import {
  Button,
  Modal,
  Paper,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useContext, useRef, useState } from "react";
import { Key, LogOut, Mail } from "react-feather";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { clearTokens } from "../../storage/helpers";
import ACTIONS from "../../store/actions";
import { GlobalState } from "../../store/GlobalState";
import ActionButtonForAccount from "./ActionButtonForAccount";
import ChangePassword from "./ChangePassword";
import Contact from "./Contact";
import CurrentCompanyDisplay from "./CurrentCompanyDisplay";
import UserSwitches from "./UserSwitches";
import { useSWRConfig } from "swr";

const Wrapper = styled(Paper)`
  padding: 20px;
  min-height: 200px;
  min-width: 360px;
  max-width: 440px;
  text-align: left;
  border: 1px solid lightgray;
`;

const AccountButton = styled(Button)`
  height: 40px;
  width: 40px;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    color: slategrey;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &:hover {
    cursor: pointer;
    background-color: lightgray;

    span {
      color: black;
    }
  }
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0px;
  border-bottom: 1px solid #eeeeee68;

  p {
    font-size: 12px;
    color: black;
  }

  span {
    font-size: 12px;
    color: black;
    font-weight: 500;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const getInitials = (user) => `${user?.first_name[0]}${user?.last_name[0]}`;

const AccountDropdownV2 = () => {
  const { state, dispatch } = useContext(GlobalState);
  const { config, job } = state;
  const { user } = config;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const initials = getInitials(user);
  const [editPasswordOpen, setEditPasswordOpen] = useState(false);
  const [contactUsOpen, setContactUsOpen] = useState(false);
  const history = useHistory();

  const isManager = job === "M";
  const { mutate } = useSWRConfig();

  const clearCache = () => mutate(() => true, undefined, { revalidate: false });

  const handleLogout = () => {
    clearTokens();
    clearCache();
    dispatch({ type: ACTIONS.LOGOUT });
    history.push("/");
  };

  return (
    <>
      <Tooltip title="Account" arrow placement="bottom">
        <AccountButton onClick={() => setOpen((p) => !p)} ref={ref}>
          <span>{initials}</span>
        </AccountButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Wrapper>
          <div
            style={{
              width: "100%",
              height: 48,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 10,
            }}
          >
            <div
              style={{
                height: 40,
                width: 40,
                borderRadius: 3,
                background: "#eeeeee",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "slategray",
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
              >
                {initials}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                overflow: "hidden",
                flex: 1,
                flexDirection: "column",
                height: "100%",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "600",
                  color: "black",
                  lineHeight: 1.2,
                }}
              >
                {user.first_name} {user.last_name}{" "}
              </Typography>
              <Typography style={{ color: "darkgrey", lineHeight: 1.2 }}>
                {user?.username}
              </Typography>
            </div>
          </div>

          {!isManager && <div style={{ height: 16 }} />}
          <UserSwitches user={user} />

          <div style={{ height: 12 }} />
          <CurrentCompanyDisplay
            currentCompany={config?.current_company}
            user={config?.user}
          />

          <div style={{ height: 12 }} />
          <p style={{ fontSize: 11, color: "slategrey", fontWeight: "600" }}>
            USER ACTIONS
          </p>

          <div style={{ height: 8 }} />
          <ButtonContainer>
            <ActionButtonForAccount
              Icon={Key}
              title="change your password"
              handleClick={() => setEditPasswordOpen(true)}
            />
            <div style={{ width: 4 }} />
            <ActionButtonForAccount
              Icon={Mail}
              title="contact us"
              handleClick={() => setContactUsOpen(true)}
            />
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "firebrick",
                marginLeft: "auto",
                color: "white",
              }}
              onClick={handleLogout}
              startIcon={<LogOut height={14} width={14} color="white" />}
            >
              Log Out
            </Button>
          </ButtonContainer>
        </Wrapper>
        <Modal
          open={editPasswordOpen}
          onClose={() => setEditPasswordOpen(false)}
        >
          <div>
            <ChangePassword />
          </div>
        </Modal>
        <Modal open={contactUsOpen} onClose={() => setContactUsOpen(false)}>
          <div>
            <Contact user={user} />
          </div>
        </Modal>
      </Popover>
    </>
  );
};

export default AccountDropdownV2;
