/* eslint-disable react/no-unescaped-entities */
import React from "react";
import styled from "styled-components";
import {
  BDown,
  CustomDetails,
  CustomAccordion,
  CustomAccordionSummary,
  IntroText,
} from "./styles";
import { Typography } from "../../pages/private/styles";

const Wrapper = styled.div`
  padding: 10px 0px;
  width: 100%;
  flex: 0.5;
  flex-grow: 1;
`;

const FreqAskedModuleQuestions = () => (
  <Wrapper>
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<BDown />}>
        <Typography variant="subtitle1">
          <strong>Frequently Asked Questions</strong>
        </Typography>
      </CustomAccordionSummary>
      <CustomDetails>
        <Typography variant="subtitle2" ml={2}>
          <em>What is a suggested module?</em>
        </Typography>
        <IntroText mt={2} ml={2}>
          A suggested module is a vendor module made by one of our premium
          vendors. If they believe the module may to of use to your company,
          they will push it to you, and it will end up in your suggested module
          table. You can review this module, as well as have the choice to
          either accept it (will go in your vendor modules) or reject it.
        </IntroText>
        <Typography variant="subtitle2" ml={2} mt={8}>
          <em>What is the difference between a "Custom" vs "Vendor" module.</em>
        </Typography>
        <IntroText mt={2} ml={2}>
          Modules added from the marketplace, or accepted in your suggested
          modules, are considered vendor modules. Modules that are created by
          you are custom modules.
        </IntroText>
      </CustomDetails>
    </CustomAccordion>
  </Wrapper>
);

export default FreqAskedModuleQuestions;
