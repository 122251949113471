import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "@material-ui/core";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import { CustomDatatableV2 } from "../styles";
import API from "../../../axios/instances/API";
import { makeDefaultTableOptions } from "../../../storage/helpers";
import ConfirmModalSmall from "../../../components/Modals/ConfirmModalSmall";
import { RefreshCw, Triangle } from "react-feather";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import ActionButtonForTables from "../../../components/Table/ActionButtonForTables";
import TableTitle from "../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import TableLoader from "../../../components/Loaders/TableLoader";
import useManagerInvitations from "../../../swr/hooks/Managers/useManagerInvitations";
import { useMediaQuery } from "@material-ui/core";
import DateTooltip from "../../../components/Table/DateTooltip";

const SupervisorInvitesTable = ({ readOnly }) => {
  const [confirmInfo, setConfirmInfo] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const { isLoading, data, mutate } = useManagerInvitations();

  const resendInvitation = async () => {
    try {
      await API.put("dashboard/managers/invitations/details/", {
        access_token: confirmInfo?.token,
      });
      setSnackbarText(`Resent invitation to ${confirmInfo?.name} success`);
      setConfirmInfo(null);
    } catch (error) {
      setSnackbarText(
        `Failed to resend invitation to ${confirmInfo?.name}, please try again. error`,
      );
      setConfirmInfo(null);
    }
  };

  const revokeInvitation = async () => {
    try {
      await API.delete("dashboard/managers/invitations/details/", {
        data: {
          access_token: confirmInfo?.token,
        },
      });
      await mutate();
      setSnackbarText(`Deleted invitation to ${confirmInfo?.name} warning`);
      setConfirmInfo(null);
    } catch (error) {
      setSnackbarText(
        `Failed to resend invitation to ${confirmInfo?.name}, please try again. error`,
      );
      setConfirmInfo(null);
    }
  };

  if (isLoading) return <TableLoader text="Outstanding Supervisors" />;

  const options = makeDefaultTableOptions("outstanding-supervisor-invitations");
  options.textLabels = {
    body: {
      noMatch:
        "No outstanding supervisor invitations are pending! Click the “Invite Supervisor” button to invite new supervisors to manage their store!",
    },
  };
  options.sortOrder = {
    name: "created",
    direction: "desc",
  };
  options.rowsPerPage = 50;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = data.invitations.map((manager) => ({
    id: manager.access_token,
    name: `${manager.first_name} ${manager.last_name}`,
    created: new Date(manager.created).toISOString(),
    email: manager.email,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
        print: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created",
      label: "Date Sent",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} shouldLocalize />
        ),
      },
    },
    {
      name: "resend",
      label: "Resend",
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            title="resend invitation"
            disabled={readOnly}
            handleClick={() => {
              const { rowData } = tableMeta;
              setConfirmInfo({
                token: rowData[0],
                name: rowData[1],
                type: "resend",
              });
            }}
            Icon={RefreshCw}
          />
        ),
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        sort: false,
        filter: false,
        empty: true,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            handleClick={() => {
              const { rowData } = tableMeta;
              setConfirmInfo({
                token: rowData[0],
                name: rowData[1],
                type: "delete",
              });
            }}
          />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={
          <TableTitle
            title="Supervisor outstanding invitations"
            Icon={Triangle}
          />
        }
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(confirmInfo)}
        onClose={() => setConfirmInfo(null)}
        aria-labelledby="Cancel a Pending Supervisor Invite"
        aria-describedby="Cancel a pending Supervisor Invite"
      >
        <div>
          <ConfirmModalSmall
            title={
              confirmInfo?.type === "delete"
                ? "Delete invite to"
                : "Resend invite to"
            }
            name={confirmInfo?.name}
            handleConfirm={
              confirmInfo?.type === "delete"
                ? revokeInvitation
                : resendInvitation
            }
            confirmAccept={confirmInfo?.type !== "delete"}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default SupervisorInvitesTable;

SupervisorInvitesTable.propTypes = {
  pendingLoading: PropTypes.bool.isRequired,
  pendingManagers: PropTypes.array,
  mutate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

SupervisorInvitesTable.defaultProps = {
  pendingManagers: null,
};
