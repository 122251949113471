import React, { useState } from "react";
import { Modal, Paper, Tooltip, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  CheckCircle,
  File,
  PlusCircle,
  Search,
  Target,
  Video,
} from "react-feather";
import styled from "styled-components";
import AddAudienceAndDatesToLaunchFromMarketplace from "../../../components/audience/AddAudienceAndDatesToLaunchFromMarketplace";
import { getSrcTwo } from "../../../storage/helpers";
import { AddButton, AddedAnimation, ReviewButton } from "./marketplaceStyles";
import DisplayStreams from "./DisplayStreams";

const CardWrapper = styled(Paper)`
  min-height: 370px;
  width: 290px;
  border-radius: 12px;
  border: 1px solid lightgray;
  box-shadow: none;
  padding: 8px;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  transition: box-shadow 300ms ease-in;

  &:hover {
    box-shadow: 0px 0px 8px 2px lightgray;
  }
`;

const CardMainImage = styled.img`
  height: 240px;
  border-radius: 6px;
  width: 100%;
  object-fit: contain;
  background: white;
  border: 1px solid lightgray;
`;

const AnimationOverlay = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 12px;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: 6s ${AddedAnimation} ease-out 1;
  pointer-events: none;
`;

const AddedText = styled(Typography)`
  color: black;
  font-size: 18px;
  font-weight: 600;
`;

const InfoBox = styled.div`
  padding: 2px 4px;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
  border-radius: 3px;

  svg {
    height: 10px;
    width: 10px;
    color: slategrey;
  }

  span {
    color: slategrey;
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
  }
`;

const limitChars = (text, maxLen = 120) => {
  if (text === null) return " ";
  if (text.length > maxLen) {
    const shortened = text.substring(0, maxLen);
    const trimmed = shortened.trim().concat("...");

    return trimmed;
  }
  return text;
};

const Chip = styled.div`
  width: 88px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: absolute;
  top: 12px;
  right: 12px;
  background: #eeeeee;
  // background: mediumseagreen;

  gap: 5px;

  span {
    color: slategrey;
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const ModuleCardV2 = ({
  quiz,
  setReviewModalInfo,
  setSnackbarText,
  config,
  dispatch,
  stream,
  goToTop,
}) => {
  const { user } = config;
  const isManager = user.job === "M";
  const [added, setAdded] = useState(false);
  const [launched, setLaunched] = useState(false);
  const [addModalInfo, setAddModalInfo] = useState(false);
  const { read_only: readOnly } = user;

  const hasPermissionsToAddModule = readOnly
    ? false
    : !isManager
      ? true
      : user.can_deploy_vendor_content
        ? true
        : false;

  const addDisabled = !hasPermissionsToAddModule || quiz.exists;

  return (
    <>
      <CardWrapper>
        <CardMainImage src={quiz.image} alt="" />
        <div style={{ height: 10 }} />

        <p
          style={{
            fontWeight: "600",
            fontSize: 14,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: 270,
            overflow: "hidden",
          }}
        >
          {limitChars(quiz.name, 76)}
        </p>

        <div style={{ height: 8 }} />
        <div
          style={{
            padding: 8,
            background: "#eeeeee55",
            border: "1px solid lightgray",
            width: "100%",
            borderRadius: 5,
            textAlign: "left",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 4,
              borderBottom: "1px solid gainsboro",
              paddingBottom: 2,
            }}
          >
            <span
              style={{ color: "slategrey", fontSize: 12, fontWeight: "500" }}
            >
              {quiz.company.name}
            </span>
            <img
              style={{
                height: 19,
                width: 19,
                borderRadius: 3,
                objectFit: "contain",
                filter: "grayscale(.6)",
              }}
              alt="company"
              src={quiz.company?.logo}
            />
          </div>
          <div style={{ height: 4 }} />
          <p
            style={{
              fontSize: 10,
              color: "darkgrey",
            }}
          >
            {limitChars(quiz.description, 90)}
          </p>
        </div>
        <div style={{ height: 6 }} />
        <DisplayStreams
          activeStreamId={stream?.id}
          streams={quiz?.streams}
          dispatch={dispatch}
          goToTop={goToTop}
          company={quiz.company}
        />
        <div
          style={{
            width: "100%",

            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            height: 46,
          }}
        >
          <div
            style={{
              height: 46,
              display: "flex",
              flexWrap: "wrap",
              paddingRight: 4,
              flex: 1,
              alignItems: "flex-start",
              justifyContent: "center",
              gap: 4,
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", gap: 4 }}>
              {(quiz?.media_type === "V" || quiz?.media_type === "O") && (
                <InfoBox>
                  <Video />
                  <span>Video</span>
                </InfoBox>
              )}
              {(quiz?.media_type === "P" || quiz?.media_type == "O") && (
                <InfoBox>
                  <File />
                  <span>PDF</span>
                </InfoBox>
              )}
              <InfoBox>
                <Target />
                <span>
                  {quiz?.pass_score}/{quiz?.questions}
                </span>
              </InfoBox>
            </div>

            <InfoBox>
              <span
                style={{
                  color: quiz.exists ? "mediumseagreen" : "firebrick",
                }}
              >
                {quiz.exists ? "In library" : "Not in library"}
              </span>
            </InfoBox>
          </div>
          <Tooltip title="Review Module" arrow>
            <ReviewButton
              onClick={() =>
                setReviewModalInfo({
                  id: quiz.id,
                  exists: quiz.exists,
                  marketplaceParent: quiz.company.name,
                })
              }
            >
              <Search />
            </ReviewButton>
          </Tooltip>
          <div style={{ width: 4 }} />
          <Tooltip
            title={addDisabled ? "Module already in library" : "Add to library"}
            arrow
          >
            <div>
              <AddButton
                onClick={() =>
                  setAddModalInfo({
                    quiz,
                  })
                }
                disabled={addDisabled}
              >
                <PlusCircle />
              </AddButton>
            </div>
          </Tooltip>
        </div>
        {added && (
          <AnimationOverlay>
            <CheckCircle color="mediumseagreen" height={26} width={26} />
            <div style={{ height: 16 }} />
            <AddedText>Added to Library</AddedText>
          </AnimationOverlay>
        )}
        {launched && (
          <AnimationOverlay>
            <span style={{ fontSize: 28 }}>🚀</span>
            <div style={{ height: 16 }} />
            <AddedText>Module Launched</AddedText>
          </AnimationOverlay>
        )}
      </CardWrapper>
      <Modal
        open={Boolean(addModalInfo)}
        onClose={() => setAddModalInfo(null)}
        aria-labelledby="Modal to add Module"
        aria-describedby="You can add this module to your library here"
      >
        <div>
          <AddAudienceAndDatesToLaunchFromMarketplace
            alreadyInLibrary={false}
            moduleId={quiz.id}
            setAdded={setAdded}
            setLaunched={setLaunched}
            setSnackbarText={setSnackbarText}
            setAddModalInfo={setAddModalInfo}
            dispatch={dispatch}
          />
        </div>
      </Modal>
    </>
  );
};

export default ModuleCardV2;

ModuleCardV2.propTypes = {
  quiz: PropTypes.object.isRequired,
  setReviewModalInfo: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
