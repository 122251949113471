import React, { useState } from "react";
import { Modal, useMediaQuery } from "@material-ui/core";
import { RefreshCw, Triangle } from "react-feather";
import API from "../../../axios/instances/API";
import TableLoader from "../../../components/Loaders/TableLoader";
import ConfirmModalSmall from "../../../components/Modals/ConfirmModalSmall";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import ActionButtonForTables from "../../../components/Table/ActionButtonForTables";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../components/Table/DateTooltip";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import TableTitle from "../../../components/Table/TableTitle";
import { makeDefaultTableOptions } from "../../../storage/helpers";
import useAdminInvitations from "../../../swr/hooks/Admin/useAdminInvitations";
import { CustomDatatableV2 } from "../styles";

const OutstandingAdminTable = ({ readOnly }) => {
  const [confirmInfo, setConfirmInfo] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const { data, isLoading, mutate } = useAdminInvitations();

  if (isLoading) return <TableLoader />;

  const options = makeDefaultTableOptions("outstanding-admins");
  options.sortOrder = {
    name: "created",
    direction: "desc",
  };
  options.rowsPerPage = 50;
  options.textLabels = {
    body: {
      noMatch:
        'No outstanding admin invitations pending! Click the "Invite Admin" button to send an invite to a new admin!',
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const { invitations } = data;

  const resendInvitation = async () => {
    try {
      await API.put("dashboard/admin/invitations/details/", {
        access_token: confirmInfo?.token,
      });
      setSnackbarText(`Invitation to ${confirmInfo?.name} resent success`);
      setConfirmInfo(null);
    } catch (error) {
      setSnackbarText(
        `Failed to resend invitation to ${confirmInfo?.name} please try again error`,
      );
    }
  };

  const revokeInvitation = async () => {
    try {
      await API.delete("dashboard/admin/invitations/details/", {
        data: {
          access_token: confirmInfo?.token,
        },
      });
      await mutate();
      setSnackbarText(`Invitation to ${confirmInfo?.name} removed warning`);
      setConfirmInfo(null);
    } catch (error) {
      setSnackbarText(
        `Failed to resend invitation to ${confirmInfo?.name} please try again error`,
      );
    }
  };

  const tableData = invitations.map((invite) => ({
    id: invite.access_token,
    name: `${invite.first_name} ${invite.last_name}`,
    email: invite.email,
    created: new Date(invite.created).toISOString(),
    resend: invite.access_token,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created",
      label: "Date Sent",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} shouldLocalize />
        ),
      },
    },
    {
      name: "resend",
      label: "Resend Invite",
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <div style={{ width: "fit-content" }}>
            <ActionButtonForTables
              disabled={readOnly}
              handleClick={(e) => {
                e.stopPropagation();
                const { rowData } = tableMeta;
                setConfirmInfo({
                  token: rowData[0],
                  name: rowData[1],
                  type: "resend",
                });
              }}
              Icon={RefreshCw}
              title="resend invitation"
            />
          </div>
        ),
      },
    },
    {
      name: "delete",
      label: "Delete Invite",
      options: {
        sort: false,
        filter: false,
        empty: true,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setConfirmInfo({
                token: rowData[0],
                name: rowData[1],
                type: "delete",
              });
            }}
          />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={
          <TableTitle title="Admin outstanding invitations" Icon={Triangle} />
        }
        data={tableData}
        options={options}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(confirmInfo)}
        onClose={() => setConfirmInfo(null)}
        aria-labelledby="Delete a Outstanding Admin Invite"
        aria-describedby="Delete a Outstanding Admin Invite"
      >
        <div>
          <ConfirmModalSmall
            title={
              confirmInfo?.type === "delete"
                ? "Delete this invite"
                : "Resend this invite"
            }
            name={confirmInfo?.name}
            handleConfirm={
              confirmInfo?.type === "delete"
                ? revokeInvitation
                : resendInvitation
            }
            confirmAccept={confirmInfo?.type !== "delete"}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default OutstandingAdminTable;
