import { Modal, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { Info, ChevronRight as LinkIcon } from "react-feather";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ModalWrapper = styled(Paper)`
  padding: 16px;
  min-width: min(300px, 100vw);
  max-width: 3000px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  border: 2px solid black;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const CustomIconButton = styled.div`
  border: 1px solid transparent;
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: slategrey;
  }

  &:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }
`;

const MultipleLocationPopperV2 = ({ name, teamMembers }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 4,
        paddingLeft: 12,
      }}
    >
      <span>{name}</span>
      <CustomIconButton size="small" onClick={() => setOpen((p) => !p)}>
        <Info height={11} width={11} />
      </CustomIconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalWrapper>
          <span style={{ fontWeight: "600", marginBottom: 4 }}>
            {name} has multiple locations
          </span>
          {teamMembers.map((member) => (
            <div
              key={member.team_member_id}
              style={{ display: "flex", gap: 6 }}
            >
              <span style={{ fontSize: 11, fontWeight: "500" }}>
                {name} | {member?.location_name}
              </span>
              <Link to={`/teammembers/detail/${member.team_member_id}`}>
                <LinkIcon
                  style={{
                    background: "#eeeeee",
                    borderRadius: 4,
                    padding: 2,
                  }}
                  height={16}
                  width={16}
                  color="dodgerblue"
                />
              </Link>
            </div>
          ))}
        </ModalWrapper>
      </Modal>
    </div>
  );
};

export default MultipleLocationPopperV2;
