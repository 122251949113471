import React from "react";
import { Typography } from "@material-ui/core";
import ShowRoleChip from "../../ShowRoleChip";
import { Tag } from "react-feather";
import { Wrapper, ScrollWrapper } from "./styles";

const CurrentRoles = ({ comboValue, options, ...props }) => {
  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Tag
          style={{
            height: 14,
            width: 14,
            color: "black",
            marginRight: 6,
          }}
        />
        <Typography variant="subtitle2" style={{ color: "black" }}>
          By Role(s)
        </Typography>
      </div>
      <span
        style={{
          fontSize: 11,
          fontWeight: "400",
          color: "darkgrey",
          paddingLeft: 20,
        }}
      >
        This module will be distributed to all team members with the following
        role(s):
      </span>
      <ScrollWrapper>
        {options.roles
          .filter((role) => comboValue.includes(role.id))
          .map((role) => (
            <ShowRoleChip
              key={role.id}
              id={role.id}
              name={role.name}
              shouldLinkToRolePage={false}
            />
          ))}
      </ScrollWrapper>
    </Wrapper>
  );
};

export default CurrentRoles;
