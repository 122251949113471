import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { Info } from "react-feather";

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: "none",
    width: 250,
    minHeight: "none",
    maxHeight: "none",
    borderRadius: 4,
    backgroundColor: "white",
    border: "1px solid slategrey",
    padding: 8,
  },
}));

const Container = styled.div`
  height: 18px;
  width: 18px;
  background: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #eeeeee;
    cursor: pointer;
  }
`;

const Inner = () => (
  <div
    style={{
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "column",
      gap: 4,
    }}
  >
    <span style={{ color: "black", fontSize: 10, fontWeight: "600" }}>
      What are supervisor deploy permissions?
    </span>
    <span style={{ color: "darkgrey" }}>
      Supervisors with deploy permissions gain 3 main functionalities
    </span>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span style={{ color: "black", fontWeight: "400" }}>
        1) The ability to create custom modules.
      </span>
      <span style={{ color: "black", fontWeight: "400" }}>
        2) The ability to view and use the marketplace.
      </span>
      <span style={{ color: "black", fontWeight: "400" }}>
        3) The ability to change the audience of modules.
      </span>
    </div>
  </div>
);

const DeployPermissionsTooltip = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        arrow
        placement="top"
        title={<Inner />}
      >
        <Container>
          <Info height={12} width={12} color="slategrey" />
        </Container>
      </Tooltip>
    </div>
  );
};

export default DeployPermissionsTooltip;
