import React from "react";
import { StyledItem } from "../styles";
import { Navigation } from "react-feather";
import {
  QUIZSTATES,
  LIBRARY_MODAL_KEYS,
} from "../../../../../storage/constants";

const LaunchPendingModule = ({
  id,
  moduleState,
  setModalState,
  handleClose,
}) => {
  if (moduleState !== QUIZSTATES.PENDING) return null;

  return (
    <StyledItem
      onClick={() => {
        setModalState({ key: LIBRARY_MODAL_KEYS.LAUNCH_PENDING, id });
        handleClose();
      }}
    >
      <Navigation />
      <span>Launch Now</span>
    </StyledItem>
  );
};

export default LaunchPendingModule;
