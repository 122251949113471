import React from "react";
import styled from "styled-components";
import { Paper, Typography } from "@material-ui/core";
import { Button } from "../../../pages/private/styles";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import { Users } from "react-feather";

const Wrapper = styled(Paper)`
  padding: 10px;
  width: 320px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  max-width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: flex-end;

  gap: 6px;
  margin-top: 16px;
`;

const ConfirmAllTeamMembersModal = () => {
  const handleSelectAllAndSave = useAudienceToolStore(
    (state) => state.handleSelectAllAndSave,
  );

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-starts",
          gap: 6,
        }}
      >
        <Users height={13} width={13} />
        <Typography style={{ fontSize: 14, fontWeight: "500" }}>
          All Team Members
        </Typography>
      </div>
      <Typography style={{ fontSize: 11, color: "darkgray", marginLeft: 18 }}>
        Send module to all team members, at all locations.
      </Typography>
      <ButtonWrapper>
        <Button
          style={{
            background: "#337ab7",
            height: 32,
            minWidth: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 8px",
          }}
          variant="contained"
          color="primary"
          onClick={handleSelectAllAndSave}
        >
          Confirm & Save
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ConfirmAllTeamMembersModal;
