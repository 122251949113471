import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { GlobalState } from "../../../../store/GlobalState";
import RetailProgress from "./retail";
import VendorProgress from "./vendor";

const ModuleProgress = ({ match }) => {
  const { id } = match.params;
  const { state } = useContext(GlobalState);
  const { isRetail } = state;

  return (
    <>
      <Helmet title="Spiffy | Module Info" />
      {isRetail ? <RetailProgress id={id} /> : <VendorProgress id={id} />}
    </>
  );
};

export default ModuleProgress;

ModuleProgress.propTypes = {
  match: PropTypes.object.isRequired,
};
