import React from "react";
import styled from "styled-components";
import { Paper, Typography } from "@material-ui/core";
import useCompanyDetails from "../../../swr/hooks/Util/useCompanyDetails";
import CompanySwitches from "../CompanySwitches";
import CompanyPermissions from "../CompanyPermissions";
import ActionButtonForAccount from "../ActionButtonForAccount";
import { Image, Edit, MessageCircle } from "react-feather";
import FunLoader from "../../Loaders/FunLoader";

const Wrapper = styled(Paper)`
  padding: 20px;
  min-height: 120px;
  min-width: 360px;
  max-width: 360px;
  text-align: left;
  border: 1px solid lightgray;
`;

const CompanyImgNoHover = styled.img`
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 12px;
  border: 1px solid #eeeeee;
  background-color: white;
  cursor: pointer;
  transition: all 200ms ease-in;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0px;
  border-bottom: 1px solid #eeeeee68;

  p {
    font-size: 12px;
    color: black;
  }

  span {
    font-size: 12px;
    color: black;
    font-weight: 500;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

const DropdownInner = ({
  isRetail,
  isManager,
  setEditCompanyName,
  setEditCompanyDescription,
  setEditLogoModalOpen,
}) => {
  const { isLoading, isError, company, mutate } = useCompanyDetails();

  if (isLoading)
    return (
      <Wrapper>
        <div
          style={{
            width: "100%",
            minHeight: 180,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FunLoader />
        </div>
      </Wrapper>
    );

  if (isError)
    return (
      <Wrapper>
        <span style={{ color: "firebrick" }}>
          Error getting company details
        </span>
      </Wrapper>
    );

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: -4,
        }}
      >
        <CompanyImgNoHover
          src={company?.logo}
          alt="brand logo"
          style={{ marginRight: 6 }}
        />
        <Typography
          style={{
            fontSize: 13,
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          {company?.name}
        </Typography>
      </div>
      <div style={{ height: 16 }} />
      <p style={{ fontSize: 11, color: "slategrey", fontWeight: "600" }}>
        COMPANY INFO
      </p>
      <div style={{ height: 4 }} />
      <FlexDiv>
        <p>Company Name:</p>
        <span>{company?.name}</span>
      </FlexDiv>
      <FlexDiv>
        <p>Logo Uploaded:</p>
        <span>{company?.logo ? "Yes" : "No"}</span>
      </FlexDiv>
      {!isRetail && (
        <>
          <div style={{ height: 16 }} />
          <p style={{ fontSize: 11, color: "slategrey", fontWeight: "600" }}>
            COMPANY DESCRIPTION
          </p>
          <div style={{ height: 4 }} />
          <p style={{ fontSize: 12, marginBottom: -2 }}>
            {company?.description || "--/--"}
          </p>
        </>
      )}
      <div style={{ height: 16 }} />
      {!isManager && (
        <>
          <CompanySwitches
            isRetail={isRetail}
            company={company}
            mutate={mutate}
          />
        </>
      )}
      <CompanyPermissions />
      {!isManager && (
        <>
          <div style={{ height: 16 }} />
          <p style={{ fontSize: 11, color: "slategrey", fontWeight: "600" }}>
            COMPANY ACTIONS
          </p>
          <div style={{ height: 8 }} />
          <ButtonContainer>
            <ActionButtonForAccount
              Icon={Image}
              title="edit company logo"
              handleClick={() => setEditLogoModalOpen((p) => !p)}
            />
            <ActionButtonForAccount
              Icon={Edit}
              title="edit company name"
              handleClick={() => setEditCompanyName((p) => !p)}
            />
            {!isRetail && (
              <ActionButtonForAccount
                Icon={MessageCircle}
                title="edit company description"
                handleClick={() => setEditCompanyDescription((p) => !p)}
              />
            )}
          </ButtonContainer>
        </>
      )}
    </Wrapper>
  );
};

export default DropdownInner;
