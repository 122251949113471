import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import ACTIONS from "../state/actions";
import { Typography } from "../../styles";
import styled from "styled-components";

const QuestionText = styled(Typography)`
  color: darkgrey;
  font-size: 13px;
  font-weight: 400;
`;

const AnswerText = styled(Typography)`
  font-size: 14px;
  color: black;
  font-weight: 500;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 4px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 52px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Basics = ({ details, quizContext, isMarketplace = false }) => (
  <>
    <Wrapper>
      <Container>
        <QuestionText> Name </QuestionText>
        <AnswerText>{details.name}</AnswerText>
      </Container>
      <Container>
        <QuestionText>Description</QuestionText>
        <AnswerText variant="subtitle1">
          {details.description ? details.description : "None"}
        </AnswerText>
      </Container>
      <Container>
        <QuestionText variant="body2" color="inherit">
          Disclaimer
        </QuestionText>
        <AnswerText variant="subtitle1">
          {details.disclaimer ? details.disclaimerText : "None"}
        </AnswerText>
      </Container>
      <Container>
        <QuestionText variant="body2" color="inherit">
          Start Date
        </QuestionText>
        <AnswerText variant="subtitle1">
          {details.startDate ? details.startDate : "None"}
        </AnswerText>
      </Container>
      <Container>
        <QuestionText variant="body2" color="inherit">
          End Date
        </QuestionText>
        <AnswerText variant="subtitle1">
          {details.dueDate ? details.dueDate : "None"}
        </AnswerText>
      </Container>
      <Container>
        <QuestionText variant="body2" color="inherit">
          Estimated Time To Complete
        </QuestionText>
        <AnswerText variant="subtitle1">
          {details.estimatedTime} minutes
        </AnswerText>
      </Container>
      <Container>
        <QuestionText variant="body2" color="inherit">
          Show Incorrect Answers
        </QuestionText>
        <AnswerText variant="subtitle1">
          {details.displayAnswers ? "Yes" : "No"}
        </AnswerText>
      </Container>
      {!isMarketplace && (
        <Grid item xs={12}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() =>
              quizContext.dispatch({ type: ACTIONS.UPDATE_INDEX, payload: 0 })
            }
          >
            Go To Edit Basics
          </Button>
        </Grid>
      )}
    </Wrapper>
  </>
);

export default Basics;

Basics.propTypes = {
  details: PropTypes.object,
  quizContext: PropTypes.object,
  isMarketplace: PropTypes.bool,
};

Basics.defaultProps = {
  quizContext: null,
  details: PropTypes.string,
  isMarketplace: undefined,
};
