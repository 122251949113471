import React from "react";
import styled from "styled-components";
import CheckboxBrandBasic from "../../../../../components/CheckboxBrandBasic";
const ItemWrapper = styled.div`
  width: 100%;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 4px;
  background-color: white;

  &:hover {
    background-color: #eeeeee66;
  }
`;

const Item = ({ member, handleClick, checked }) => {
  return (
    <ItemWrapper
      onClick={() => handleClick(member?.teamMember?.team_member_id)}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 4,
        }}
      >
        <span>{member?.name}</span>
        <span style={{ fontSize: 12, color: "darkgrey" }}>
          ({member?.teamMember?.location_name})
        </span>
      </div>
      <CheckboxBrandBasic checked={checked} overrideColor="firebrick" />
    </ItemWrapper>
  );
};

export default Item;
