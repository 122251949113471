import React, { useState } from "react";
import { Button, Collapse, Paper } from "@material-ui/core";
import styled from "styled-components";
import useQuizReview from "../../../../swr/hooks/Library/useQuizReview";
import API from "../../../../axios/instances/API";
import { Alert } from "@material-ui/lab";
import FunLoader from "../../../../components/Loaders/FunLoader";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(420px, 96vw));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
  background: #eeeeee;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 4px;
  background: white;
`;

const getErrorMsg = (error) => {
  return (
    error?.response?.data?.error?.details || "Error launching this module."
  );
};

const LaunchScheduledModModal = ({ handleClose, id, setSnackbarText }) => {
  const { details, isLoading } = useQuizReview(id);
  const [isLaunching, setIsLaunching] = useState(false);
  const [error, setError] = useState(false);

  if (isLoading) {
    return (
      <ModalWrapper>
        <span style={{ fontSize: 16, fontWeight: "600" }}>
          Launch Pending Module
        </span>
        <div style={{ height: 8 }} />
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 18,
          }}
        >
          <FunLoader />
        </div>
      </ModalWrapper>
    );
  }

  const handleLaunchPending = async () => {
    setIsLaunching(true);

    try {
      await API.post("/dashboard/quiz/deployment/launch-scheduled-quiz/", {
        id,
      });
      setSnackbarText("Module launched successfully 🚀 success");
      handleClose();
    } catch (error) {
      const msg = getErrorMsg(error);
      setError(msg);
      setIsLaunching(false);
    }
  };

  return (
    <ModalWrapper>
      <span style={{ fontSize: 16, fontWeight: "600" }}>
        Launch Pending Module
      </span>
      <div style={{ height: 12 }} />

      <div
        style={{
          width: "100%",
          flex: 1,

          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <FlexDiv>
          {/* <span>Module:</span> */}
          <img
            alt="module"
            style={{
              height: 44,
              width: 44,
              border: "1px solid lightgray",
              borderRadius: 5,
              objectFit: "contain",
            }}
            src={details?.image}
          />
          <span style={{ fontSize: 13, fontWeight: "500" }}>
            {details?.name}
          </span>
        </FlexDiv>
        {/* <FlexDiv>
          <span>State:</span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
            }}
          >
            <AlertTriangle color="orange" height={14} width={14} />
            <span style={{ fontSize: 13, fontWeight: "500" }}>
              Pending Release
            </span>
          </div>
        </FlexDiv> */}
        {/* <FlexDiv>
          <span>Scheduled Launch date:</span>
          <span style={{ fontSize: 13, fontWeight: "500" }}>
            {details?.startDate}
          </span>
        </FlexDiv> */}

        <div style={{ height: 14 }} />
        <span
          style={{
            fontSize: 13,
            color: "black",
            textAlign: "left",
          }}
        >
          Clicking <b>Launch Module</b> will set the module start date to today
          and change the module state to <b>Live</b>.
        </span>
      </div>
      <div style={{ height: 16 }} />

      <Button
        variant="contained"
        color="secondary"
        style={{ marginTop: "auto", marginLeft: "auto" }}
        onClick={handleLaunchPending}
        disabled={isLaunching || error}
      >
        {isLaunching ? "Launcing Module.." : "🚀 Launch Module"}
      </Button>
      <Collapse in={Boolean(error)}>
        <>
          <div style={{ height: 8 }} />
          <Alert
            style={{ width: "100%", border: "1px solid red" }}
            severity="error"
            onClose={() => setError(null)}
          >
            <p style={{ fontWeight: "600", fontSize: 14 }}>Error</p>
            <span style={{ fontSize: 13 }}>
              {error || "There was an error trying to push this module live"}
            </span>
          </Alert>
        </>
      </Collapse>
    </ModalWrapper>
  );
};

export default LaunchScheduledModModal;
