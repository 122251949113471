import React from "react";
import { Typography } from "@material-ui/core";
import ShowLocationChip from "../../ShowLocationChip";
import { MapPin } from "react-feather";
import { Wrapper, ScrollWrapper } from "./styles";

const CurrentLocations = ({ comboValue, options }) => {
  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <MapPin
          style={{
            height: 14,
            width: 14,
            color: "black",
            marginRight: 6,
          }}
        />
        <Typography variant="subtitle2" style={{ color: "black" }}>
          By Location(s)
        </Typography>
      </div>
      <span
        style={{
          fontSize: 11,
          fontWeight: "400",
          color: "darkgrey",
          paddingLeft: 20,
        }}
      >
        This module will be deployed to all team members in the following
        location(s):
      </span>
      <ScrollWrapper>
        {options.locations
          .filter((loc) => comboValue.includes(loc.id))
          .map((loc) => (
            <ShowLocationChip
              name={loc.name}
              id={loc.id}
              key={loc.id}
              showLinkToLocation={false}
            />
          ))}
      </ScrollWrapper>
    </Wrapper>
  );
};

export default CurrentLocations;
