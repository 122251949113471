import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ModalWrapper, Typography, DeleteTimes, BlueShuffle } from "../styles";

const DragWrapper = styled.div`
  width: 80%;
  min-height: 400px;
  margin: auto;

  @media (max-width: 360px) {
    width: 100%;
  }
`;

const DragItem = styled.div`
  width: 100%;
  background-color: #eeeeee;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  margin: 4px 0px;
  border-radius: 4px;
  cursor: pointer;
`;

const OrderStream = ({ setOrderStreamOpen }) => (
  <ModalWrapper>
    <Typography variant="h3">
      Reorder Streams
      <BlueShuffle />
    </Typography>
    <Typography variant="subtitle1">
      Drag and Drop stream items to reorder
    </Typography>
    <DragWrapper>
      <DragItem>Item</DragItem>
      <DragItem>Item</DragItem>
    </DragWrapper>
    <DeleteTimes onClick={() => setOrderStreamOpen(false)} />
  </ModalWrapper>
);

export default OrderStream;

OrderStream.propTypes = {
  setOrderStreamOpen: PropTypes.func.isRequired,
};
