import { Button, LinearProgress, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../../../../axios/instances/API";
import AccessTable from "../../../../components/AdminAccess/AccessTable";
import {
  cleanAccessStateForApi,
  getStatefulAccess,
} from "../../../../components/AdminAccess/utils";
import FunLoader from "../../../../components/Loaders/FunLoader";
import { ACCESS_LEVELS } from "../../../../storage/constants";
import useAdminDetail from "../../../../swr/hooks/Admin/useAdminDetail";
import { Typography } from "../../styles";
import KeepTmsDialog from "./KeepTmsDialog";

// CHORE ->
// Updated to use zustand instead of context api?

const Wrapper = styled(Paper)`
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
  border-radius: 8px;
  width: 500px;
  min-height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

const ModifyAdminPermissionsModal = ({
  permissionId,
  setPermissionId,
  setSnackbarText,
  mutate,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    data,
    isLoading,
    isError,
    mutate: mutateModalData,
    isValidating,
  } = useAdminDetail(permissionId, 0);
  const [accessState, setAccessState] = useState();
  const [keepTmsOpen, setKeepTmsOpen] = useState(null);

  useEffect(() => {
    if (isLoading || isValidating) return undefined;

    const cleanedData = getStatefulAccess(data?.access);
    setAccessState(cleanedData);
  }, [isLoading, isValidating]);

  if (isLoading) {
    return (
      <Wrapper>
        <LoadingWrapper>
          <FunLoader />
        </LoadingWrapper>
      </Wrapper>
    );
  }

  if (isError) {
    const isUserNotAdminAnymore =
      isError?.response?.data?.error?.details ===
      "The specified admin user does not exist.";

    return (
      <Wrapper>
        <LoadingWrapper>
          <span style={{ fontWeight: 500 }}>
            {isUserNotAdminAnymore
              ? "This user is no longer an admin at this company"
              : "error getting data"}
          </span>
          {isUserNotAdminAnymore && (
            <Button
              size="small"
              variant="outlined"
              onClick={() => setPermissionId(null)}
            >
              Exit
            </Button>
          )}
        </LoadingWrapper>
      </Wrapper>
    );
  }

  const handleChange = (e, companyId) => {
    const { value } = e.target;

    const updatedArr = accessState?.map((obj) => {
      if (obj?.company_id !== companyId) {
        return {
          ...obj,
        };
      } else {
        return {
          ...obj,
          admins_access: value,
        };
      }
    });

    const prevObj = accessState?.find((s) => s?.company_id === companyId);

    const prevAccessWasNotNone = [
      ACCESS_LEVELS.WRITE,
      ACCESS_LEVELS.READ_ONLY,
    ].includes(prevObj?.admins_access);
    const userHasATeamMember = prevObj.hasActiveTeamMember;

    const newAccessIsNone = value === "NO_ACCESS";

    const shouldShowKeepTmsDialog =
      newAccessIsNone && prevAccessWasNotNone && userHasATeamMember;
    /// check to see if the change was from
    /// some level of access to none...
    /// if it was dialoge a question about keeping team member
    setAccessState(updatedArr);

    if (shouldShowKeepTmsDialog) {
      setKeepTmsOpen(updatedArr);
    } else {
      handleUpdate(updatedArr);
    }
  };

  const handleUpdate = async (updateArr) => {
    setIsUpdating(true);

    const accessData = cleanAccessStateForApi(updateArr);

    try {
      const sendBody = {
        admin_id: permissionId,
        access_data: accessData,
        keep_tms: true,
      };
      await API.put("/dashboard/admin/details/", sendBody);
      await mutateModalData();
      await mutate();
      setSnackbarText(
        `Admins permissions modified for ${data?.user?.first_name} ${data?.user?.last_name} success`,
      );
      setIsUpdating(false);
    } catch (error) {
      setSnackbarText("Failed to updated admin permissions error");
      setIsUpdating(false);
    }
  };

  const handleUpdateFromDialog = async (updateArr, keepTms) => {
    setIsUpdating(true);
    const accessData = cleanAccessStateForApi(updateArr);

    try {
      const sendBody = {
        admin_id: permissionId,
        access_data: accessData,
        keep_tms: keepTms,
      };
      setKeepTmsOpen(null);
      await API.put("/dashboard/admin/details/", sendBody);
      await mutateModalData();
      await mutate();
      setSnackbarText(
        `Admins permissions modified for ${data?.user?.first_name} ${data?.user?.last_name} success`,
      );
      setIsUpdating(false);
    } catch (error) {
      setSnackbarText("Failed to updated admin permissions error");
      setIsUpdating(false);
    }
  };

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: 4,
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          Admin Permissions {data?.user?.first_name} {data?.user?.last_name}
        </Typography>
        <Typography mb={6} variant="body1">
          You can change admin privileges for{" "}
          <strong>
            {data?.user?.first_name} {data?.user?.last_name}
          </strong>{" "}
          below.
        </Typography>
      </div>

      <div style={{ height: 8, minHeight: 8 }} />
      <AccessTable
        maxHeight={460}
        accessState={accessState}
        handleChange={handleChange}
      />
      <div style={{ height: 8, minHeight: 8 }} />
      {isUpdating && (
        <LinearProgress
          variant="indeterminate"
          color="secondary"
          style={{
            width: 200,
            position: "absolute",
            width: "100%",
            bottom: 0,
            left: 0,
            height: 4,
            borderRadius: 2,
          }}
        />
      )}
      <KeepTmsDialog
        handleUpdate={handleUpdateFromDialog}
        open={Boolean(keepTmsOpen)}
        handleClose={() => setKeepTmsOpen(false)}
        info={keepTmsOpen}
      />
    </Wrapper>
  );
};

export default ModifyAdminPermissionsModal;
