import React from "react";
import PropTypes from "prop-types";
import { ClickAwayListener, CircularProgress, Paper } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Divider, Typography } from "../../pages/private/styles";
import useLocationTeamMembers from "../../swr/hooks/Locations/useLocationTeamMembers";

const Wrapper = styled(Paper)`
  min-width: 180px;
  min-height: 130px;
  padding: 10px;
  border: 2px solid #eeeeee;
  text-align: left;
`;

const CenterCircular = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  minheight: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubtitleSpan = styled.span`
  font-size: 11px;
  color: ${(props) => props.theme.palette.grey[500]};
`;

const ShowDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 240px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 6px 0px;
`;

const LinkSpan = styled(Link)`
  text-decoration: none;
  margin-top: 12px;
  display: block;

  &:active {
    text-decoration: none;
    color: ${(props) => props.theme.palette.primary.dark};
  }

  &:visited {
    text-decoration: none;
    color: ${(props) => props.theme.palette.primary.dark};
  }

  &:hover {
    text-decoration: underline;
  }

  span {
    font-size: 10px;
    color: ${(props) => props.theme.palette.primary.dark};
  }
`;

const Inner = ({ id, name, setOpen, showLinkToLocation = true }) => {
  const { isLoading, isError, teamMembers } = useLocationTeamMembers(id);

  if (isError) {
    return (
      <Wrapper>
        <span>Error loading Location details</span>
      </Wrapper>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Wrapper>
        <Typography variant="subtitle2" mb={-1}>
          {name}
        </Typography>
        <SubtitleSpan>
          Team Members {isLoading ? "" : `(${teamMembers.length})`}
        </SubtitleSpan>
        <Divider mb={3} />
        {isLoading ? (
          <CenterCircular>
            <CircularProgress size={13} style={{ color: "black" }} />
          </CenterCircular>
        ) : (
          <ShowDiv>
            {teamMembers.length > 0 ? (
              teamMembers.map((member) => (
                <div key={member.userId}>
                  <span>{`${member.firstName} ${member.lastName}`}</span>
                </div>
              ))
            ) : (
              <CenterCircular>
                <span style={{ fontSize: 10 }}>
                  No team members at this location
                </span>
              </CenterCircular>
            )}
          </ShowDiv>
        )}
        {!isLoading && showLinkToLocation && (
          <LinkSpan to={`/locations/detail/${id}`}>
            <span>View Location Details</span>
          </LinkSpan>
        )}
      </Wrapper>
    </ClickAwayListener>
  );
};

export default Inner;

Inner.propTypes = {
  setOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
