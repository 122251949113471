import React from "react";
import { Checkbox } from "@material-ui/core";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import { Check } from "react-feather";
import { TABLE_STATES } from "./helpers";

const CheckedIcon2 = () => {
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);
  const tableState = useAudienceToolStore((state) => state.tableState);

  return (
    <div
      style={{
        height: 14,
        width: 14,
        minHeight: 14,
        minWidth: 14,
        borderRadius: 4,
        background:
          isDisplayOnly ||
          [TABLE_STATES.SAVING, TABLE_STATES.LOCKED].includes(tableState)
            ? "slategrey"
            : "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Check height={10} width={10} style={{ color: "white" }} />
    </div>
  );
};

const ColumnHeader = ({ roleId, index }) => {
  const tableState = useAudienceToolStore((state) => state.tableState);
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);
  const toggleColumnCheck = useAudienceToolStore(
    (state) => state.toggleColumnCheck,
  );

  const roleName = useAudienceToolStore(
    (state) => state.roles.find((role) => role.id === roleId).name,
  );
  const isChecked = useAudienceToolStore((state) =>
    Object.values(state.rows).every((row) => row[index].checked),
  );

  return (
    <th
      style={{
        backgroundColor: isChecked ? "#F8F8F8" : "#F0F0F0",
        borderBottom: "1px solid lightgray",
        flex: 1,
        overflow: "hidden",
        borderLeft: "1px solid lightgray",
        height: 60,
        maxWidth: 120,
        minWidth: 120,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          position: "relative",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Checkbox
          size="small"
          checked={isChecked}
          disabled={[
            TABLE_STATES.LOADING,
            TABLE_STATES.LOCKED,
            TABLE_STATES.SAVING,
          ].includes(tableState)}
          icon={
            <div
              style={{
                height: 14,
                width: 14,
                minWidth: 14,
                minHeight: 14,
                borderRadius: 4,
                background: "lightgray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          }
          checkedIcon={<CheckedIcon2 />}
          onChange={(e) => toggleColumnCheck(e, index)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontSize: 10,
              fontWeight: "600",
              color:
                isDisplayOnly ||
                [TABLE_STATES.LOCKED, TABLE_STATES.SAVING].includes(tableState)
                  ? "darkgrey"
                  : "black",
              textAlign: "center",
              maxHeight: 32,
              padding: 2,
            }}
          >
            {roleName}
          </span>
        </div>
      </div>
    </th>
  );
};

export default ColumnHeader;
