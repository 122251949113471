import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { Box, CircularProgress } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import API from "../../../axios/instances/API";
import { Alert, Button, Typography, TextField } from "../../private/styles";
import { Wrapper } from "./styles";
import Auth from "../../../layouts/Auth";

const MarginWrapper = styled(Wrapper)`
  margin-bottom: 100px;
  margin-top: 100px;
`;

const TextLink = styled(Typography)`
  cursor: pointer;
  display: inline-block;
  color: ${(props) => props.theme.palette.info.light};

  &:hover {
    text-decoration: underline;
  }
`;

const initialValues = {
  name: "",
  email: "",
  message: "",
};

const validationSchema = Yup.object({
  name: Yup.string("Enter your Name")
    .min(1, "Name must be 1 characters long")
    .required("Name is required"),
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  message: Yup.string()
    .min(8, "Message should be a minimum of 6 characters")
    .max(800, "Message can be at most 800 characters")
    .required("Message is required"),
});

const Contact = () => {
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [msgError, setMsgError] = useState(false);

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setMsgSuccess(false);
    setMsgError(false);
    try {
      await API.post("dashboard/contact/", {
        name: values.name,
        email: values.email,
        message: values.message,
      });
      setMsgSuccess(true);
      resetForm();
      setSubmitting(false);
    } catch (error) {
      setMsgError(true);
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <Auth>
      <Helmet title="Spiffy | Contact Us" />
      <MarginWrapper>
        <Box mb={4}>
          <Typography variant="h3">Contact Us</Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="subtitle2">
            Book time with a sales representative{" "}
            <em>
              <TextLink
                target="_blank"
                variant="subtitle2"
                color="primary"
                component="a"
                href="https://meetings.hubspot.com/satch"
              >
                here
              </TextLink>
            </em>
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle2">
            You can also email us at{" "}
            <em>
              <TextLink
                variant="subtitle2"
                color="primary"
                component="a"
                href="mailto: info@withspiffy.com"
              >
                info@withspiffy.com
              </TextLink>
            </em>
          </Typography>
        </Box>
        <Box mt={4} mb={1}>
          <Typography variant="h6">Or send us a message here</Typography>
        </Box>
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              isSubmitting,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box mt={2} mb={4}>
                  <TextField
                    type="text"
                    name="name"
                    label="Your Name"
                    value={values.name}
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    autoComplete="new-password"
                    variant="outlined"
                    margin="none"
                    size="small"
                  />
                </Box>
                <Box mt={0} mb={4}>
                  <TextField
                    type="email"
                    name="email"
                    label="Email Address"
                    value={values.email}
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.email && errors.email}
                    autoComplete="new-password"
                    variant="outlined"
                    margin="none"
                    size="small"
                  />
                </Box>
                <Box mt={2} mb={4}>
                  <TextField
                    multiline
                    minRows={4}
                    maxRows={4}
                    type="password"
                    name="message"
                    label="Message"
                    value={values.message}
                    fullWidth
                    error={Boolean(touched.message && errors.message)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.message && errors.message}
                    autoComplete="new-password"
                    variant="outlined"
                  />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  color="secondary"
                  variant="contained"
                  mt={2}
                  pt={2}
                  pb={2}
                  disabled={isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </form>
            )}
          </Formik>
        </Box>
        {msgSuccess && (
          <Alert severity="success" mt={2} onClose={() => setMsgSuccess(false)}>
            Message Sent! We will get back to you shortly.
          </Alert>
        )}
        {msgError && (
          <Alert severity="error" mt={2} onClose={() => setMsgError(false)}>
            Hmm.. seems we have run into an error. Try again later.
          </Alert>
        )}
      </MarginWrapper>
    </Auth>
  );
};

export default Contact;
