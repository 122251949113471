import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import { Paper, Avatar, Chip, Tooltip } from "@material-ui/core";
import { Typography } from "../../styles";
import AverageScoreBar from "./AverageScoreBar";

const SmallAvatar = styled(Avatar)`
  height: 18px;
  width: 18px;
  background-color: ${(props) =>
    props.$isCorrect
      ? props.theme.palette.primary.main
      : props.theme.palette.grey[400]};
  font-size: 12px;
`;

const Wrapper = styled(Paper)`
  padding: 20px 20px 40px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 420px;
  height: 644px;
  position: relative;
  margin-right: 16px;
  margin-bottom: 16px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ChartWrapper = styled.div`
  min-height: 240px;
  max-height: 260px;
  width: 100%;
  margin-right: 20px;
`;

const BreakdownWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  padding-top: 16px;
  width: 100%;
`;

const DarkNumber = styled.span`
  font-size: 22px;
  font-weight: 800;
  color: ${(props) => props.theme.palette.primary.dark};
`;

const BreakdownItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SmallText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.primary.dark};
`;

const HighlightQ = styled.span`
  margin-right: 4px;
  font-weight: 700;
  font-style: italic;
`;

const SmallTextGrey = styled.span`
  font-size: 11px;
`;

const OptionsWrapper = styled.div`
  min-height: 100px;
  width: 100%;
  padding: 0px 20px;
`;

const TitleText = styled(Typography)`
  font-weight: 800;
  font-size: 13px;
  font-style: italic;
`;

const OptionDisplay = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]};
  margin: 2px 0px;
  text-overflow: ellipsis;
`;

const AnswerSmall = styled(Typography)`
  font-size: 10px;
  font-style: italic;
  margin-left: 8px;
  text-overflow: ellipsis;
`;

const FloatChip = styled(Chip)`
  border: 1px solid black;
  font-size: 10px;
  background-color: white;
  position: absolute;
  left: 5px;
  top: 5px;
`;

const options = {
  maintainAspectRatio: false,
  responsive: true,
  cornerRadius: 2,
  indexAxis: "x",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: () => "",
        beforeBody: (c) => `# Submissions : ${c[0].raw}`,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value) => {
          if (Number.isInteger(value)) return value;
        },
        color: "black",
      },
      title: {
        display: true,
        text: "Count",
      },
    },

    x: {
      title: {
        display: true,
        text: "Submitted Answers",
      },
      ticks: {
        color: "black",
      },
    },
  },
};

const getLabel = (question) => {
  switch (question.type) {
    case "mc":
      return "M/C";
    case "tf":
      return "T/F";
    case "sa":
      return "S/A";
    default:
      return "";
  }
};

const AnswerDistribution = ({ question }) => {
  const getAnswer = (q) => {
    if (q.type !== "sa") {
      return q.correctAnswer.toUpperCase();
    }

    if (Array.isArray(q.correctAnswer)) {
      return q.correctAnswer.map((letter) => letter.toUpperCase()).join(",");
    }
    return q.correctAnswer;
  };

  const formatAnswer = (answer, keepLow = false) => {
    if (Array.isArray(answer)) {
      const retArr = answer.map((letter) =>
        keepLow ? letter.toLowerCase() : letter.toUpperCase(),
      );
      return retArr.join(",");
    }
    return keepLow ? answer.toLowerCase() : answer.toUpperCase();
  };

  const totalSubmitted = Object.values(question.answerDistribution)

    .reduce((total, acc) => {
      return (total += acc);
    }, 0);
  const totalCorrect = question.answerDistribution[
    formatAnswer(question.correctAnswer, true)
  ]
    ? question.answerDistribution[formatAnswer(question.correctAnswer, true)]
    : 0;
  // If total correct is undefined return 0, else return the percentage
  const percentageCorrect =
    totalCorrect === 0 ? 0 : totalCorrect / totalSubmitted;

  const data = {
    labels: Object.keys(question.answerDistribution).map((val) =>
      val.toUpperCase(),
    ),
    datasets: [
      {
        label: "Submitted Options",
        data: Object.values(question.answerDistribution),
        barPercentage: 0.5,
        borderRadius: 2,
        minBarLength: 2,
        backgroundColor: Object.keys(question.answerDistribution).map((key) => {
          if (key.toUpperCase() === getAnswer(question)) return "rgb(23,62,88)";
          return "rgba(0, 0, 0, .2)";
        }),
        borderColor: "black",
        categoryPercentage: 0.9,
      },
    ],
  };

  return (
    <Wrapper>
      <FloatChip size="small" label={getLabel(question)} />
      <HeaderWrapper>
        <TitleText variant="subtitle2" align="center" mt={2} mb={-1}>
          <HighlightQ>{`Q${question.order + 1})`}</HighlightQ>
          {question.question}
        </TitleText>
        <SmallText variant="body2" align="center">
          {question.type !== "sa"
            ? `Correct Answer (${question.correctAnswer.toUpperCase()})`
            : `Correct Answer (${formatAnswer(question.correctAnswer)})`}
        </SmallText>
      </HeaderWrapper>
      {question.type !== "tf" && (
        <>
          <OptionsWrapper>
            {Object.entries(question.options).map((option) => (
              <OptionDisplay key={option[0]}>
                {question.type === "sa" ? (
                  <SmallAvatar
                    $isCorrect={formatAnswer(question.correctAnswer).includes(
                      option[0].toUpperCase(),
                    )}
                  >
                    {option[0].toUpperCase()}
                  </SmallAvatar>
                ) : (
                  <SmallAvatar
                    $isCorrect={
                      question.correctAnswer.toUpperCase() ===
                      option[0].toUpperCase()
                    }
                  >
                    {option[0].toUpperCase()}
                  </SmallAvatar>
                )}
                <Tooltip title={option[1]}>
                  <AnswerSmall>{option[1]}</AnswerSmall>
                </Tooltip>
              </OptionDisplay>
            ))}
          </OptionsWrapper>
        </>
      )}
      <ChartWrapper>
        <Bar data={data} options={options} />
      </ChartWrapper>
      <BreakdownWrapper>
        <BreakdownItem>
          <SmallTextGrey>Total Answers</SmallTextGrey>
          <DarkNumber>{totalSubmitted}</DarkNumber>
        </BreakdownItem>
        <BreakdownItem>
          <SmallTextGrey>Correct Answers</SmallTextGrey>
          <DarkNumber>{totalCorrect}</DarkNumber>
        </BreakdownItem>
        <BreakdownItem>
          <SmallTextGrey>Incorrect Answers</SmallTextGrey>
          <DarkNumber>{totalSubmitted - totalCorrect}</DarkNumber>
        </BreakdownItem>
      </BreakdownWrapper>
      <AverageScoreBar title="Percentage Correct" value={percentageCorrect} />
    </Wrapper>
  );
};

export default AnswerDistribution;

AnswerDistribution.propTypes = {
  question: PropTypes.object.isRequired,
};
