import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ListItem, Chip, ListItemText } from "@material-ui/core";
import { rgba, darken } from "polished";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    transition: all 200ms ease-in;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  // background: ${(props) => props.theme.sidebar.badge.background};
  background: #337ab7;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => (
  <Category {...rest}>
    {icon}
    <CategoryText>{name}</CategoryText>
    {isCollapsable ? (
      isOpen ? (
        <CategoryIconMore />
      ) : (
        <CategoryIconLess />
      )
    ) : null}
    {badge ? <CategoryBadge label={badge} /> : ""}
  </Category>
);

export default SidebarCategory;

SidebarCategory.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.node.isRequired,
  classes: PropTypes.node,
  isOpen: PropTypes.bool,
  isCollapsable: PropTypes.bool.isRequired,
  badge: PropTypes.node,
};

SidebarCategory.defaultProps = {
  classes: null,
  name: null,
  isOpen: false,
  badge: null,
};
