import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Paper, LinearProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AuthLayout from "../../layouts/Auth";
import { Typography } from "../private/styles";
import API from "../../axios/instances/API";

const Wrapper = styled(Paper)`
  min-height: 300px;
  width: calc(min(400px, 96vw));
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HighlightLink = styled(Link)`
  color: ${(props) => props.theme.palette.info.light};
  font-weight: 600;
  font-size: 14px;
`;

const WideLinear = styled(LinearProgress)`
  min-width: 100%;
`;

const VerifyEmail = ({ match }) => {
  const [isVerifying, setIsVerifying] = useState(true);
  const [returnText, setReturnText] = useState("");
  const { token } = match.params;

  useEffect(() => {
    const verify = async () => {
      try {
        await API.post("/dashboard/account/verify/", {
          token,
        });
        setIsVerifying(false);
        return setReturnText("Account Verified");
      } catch (error) {
        setIsVerifying(false);
        const { msg } = error.response.data;
        if (msg === "invalid_token") {
          return setReturnText("Your Token is Invalid");
        }
        if (msg !== "invalid_token") {
          return setReturnText("Issue has Occured");
        }
        return null;
      }
    };
    return verify();
  }, []);

  return (
    <AuthLayout>
      <Wrapper>
        <Typography variant="h3" align="center" mb={2}>
          {isVerifying ? "Verifying Your Account.." : returnText}
        </Typography>
        {isVerifying && <WideLinear variant="indeterminate" color="primary" />}
        {returnText === "Account Verified" && (
          <Typography variant="body2">
            Feel free to <HighlightLink to="/">Sign In</HighlightLink>
          </Typography>
        )}
        {returnText && returnText !== "Account Verified" && (
          <Typography variant="body2">
            Please Retry Signup Process{" "}
            <HighlightLink to="/register">Here</HighlightLink>
          </Typography>
        )}
      </Wrapper>
    </AuthLayout>
  );
};

export default VerifyEmail;

VerifyEmail.propTypes = {
  match: PropTypes.object.isRequired,
};
