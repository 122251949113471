import { CircularProgress, Grid, Paper, Switch } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Send } from "react-feather";
import styled from "styled-components";
import { mutate } from "swr";
import * as Yup from "yup";
import API from "../../../axios/instances/API";
import ExtraInfo from "../../../components/ExtraInfo";
import ErrorAlertInvite from "../../../components/UI/ErrorAlertInvite";
import { getInvitesErrorMsgServer } from "../../../storage/helpers";
import useLocations from "../../../swr/hooks/Locations/useLocations";
import EmailField from "../../public/Inputs/EmailField";
import FirstNameField from "../../public/Inputs/FirstNameField";
import LastNameField from "../../public/Inputs/LastNameField";
import { Button, SubtitleSmall, TextField, Typography } from "../styles";
import PreInfoDialog from "./PreInfoDialog";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  createTeamProfile: true,
};

const Wrapper = styled(Paper)`
  padding: 30px;
  width: ${(props) => props.theme.spacing(160)}px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  border: 2px solid black;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  margin-bottom: -4px;
  margin-top: -4px;

  p {
    font-weight: 400;
    font-size: 13px;
    color: ${(props) => props.theme.palette.grey[600]};
    margin-left: 4px;
  }
`;

const LoaderWrapper = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: ${(props) => props.theme.spacing(160)}px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const validationSchema = Yup.object({
  email: Yup.string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: Yup.string("Enter first name").required("First name is required"),
  lastName: Yup.string("Enter last name").required("Last name is required"),
  createTeamProfile: Yup.boolean(),
});

const InviteSupervisor = ({ setSnackbarText, setCreateManagerOpen }) => {
  const [comboValue, setComboValue] = useState("");
  const [comboError, setComboError] = useState(false);

  const { locations, isLoading } = useLocations(); // tested

  const [dialogInfo, setDialogInfo] = useState(null);

  const [errorAlert, setErrorAlert] = useState(null);

  if (isLoading) {
    return (
      <Wrapper>
        <LoaderWrapper>
          <span style={{ color: "darkgrey" }}>loading...</span>
        </LoaderWrapper>
      </Wrapper>
    );
  }

  const autocompleteOptions = locations.locations.map((location) => ({
    name: location.name,
    id: location.id,
  }));

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    setErrorAlert(null);
    if (!comboValue || comboValue.length === 0) return setComboError(true);
    setSubmitting(true);
    const sendObj = {
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      create_team_member: values.createTeamProfile,
      locations: comboValue.map((location) => location.id),
    };
    try {
      await API.post("dashboard/managers/invitations/", sendObj);
      await mutate("/dashboard/managers/invitations/");
      setSnackbarText(
        `Supervisor invitation sent to ${values.firstName} ${values.lastName} success`,
      );
      setSubmitting(false);
      setCreateManagerOpen(false);
    } catch (error) {
      setSubmitting(false);
      const errorObj = getInvitesErrorMsgServer(error);

      if (errorObj.status === 409) {
        errorObj.body = sendObj;
        return setDialogInfo(errorObj);
      }

      setErrorAlert(errorObj);
    }
  };

  const handleSubmitFromDialog = async (obj) => {
    const sendObj = { ...obj, confirm: true };
    await API.post("dashboard/managers/invitations/details/", sendObj);
    await mutate("/dashboard/managers/invitations/");
    setSnackbarText(
      `Invite sent to ${obj.first_name} ${obj.last_name} success`,
    );
  };

  const handleCloseDialog = () => {
    setDialogInfo(null);
    setCreateManagerOpen(false);
  };

  return (
    <Wrapper>
      <PreInfoDialog
        open={Boolean(dialogInfo)}
        info={dialogInfo}
        handleSubmitFromDialog={handleSubmitFromDialog}
        handleClose={handleCloseDialog}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography align="left" variant="h3">
          Invite A Supervisor
        </Typography>
      </div>
      <Typography align="left" variant="subtitle1" color="textPrimary" mb={2}>
        Fill out form to invite a Supervisor
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <EmailField
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <FirstNameField
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <LastNameField
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <SubtitleSmall style={{ marginBottom: 4 }}>
                  Select Location To Supervise
                </SubtitleSmall>
                <Autocomplete
                  multiple
                  size="small"
                  options={autocompleteOptions}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) => setComboValue(newValue)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      // label="Location To Supervise"
                      error={
                        comboError && (!comboValue || comboValue.length === 0)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FlexDiv>
                  <ExtraInfo
                    saved={values.createTeamProfile}
                    text="If checked, a team member account will also be created for this supervisor. This will give the supervisor app access."
                    savedText={"Team member account will be created"}
                  />
                  <p>Create team member account as well?</p>
                  <Switch
                    checked={values.createTeamProfile}
                    size="medium"
                    name="createTeamProfile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FlexDiv>
              </Grid>
              <Grid item xs={12}>
                <Button
                  mt={2}
                  p={2}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting || !isValid || comboValue.length < 1}
                  startIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  {isSubmitting ? "Submitting" : "Invite Supervisor"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      {errorAlert && (
        <>
          <div style={{ height: 12 }} />
          <ErrorAlertInvite
            errorMsg={errorAlert}
            handleClose={() => setErrorAlert(null)}
          />
        </>
      )}
    </Wrapper>
  );
};

export default InviteSupervisor;

InviteSupervisor.propTypes = {
  setSnackbarText: PropTypes.func.isRequired,
  setCreateManagerOpen: PropTypes.func.isRequired,
};
