import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import styled from "styled-components";
import { QuestionAvatar } from "../../../moduleBuilder/styles";
import { Typography } from "../../../styles";

const Wrapper = styled.div`
  padding: 0px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const AnswerAvatar = styled(Avatar)`
  height: 18px;
  width: 18px;
  font-size: 11px;
  font-weigth: 600;
  margin-right: 12px;
  background-color: ${(props) => {
    if (props.activecolor === "correct") return "mediumseagreen";
    if (props.activecolor === "failed") return "tomato";
    return props.theme.palette.grey[400];
  }};
`;

const SmallText = styled.span`
  font-size: 10px;
`;

const AnswerHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

const AnswerBody = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 2px;
  padding: 2px 0px;
`;

const AnswerContainerLarge = styled.div`
  flex: 0.8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const AnswerContainerSmall = styled.div`
  flex: 0.2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MCEval = ({ answer }) => (
  <Wrapper>
    <AnswerHeader>
      <AnswerContainerLarge>
        <SmallText>Options</SmallText>
      </AnswerContainerLarge>
      <AnswerContainerSmall>
        <SmallText>
          Correct {`(${answer.question.correctAnswer.toUpperCase()})`}
        </SmallText>
      </AnswerContainerSmall>
      <AnswerContainerSmall>
        <SmallText>Submitted {`(${answer.answer.toUpperCase()})`}</SmallText>
      </AnswerContainerSmall>
    </AnswerHeader>
    {Object.entries(answer.question.possibleAnswers).map(([key, text]) => (
      <AnswerBody variant="subtitle2" key={text}>
        <AnswerContainerLarge>
          <Typography variant="body2">{text}</Typography>
        </AnswerContainerLarge>
        <AnswerContainerSmall>
          <QuestionAvatar
            activecolor={
              key.toUpperCase() === answer.question.correctAnswer.toUpperCase()
                ? "Yes"
                : "No"
            }
          >
            {key.toUpperCase()}
          </QuestionAvatar>
        </AnswerContainerSmall>
        <AnswerContainerSmall>
          <AnswerAvatar
            activecolor={
              key.toUpperCase() === answer.answer.toUpperCase()
                ? answer.correct
                  ? "correct"
                  : "failed"
                : "No"
            }
          >
            {key.toUpperCase()}
          </AnswerAvatar>
        </AnswerContainerSmall>
      </AnswerBody>
    ))}
  </Wrapper>
);

export default MCEval;

MCEval.propTypes = {
  answer: PropTypes.object.isRequired,
};
