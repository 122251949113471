import React, { useContext, useRef, useState } from "react";
import { Button, Modal, Popover, Tooltip } from "@material-ui/core";
import { Plus, Repeat } from "react-feather";
import styled from "styled-components";
import { GlobalState } from "../../../store/GlobalState";
import EditCompanyDescription from "../../Company/EditCompanyDescription";
import EditCompanyLogo from "../../Company/EditCompanyLogo";
import EditCompanyName from "../../Company/EditCompanyName";
import SnackbarWrapper from "../../SnackbarWrapper";
import SelectCompanyModal from "../SelectCompanyModal";
import DropdownInner from "./DropdownInner";

const StyledButton = styled(Button)`
  height: 36px;
  width: 36px;
  min-height: 36px;
  max-height: 36px;
  max-width: 36px;
  border-radius: 18px;
  min-width: 36px;
  background-color: #eeeeee34;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: background-color 200ms ease-in;
  position: relative;

  svg {
    color: slategrey;
  }
  &:hover {
    background-color: #eeeeee;

    svg {
      color: black;
    }
  }
`;

const ActionButtonTransparent = ({
  handleClick,
  disabled = false,
  title = "title",
  Icon,
}) => {
  return (
    <>
      <Tooltip arrow placement="bottom" title={title}>
        <StyledButton onClick={handleClick} disabled={disabled}>
          <Icon height={14} width={14} />
        </StyledButton>
      </Tooltip>
    </>
  );
};

const CompanyContainer = styled.div`
  height: 42px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;

  p {
    margin-left: 6px;
    font-size: 12px;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const CompanyImage = styled.img`
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 12px;
  border: 1px solid #eeeeee;
  background-color: white;
  cursor: pointer;
  transition: all 240ms ease-in;

  &:hover {
    border: 1px solid slategray;
    transform: scale(1.02);
  }
`;

const AddCompanyImage = styled(Button)`
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  max-width: 40px;
  min-width: 40px;
  width: 40px;
  border-radius: 12px;
  border: 2px dashed #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    border: 2px dashed ${(props) => props.theme.palette.info.light};
  }

  svg {
    height: 14px;
    width: 14px;
    color: ${(props) => props.theme.palette.grey[400]};
  }
`;

const CurrentCompanyName = styled.p`
  @media (max-width: 600px) {
    display: none;
  }
`;

const CompanyDropdown = () => {
  const { state, dispatch } = useContext(GlobalState);
  const { config, job, isRetail } = state;
  const [editLogoModalOpen, setEditLogoModalOpen] = useState(false);
  const [editCompanyName, setEditCompanyName] = useState(false);
  const [switchCompanyOpen, setSwitchCompanyOpen] = useState(false);
  const [editCompanyDescription, setEditCompanyDescription] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const {
    current_company: currentCompany,
    user,
    available_companies: availableCompanies,
  } = config;

  const isManager = job === "M";

  const canSwitchCompanies = availableCompanies?.length > 1;

  const hasLogo = Boolean(currentCompany?.logo);

  if (!currentCompany) return null;

  return (
    <>
      <CompanyContainer>
        <div style={{ marginRight: "auto" }} />
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip
            arrow
            title={hasLogo ? "Company" : "Add Logo"}
            placement="bottom"
          >
            {hasLogo ? (
              <CompanyImage
                ref={ref}
                onClick={() => setOpen((p) => !p)}
                src={currentCompany.logo}
                alt="brand logo"
              />
            ) : (
              <AddCompanyImage
                onClick={() => setEditLogoModalOpen((p) => !p)}
                disabled={isManager}
              >
                <Plus />
              </AddCompanyImage>
            )}
          </Tooltip>
          <CurrentCompanyName>{currentCompany.name}</CurrentCompanyName>
        </div>
        {canSwitchCompanies && (
          <div style={{ position: "relative" }}>
            <ActionButtonTransparent
              Icon={Repeat}
              title="Switch Company"
              handleClick={() => setSwitchCompanyOpen((p) => !p)}
            />

            <div
              style={{
                position: "absolute",
                top: -3,
                right: -3,
                height: 14,
                width: 14,
                background: "#907ac63b",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{ fontSize: 8, fontWeight: "600", color: "#813eee" }}
              >
                {availableCompanies?.length}
              </span>
            </div>
          </div>
        )}
      </CompanyContainer>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={open}
      >
        <DropdownInner
          isRetail={isRetail}
          isManager={isManager}
          setEditLogoModalOpen={setEditLogoModalOpen}
          setEditCompanyName={setEditCompanyName}
          setEditCompanyDescription={setEditCompanyDescription}
        />
      </Popover>
      <Modal
        open={editLogoModalOpen}
        onClose={() => setEditLogoModalOpen(false)}
        aria-labelledby="Change companys logo"
        aria-describedby="modal to change company logo"
      >
        <div>
          <EditCompanyLogo
            handleClose={() => setEditLogoModalOpen(false)}
            setSnackbarText={setSnackbarText}
          />
        </div>
      </Modal>
      {!isRetail && (
        <Modal
          open={editCompanyDescription}
          onClose={() => setEditCompanyDescription(false)}
        >
          <div>
            <EditCompanyDescription
              handleClose={() => setEditCompanyDescription(false)}
              setSnackbarText={setSnackbarText}
            />
          </div>
        </Modal>
      )}
      <Modal
        open={editCompanyName}
        onClose={() => setEditCompanyName(false)}
        aria-labelledby="Change companys name"
        aria-describedby="modal to change company name"
      >
        <div>
          <EditCompanyName
            setSnackbarText={setSnackbarText}
            handleClose={() => setEditCompanyName(false)}
          />
        </div>
      </Modal>
      <Modal
        open={switchCompanyOpen}
        onClose={() => setSwitchCompanyOpen(false)}
        aria-labelledby="switch company"
        aria-describedby="modal to switch company"
      >
        <div>
          <SelectCompanyModal
            availableCompanies={availableCompanies}
            isManager={isManager}
            currentCompanyId={currentCompany.id}
            handleClose={() => setSwitchCompanyOpen(false)}
            dispatch={dispatch}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default CompanyDropdown;
