import React from "react";
import { TextField } from "../../private/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

const LocationSelectMultiple = ({
  locations,
  handleBlur,
  values,
  setFieldValue,
}) => {
  return (
    <Autocomplete
      size="small"
      options={locations}
      multiple
      noOptionsText="No Locations Available"
      getOptionLabel={(option) => option.name}
      onChange={(_, newValue) => {
        setFieldValue("location", newValue || []);
      }}
      value={values.location}
      getOptionSelected={(option, value) => option.id === value.id}
      onBlur={handleBlur}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
};

export default LocationSelectMultiple;
