import React, { useContext } from "react";
import { MenuItem, Select, Tooltip } from "@material-ui/core";
import { Edit2, Eye, Lock } from "react-feather";
import styled from "styled-components";
import { ACCESS_LEVELS } from "../../storage/constants";
import { GlobalState } from "../../store/GlobalState";

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 28px;
  max-height: 28px;
  flex-direction: row;

  svg {
    margin-right: 4px;
  }
`;

const CompanyImg = styled.img`
  height: 28px;
  width: 28px;
  object-fit: contain;
  border-radius: 50%;
  border: 1px solid #eeeeee;
  margin-right: 8px;
  filter: ${(props) => (props.$isDisabled ? "grayscale(1)" : "none")};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  overflow-y: scroll;
  border-radius: 6px;
  position: relative;
`;

const LockWrapper = styled.div`
  height: 18px;
  width: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #eeeeee;
  margin-left: 4px;
  margin-right: 2px;
`;

const Item = styled.div`
  min-height: 40px;
  width: 100%;
  border-bottom: 1px dashed gainsboro;
  display: flex;
  align-items: center;
`;

const RightSideContainer = styled.div`
  flex-basis: 140px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftSideContainer = styled.div`
  flex-grow: 1;
  background: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  background: white;
  top: -1px;
  z-index: 10;
`;

const DisplayOtherCompanies = ({ otherCompanies, handleChange }) => (
  <>
    <Header
      style={{
        position: "sticky",
      }}
    >
      <LeftSideContainer>
        <span style={{ fontWeight: "500", color: "slategrey" }}>
          Other Companies
        </span>
      </LeftSideContainer>
      <RightSideContainer>
        <span style={{ fontWeight: 500, color: "slategrey" }}>
          {" "}
          Admin Status
        </span>
      </RightSideContainer>
    </Header>
    {otherCompanies?.map((obj) => (
      <Item key={obj?.company_id}>
        <LeftSideContainer>
          <CompanyImg $isDisabled={obj?.disabled} src={obj?.company_logo} />
          <span
            style={{
              color: obj?.disabled ? "darkgrey" : "black",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {obj?.company_name}
          </span>
          {obj?.disabled && <LockedIcon />}
        </LeftSideContainer>
        <RightSideContainer>
          <Select
            value={obj?.admins_access}
            disabled={obj?.disabled}
            style={{
              height: 28,
              maxHeight: 28,
              width: "96%",
              background: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 6,
              overflow: "hidden",
            }}
            fullWidth
            variant="outlined"
            size="small"
            onChange={(e) => handleChange(e, obj?.company_id)}
          >
            <StyledMenuItem value={ACCESS_LEVELS.NONE}>
              <Lock
                height={10}
                width={10}
                color={obj?.disabled ? "lightgray" : "firebrick"}
                style={{ marginRight: 4 }}
              />
              <span style={{ fontSize: 12, fontWeight: 500 }}>No Access</span>
            </StyledMenuItem>
            <StyledMenuItem value={ACCESS_LEVELS.READ_ONLY}>
              <Eye
                style={{ marginRight: 4 }}
                height={10}
                width={10}
                color={obj?.disabled ? "lightgray" : "black"}
              />
              <span style={{ fontSize: 12, fontWeight: 500 }}>Read Only</span>
            </StyledMenuItem>
            <StyledMenuItem value={ACCESS_LEVELS.WRITE}>
              <Edit2
                style={{ marginRight: 4 }}
                height={10}
                width={10}
                // color="black"
                color={obj?.disabled ? "lightgray" : "black"}
              />

              <span style={{ fontSize: 12, fontWeight: 500 }}>
                Write Access
              </span>
            </StyledMenuItem>
          </Select>
        </RightSideContainer>
      </Item>
    ))}
  </>
);

const LockedIcon = () => (
  <Tooltip
    title="You need write permissions at this company before editing."
    arrow
  >
    <LockWrapper>
      <Lock height={11} width={11} color="slategrey" />
    </LockWrapper>
  </Tooltip>
);

const AccessTable = ({ accessState, handleChange, maxHeight = 240 }) => {
  const { state } = useContext(GlobalState);

  if (!accessState) {
    return <div style={{ height: maxHeight, width: "100%" }} />;
  }

  const currentCompany = accessState.find(
    (obj) => obj?.company_id === state?.config?.current_company?.id,
  );

  const otherCompanies = accessState.filter(
    (c) => c.company_id !== state?.config?.current_company?.id,
  );

  const hasOtherCompanies = otherCompanies?.length > 0;

  return (
    <Wrapper
      style={{
        maxHeight: maxHeight,
      }}
    >
      <Header>
        <LeftSideContainer>
          <span style={{ fontWeight: "500", color: "slategrey" }}>
            Current Company
          </span>
        </LeftSideContainer>

        <RightSideContainer>
          <span style={{ fontWeight: 500, color: "slategrey" }}>
            {" "}
            Admin Status
          </span>
        </RightSideContainer>
      </Header>
      <Item key={currentCompany?.company_id}>
        <LeftSideContainer>
          <CompanyImg
            $isDisabled={currentCompany?.disabled}
            src={currentCompany?.company_logo}
          />
          <span
            style={{
              color: currentCompany?.disabled ? "darkgrey" : "black",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {currentCompany?.company_name}
          </span>
          {currentCompany?.disabled && <LockedIcon />}
        </LeftSideContainer>

        <RightSideContainer>
          <Select
            value={currentCompany?.admins_access}
            disabled={currentCompany?.disabled}
            style={{
              height: 28,
              maxHeight: 28,
              width: "96%",
              background: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 6,
              overflow: "hidden",
            }}
            fullWidth
            variant="outlined"
            size="small"
            onChange={(e) => handleChange(e, currentCompany?.company_id)}
          >
            <StyledMenuItem value={ACCESS_LEVELS.NONE}>
              <Lock
                height={10}
                width={10}
                color={currentCompany?.disabled ? "lightgray" : "firebrick"}
                style={{ marginRight: 6 }}
              />
              <span style={{ fontSize: 12, fontWeight: 500 }}>No Access</span>
            </StyledMenuItem>
            <StyledMenuItem value={ACCESS_LEVELS.READ_ONLY}>
              <Eye
                style={{ marginRight: 6 }}
                height={10}
                width={10}
                color={currentCompany?.disabled ? "lightgray" : "black"}
              />
              <span style={{ fontSize: 12, fontWeight: 500 }}>Read Only</span>
            </StyledMenuItem>
            <StyledMenuItem value={ACCESS_LEVELS.WRITE}>
              <Edit2
                style={{ marginRight: 6 }}
                height={10}
                width={10}
                // color="black"
                color={currentCompany?.disabled ? "lightgray" : "black"}
              />

              <span style={{ fontSize: 12, fontWeight: 500 }}>
                Write Access
              </span>
            </StyledMenuItem>
          </Select>
        </RightSideContainer>
      </Item>
      <div style={{ height: 12, minHeight: 12 }} />
      {hasOtherCompanies && (
        <DisplayOtherCompanies
          otherCompanies={otherCompanies}
          handleChange={handleChange}
        />
      )}
    </Wrapper>
  );
};

export default AccessTable;
