import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { Button } from "../../../pages/private/styles";
import { AlertTriangle, Check, MapPin } from "react-feather";
import useAudienceToolStore from "../../../store/useAudienceToolStore";

const Wrapper = styled(Paper)`
  padding: 10px;
  width: 340px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  max-width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const Item = styled.div`
  width: 100%;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 6px;
  background: #eeeeee77;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    transform: scale(1.003);
  }
`;

const List = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 8px;
  padding-top: 8px;
  overflow-y: scroll;
  padding-bottom: 8px;
`;

const LocationSelectionModal = ({ locations, handleApply, handleClose }) => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const currentAudienceType = useAudienceToolStore(
    (state) => state.currentAudienceType,
  );
  const currentAudience = useAudienceToolStore(
    (state) => state.currentAudience,
  );

  useEffect(() => {
    if (currentAudienceType === "L") {
      const locationIds = Object.keys(currentAudience);
      setSelectedLocations(locationIds.map((id) => Number(id)));
    }
  }, []);

  const isDisabled = selectedLocations.length < 1;

  if (locations.length === 0) {
    return (
      <Wrapper>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <AlertTriangle color="darkorange" />
          <div style={{ height: 16 }} />

          <span style={{ color: "black", fontWeight: "600" }}>
            No locations created for this company.
          </span>

          <span
            style={{
              textAlign: "center",
              color: "darkgrey",
              fontSize: 12,
              maxWidth: "80%",
            }}
          >
            Company must have at least 1 location before launching modules
          </span>
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <span style={{ fontSize: 16, fontWeight: "600" }}>Select Locations</span>
      <List>
        {locations.map((location) => (
          <Item
            key={location.id}
            onClick={() =>
              setSelectedLocations((p) =>
                p.includes(location.id)
                  ? p.filter((id) => id !== location.id)
                  : [...p, location.id],
              )
            }
          >
            <MapPin style={{ height: 12, width: 12, marginRight: 8 }} />
            <span
              style={{
                fontWeight: selectedLocations.includes(location.id) ? 500 : 400,
              }}
            >
              {location.name}
            </span>
            <div style={{ marginLeft: "auto" }}>
              {selectedLocations.includes(location.id) ? (
                <div
                  style={{
                    height: 14,
                    width: 14,
                    borderRadius: 4,
                    background: "#337ab7",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Check height={10} width={10} style={{ color: "white" }} />
                </div>
              ) : (
                <div
                  style={{
                    height: 14,
                    width: 14,
                    borderRadius: 4,
                    background: "lightgray",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              )}
            </div>
          </Item>
        ))}
      </List>
      <Button
        style={{
          alignSelf: "flex-end",
          marginTop: "auto",
          background: isDisabled ? "lightgray" : "#337ab7",
          height: 32,
          minWidth: 60,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px 8px",
        }}
        variant="contained"
        color="primary"
        disabled={isDisabled}
        onClick={() => {
          handleApply(selectedLocations);
          handleClose();
        }}
      >
        Confirm & Save
      </Button>
    </Wrapper>
  );
};

export default LocationSelectionModal;
