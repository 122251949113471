import React from "react";
import styled from "styled-components";
import { List } from "react-feather";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Container = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #eeeeee;
  }
`;

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: "none",
    // minWidth: 160,
    maxWidth: 200,
    background: "white",
    minHeight: 140,
    maxHeight: "none",
    minHeight: "none",
    borderRadius: 4,
    padding: "8px",
    border: "1px solid slategrey",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // TODO -> used in many components
  },
}));

const ExplanationTooltip = ({ val }) => {
  const classes = useStyles();

  if (val === "A") {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        arrow
        title={
          <div>
            <p style={{ fontWeight: "500", color: "black", fontSize: 11 }}>
              Team Member
            </p>
            <div style={{ height: 2 }} />
            <span style={{ color: "darkgrey", fontSize: 10 }}>
              Team members get access to the app and are assigned content.
            </span>
          </div>
        }
      >
        <Container>
          <List style={{ color: "darkgrey", height: 12, width: 12 }} />
        </Container>
      </Tooltip>
    );
  }

  if (val === "C") {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        arrow
        title={
          <div>
            <p style={{ fontWeight: "500", color: "black", fontSize: 11 }}>
              Admin
            </p>
            <div style={{ height: 2 }} />

            <span style={{ color: "darkgrey", fontSize: 10 }}>
              Admins get total access to the dashboard. There are multiple
              levels of admins.
            </span>
          </div>
        }
      >
        <Container>
          <List style={{ color: "darkgrey", height: 12, width: 12 }} />
        </Container>
      </Tooltip>
    );
  }

  if (val === "M") {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        arrow
        title={
          <div>
            <p style={{ fontWeight: "500", color: "black", fontSize: 11 }}>
              Supervisor
            </p>
            <div style={{ height: 2 }} />

            <span style={{ color: "darkgrey", fontSize: 10 }}>
              Supervisors get access to a limited version of the dashboard that
              allows them to manage specific locations.
            </span>
          </div>
        }
      >
        <Container>
          <List style={{ color: "darkgrey", height: 12, width: 12 }} />
        </Container>
      </Tooltip>
    );
  }

  return null;
};

export default ExplanationTooltip;
