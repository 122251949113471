/* eslint-disable */
// dr
import { InputAdornment, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { AlertTriangle, CheckCircle, Mail } from "react-feather";
import API from "../../../axios/instances/API";
import { TextField } from "../../private/styles";

const STATUS_OPTIONS = {
  CONFIRMED: "CONFIRMED",
  DENIED: "DENIED",
  UNKNOWN: "UNKNOWN",
};

const EmailField = ({
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
  verifyEmail = false,
  disabled = false,

  setEmailStatus,
  emailStatus,
}) => {
  const checkForEmail = async (checkEmail) => {
    try {
      setEmailCheckLoading(true);
      const { data } = await API.get(
        `/common/account/check-for-existence/?username=${checkEmail}`,
      );

      const { exists } = data;
      setEmailStatus(exists ? STATUS_OPTIONS.DENIED : STATUS_OPTIONS.CONFIRMED);
      return setEmailCheckLoading(false);
    } catch (error) {
      return setEmailCheckLoading(false);
    }
  };

  const [emailCheckLoading, setEmailCheckLoading] = useState(false);

  return (
    <TextField
      type="email"
      name="email"
      label="Email / Username"
      variant="outlined"
      size="small"
      autoFocus
      value={values.email}
      mt={4}
      fullWidth
      disabled={disabled}
      onChange={(e) => {
        if (verifyEmail) {
          setEmailStatus(STATUS_OPTIONS.UNKNOWN);
        }
        handleChange(e);
      }}
      onBlur={(e) => {
        if (!errors.email && verifyEmail) {
          checkForEmail(values.email);
        }
        handleBlur(e);
      }}
      error={Boolean(
        (touched.email && errors.email) ||
          emailStatus === STATUS_OPTIONS.DENIED,
      )}
      helperText={
        touched.email && errors.email
          ? errors.email
          : emailStatus === STATUS_OPTIONS.DENIED && verifyEmail
            ? "There is already an account with this email"
            : null
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Mail style={{ height: 14, width: 14, color: "darkgrey" }} />
          </InputAdornment>
        ),
        endAdornment: verifyEmail ? (
          <InputAdornment position="end" style={{ cursor: "pointer" }}>
            {!errors.email &&
              !emailCheckLoading &&
              emailStatus !== STATUS_OPTIONS.UNKNOWN && (
                <Tooltip
                  title={
                    emailStatus === STATUS_OPTIONS.CONFIRMED
                      ? "Email Available"
                      : "Already an account with this email"
                  }
                  arrow
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 14,
                      width: 14,
                      borderRadius: 10,
                    }}
                  >
                    {emailStatus === STATUS_OPTIONS.CONFIRMED ? (
                      <CheckCircle style={{ color: "mediumseagreen" }} />
                    ) : (
                      <AlertTriangle style={{ color: "darkorange" }} />
                    )}
                  </div>
                </Tooltip>
              )}
          </InputAdornment>
        ) : null,
        autoComplete: "new-password",
        form: {
          autoComplete: "off",
        },
      }}
    />
  );
};

export default EmailField;
