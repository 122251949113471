import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import styled from "styled-components";
import { Button } from "../../styles";

const StyledButton = styled(Button)`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0px;
  background: #eeeeee;
`;

const Wrapper = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const getPageData = (pageNumber, pageSize, totalRows) => {
  const start = pageNumber * pageSize - pageSize;
  const end = pageNumber * pageSize;
  const numPages = Math.ceil(totalRows / pageSize);

  return {
    start,
    end: end > totalRows ? totalRows : end,
    numPages,
  };
};

const CustomFooter = ({
  isLoading,
  queryData,
  setPageNumber,
  pageNumber,
  pageSize,
  // setPageSize,
}) => {
  const { start, end, numPages } = getPageData(
    pageNumber,
    pageSize,
    queryData.submissions_count,
  );

  return (
    <Wrapper>
      <div
        style={{
          marginRight: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: 6,
        }}
      >
        {!isLoading && (
          <span style={{ fontSize: 12, color: "darkgrey" }}>
            Showing submissions {start + 1}-{end} of{" "}
            {queryData.submissions_count}
          </span>
        )}
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: 12, color: "darkgrey" }}>Loading...</span>
            <CircularProgress
              size={14}
              style={{ color: "black", marginLeft: 12, marginRight: 6 }}
            />
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0px 8px",
        }}
      >
        <StyledButton
          variant="text"
          color="inherit"
          disabled={!queryData.has_previous || pageNumber === 1}
          onClick={() => setPageNumber((p) => p - 1)}
        >
          <ChevronLeft height={16} width={16} />
        </StyledButton>
        <div
          style={{
            width: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ color: "black", fontSize: 13 }}>
            Page <b style={{ color: "black" }}>{pageNumber}</b> of{" "}
            <b style={{ color: "black" }}>{numPages}</b>
          </p>
        </div>
        <StyledButton
          variant="text"
          color="inherit"
          disabled={!queryData.has_next}
          onClick={() => setPageNumber((p) => p + 1)}
        >
          <ChevronRight height={16} width={16} />
        </StyledButton>
      </div>
    </Wrapper>
  );
};

export default CustomFooter;

CustomFooter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  queryData: PropTypes.object,
};

CustomFooter.defaultProps = {
  queryData: null,
};
