import React from "react";
import styled from "styled-components";
import { Wrapper } from "../auth/styles";
import { CheckCircle, AlertTriangle } from "react-feather";

const UpperContainer = styled.div`
  width: 100%;
  height: 200px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 12px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #eeeeee50;
`;

const HighlightSpan = styled.span`
  font-size: 12px;
  color: black;
  font-weight: 700;
`;

const HighlightTag = styled.a`
  font-size: 12px;
  color: rgb(51, 122, 183);
  font-weight: 700;
  text-decoration: underline;

  &:hover {
    color: dodgerblue;
  }
`;

const BulletText = styled.span`
  color: black;
  text-align: left;
  font-size: 12px;
`;

const RegisterComplete = ({ signupState }) => {
  const { companyInfo, registerInfo } = signupState;

  return (
    <Wrapper>
      <UpperContainer>
        <CheckCircle
          style={{ height: 34, width: 34, color: "mediumseagreen" }}
        />
        <span
          style={{ color: "mediumseagreen", fontWeight: 800, fontSize: 24 }}
        >
          Sign Up Successful
        </span>
      </UpperContainer>

      <Container>
        <p style={{ fontSize: 14, fontWeight: "600" }}>
          <AlertTriangle
            style={{
              height: 16,
              width: 16,
              color: "#e70000",
              marginRight: 4,
              transform: "translate(-1px, 2px)",
            }}
          />
          You cannot access the dashboard yet
        </p>
        <div style={{ height: 8 }} />

        <div style={{ width: "100%", paddingLeft: 22 }}>
          <ul style={{ textAlign: "left" }}>
            <li>
              <BulletText>
                You must first be approved by an admin at{" "}
                <HighlightSpan>{companyInfo.name}</HighlightSpan>.
              </BulletText>
            </li>
            <li>
              <BulletText>
                While your account is pending approval, you can check out our{" "}
                <HighlightTag
                  target="_blank"
                  href="https://knowledge.withspiffy.com/knowledge/introducing-the-spiffy-dashboard"
                >
                  Understanding the Dashboard Guide
                </HighlightTag>{" "}
                to get an overview of the menu and the fuctionality within each
                tab.
              </BulletText>
            </li>
            <li>
              <BulletText>
                We will email{" "}
                <HighlightSpan>{registerInfo.email}</HighlightSpan> when your
                account is ready.
              </BulletText>
            </li>
          </ul>
        </div>
      </Container>
    </Wrapper>
  );
};
3;
export default RegisterComplete;
