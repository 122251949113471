import React, { useState } from "react";
import { Paper, Collapse } from "@material-ui/core";
import styled from "styled-components";
import { apiFormatDate } from "../../storage/helpers";
import { Button, CircularProgress } from "@material-ui/core";
import useAudienceToolStore from "../../store/useAudienceToolStore";
import API from "../../axios/instances/API";
import AudienceTool from "./AudienceTool";
import { USER_TYPES } from "../../storage/constants";
import { TABLE_STATES } from "./AudienceTool/helpers";
import MockHeader from "./AudienceTool/MockHeader";
import { Alert } from "@material-ui/lab";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(980px, 96vw));
  max-height: 90vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
`;

const handleLaunchError = (error) => {
  if (error?.response?.data?.error?.title) {
    return {
      title: error?.response?.data?.error?.title,
      details: error?.response?.data?.error?.details,
    };
  }

  return {
    title: "ERROR",
    details: "Error launching this module. Please try again.",
  };
};

const LaunchBar = ({ launchDisabled, handleClose, moduleId }) => {
  const [isLaunching, setIsLaunching] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);

  const handleLaunchFromRetailLibrary = async () => {
    try {
      setIsLaunching(true);
      const { data: moduleInfo } = await API.get(
        `/dashboard/quiz/review/?id=${moduleId}`,
      );

      /// Update the module details with dates
      const sendObj = {
        id: moduleId,
        due_date: null,
        start_date: apiFormatDate(new Date()),
        name: moduleInfo.name,
        description: moduleInfo.description,
        estimated_time: moduleInfo.estimatedTime,
        language: moduleInfo.language,
        disclaimer: moduleInfo.disclaimer,
        disclaimer_text: moduleInfo.disclaimerText,
        display_answers: moduleInfo.displayAnswers,
        display_answers: false,
      };

      // update module details with todays date as end date
      await API.put("/dashboard/quiz/details/", sendObj);

      // launch module
      await API.post("/dashboard/quiz/deployment/deploy-retail-quiz/", {
        id: moduleId,
      });
      // setIsLaunching(false);
      await handleClose();
    } catch (error) {
      const errorObj = handleLaunchError(error);
      setErrorAlert(errorObj);
      setIsLaunching(false);
    }
  };

  return (
    <Collapse in={!launchDisabled}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-end",
          marginTop: 8,
          borderRadius: 0,

          padding: 4,
        }}
      >
        <div
          style={{
            marginRight: "auto",
            marginLeft: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        />
        <Button
          disabled={launchDisabled || isLaunching}
          variant="contained"
          // color="primary"
          style={{
            backgroundColor:
              launchDisabled || isLaunching ? "lightgray" : "#337ab7",
            color: "#eeeeee",
            marginLeft: "auto",
          }}
          onClick={handleLaunchFromRetailLibrary}
          startIcon={
            isLaunching ? <CircularProgress size={14} color="white" /> : null
          }
        >
          <span style={{ color: launchDisabled ? "darkgrey" : "white" }}>
            {isLaunching ? "Launching Module" : "Launch Module 🚀"}
          </span>
        </Button>
      </div>
      {errorAlert && (
        <Alert
          style={{ width: "100%", marginTop: 4 }}
          severity="error"
          onClose={() => setErrorAlert(null)}
        >
          <p style={{ fontWeight: "600" }}>{errorAlert?.title}</p>
          <p>{errorAlert?.details}</p>
        </Alert>
      )}
    </Collapse>
  );
};

const AddAudienceAndDatesToLaunchV3 = ({
  moduleId,
  handleClose,
  modalState,
}) => {
  const savedAudienceIsEmpty = useAudienceToolStore(
    (state) =>
      !state.currentAudience || Object.keys(state.currentAudience).length === 0,
  );

  const isManager = useAudienceToolStore((state) =>
    [
      USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY,
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
    ].includes(state.userType),
  );
  const tableState = useAudienceToolStore((state) => state.tableState);

  return (
    <ModalWrapper>
      <div style={{ position: "relative" }}>
        <MockHeader
          title="Add audience and launch module"
          secondary={modalState?.moduleName}
        />
        <AudienceTool moduleId={moduleId} />
      </div>
      <LaunchBar
        launchDisabled={
          !isManager ? savedAudienceIsEmpty : tableState !== TABLE_STATES.LOCKED
        }
        handleClose={handleClose}
        moduleId={moduleId}
      />
    </ModalWrapper>
  );
};

export default AddAudienceAndDatesToLaunchV3;
