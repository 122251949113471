import React, { useEffect, useState } from "react";
import { CircularProgress, Paper } from "@material-ui/core";
import { AlertTitle } from "@material-ui/lab";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import API from "../../../../axios/instances/API";
import AccessTable from "../../../../components/AdminAccess/AccessTable";
import { cleanAccessStateForApi } from "../../../../components/AdminAccess/utils";
import { ACCESS_LEVELS } from "../../../../storage/constants";
import useManagerPromotionOptions from "../../../../swr/hooks/Managers/useManagerPromotionOptions";
import { Alert, Button, Typography } from "../../styles";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  min-width: min(540px, 100vw);
  max-width: 600px;
  text-align: left;
  max-height: 90vh;
  position: absolute;
  border-radius: 8px;

  left: 50%;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const getPromoteErrorMsg = (error) => {
  return (
    error?.response?.data?.error?.details || "Failed to promote supervisor."
  );
};

const PromoteModal = ({ promoteModalInfo }) => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);

  const [accessState, setAccessState] = useState([]);

  const { data, isLoading, isError, mutate } = useManagerPromotionOptions(
    promoteModalInfo?.id,
  );

  const getStatefulAccess = (access) => {
    const cleanState = access?.map((obj) => ({
      company_id: obj?.company_id,
      admins_access: obj?.admins_access,
      disabled:
        !obj?.requesters_access ||
        obj?.requesters_access !== ACCESS_LEVELS.WRITE,
      company_name: obj?.company_name,
      company_logo: obj?.company_logo,
    }));
    return cleanState;
  };

  useEffect(() => {
    // if (isLoading) return undefined; // removed //

    const cleanState = getStatefulAccess(data?.access);
    setAccessState(cleanState);
  }, [isLoading]);

  const handlePromote = async () => {
    try {
      setIsSubmitting(true);
      setErrorAlert(false);

      const sendObj = {
        id: promoteModalInfo?.id,
        access_data: cleanAccessStateForApi(accessState),
      };

      await API.put("dashboard/managers/promote/", sendObj);
      await mutate();
      setIsSubmitting(false);
      setSuccessAlert({
        title: `Promoted ${promoteModalInfo.name} to Admin`,
        body: "You will be redirected back to the Supervisor Table.",
      });

      setTimeout(() => {
        history.push("/supervisors/");
      }, 2000);
    } catch (error) {
      const msg = getPromoteErrorMsg(error);
      setErrorAlert(msg);
      setIsSubmitting(false);
    }
  };

  const handleChange = (e, companyId) => {
    // setPromoteCompany(e.target.value);

    const { value } = e.target;
    const updatedArr = accessState?.map((obj) => {
      if (obj?.company_id !== companyId) {
        return {
          ...obj,
        };
      } else {
        return {
          ...obj,
          admins_access: value,
        };
      }
    });

    setAccessState(updatedArr);
  };

  const noAccessGiven = accessState?.every(
    (obj) => obj?.admins_access === ACCESS_LEVELS.NONE,
  );

  return (
    <ModalWrapper>
      {successAlert ? (
        <Alert severity="info">
          <AlertTitle>{successAlert.title}</AlertTitle>
          {successAlert.body}
        </Alert>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography style={{ fontSize: 16, fontWeight: 600 }}>
              Promote {promoteModalInfo?.name}?
            </Typography>

            <span style={{ fontSize: 13 }}>
              You can give <b>{promoteModalInfo.name}</b> admin access below.
            </span>
          </div>
          <div style={{ height: 8, minHeight: 8 }} />
          <AccessTable
            maxHeight={320}
            accessState={accessState}
            handleChange={handleChange}
          />
          <Button
            variant="contained"
            // size="small"
            mt={2}
            color="secondary"
            style={{ marginLeft: "auto" }}
            disabled={isSubmitting || isLoading || isError || noAccessGiven}
            onClick={handlePromote}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {isSubmitting ? "Promoting..." : "Promote"}
          </Button>
          {errorAlert && (
            <Alert mt={3} severity="error" onClose={() => setErrorAlert("")}>
              <p>Error Promoting Supervisor.</p>
              <span>{errorAlert}</span>
            </Alert>
          )}
        </>
      )}
    </ModalWrapper>
  );
};

export default PromoteModal;

PromoteModal.propTypes = {
  promoteModalInfo: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
    .isRequired,
  setPromoteModalInfo: PropTypes.func.isRequired,
};
