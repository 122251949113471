import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.progress;
};

const useLocationProgress = () => {
  const { data, error, mutate } = useSWR(
    "/dashboard/analytics/report/?report=location_completions",
    fetcher,
    {
      // dedupingInterval: 90000, // Minute and a half should be fine //
      revalidateOnFocus: false,
    },
  );

  return {
    progress: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useLocationProgress;
