import React from "react";
import useAudienceOptions from "../../../../../swr/hooks/Audience/useAudienceOptions";
import useAudienceV2 from "../../../../../swr/hooks/Audience/useAudienceV2";
import CurrentAudience from "../../../../../components/audience/currentAudience";
import { AUDIENCETYPES } from "../../../../../storage/constants";
import { CircularProgress } from "@material-ui/core";

const getIdsByAudienceType = (audience, selectedAudience) => {
  if (selectedAudience === "R") {
    return Object.values(audience)?.[0]?.map((v) => Number(v.id));
  }

  if (selectedAudience === AUDIENCETYPES.LOCATIONS) {
    return Object.keys(audience).map((key) => Number(key));
  }

  return [];
};

const ShowSelectedRetailAudience = ({ id, ...props }) => {
  const { isLoading, data } = useAudienceV2(id);

  const { options, isLoading: optionsLoading } = useAudienceOptions();

  if (isLoading || optionsLoading) {
    return (
      <div
        style={{
          minHeight: 120,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={20} />
      </div>
    );
  }

  if (!data) {
    return (
      <div
        style={{
          minHeight: 120,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid slategray",
          borderRadius: 6,
        }}
      >
        <span style={{ color: "black" }}>
          No data.. module could not be found.
        </span>
      </div>
    );
  }

  const selectedAudience =
    Object.keys(data?.audience).length === 0
      ? AUDIENCETYPES.NONE
      : data?.audience_type?.raw;

  const comboIds = getIdsByAudienceType(data?.audience, selectedAudience);
  return (
    <>
      {isLoading || optionsLoading ? (
        <div
          style={{
            minHeight: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={14} />
        </div>
      ) : (
        <CurrentAudience
          moduleId={id}
          options={options}
          comboValue={comboIds} // array of ids of selected roles or locations
          selectedAudience={selectedAudience}
          {...props}
          shouldTableBeViewable={false}
        />
      )}
    </>
  );
};

export default ShowSelectedRetailAudience;
