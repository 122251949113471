import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tab, Paper } from "@material-ui/core";
import TabPanel from "../../../../components/TabPanel";
import API from "../../../../axios/instances/API";
import Basics from "./Basics";
import Content from "./Content";
import Questions from "./Questions";
import { Typography } from "../../styles";
import {
  ReviewAppBar,
  LaunchTabs,
  FlexDiv,
  TabWrapper,
} from "../marketplaceStyles";
import styled from "styled-components";
import FunLoader from "../../../../components/Loaders/FunLoader";

export const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(580px, 96%));
  border-radius: 6px;
  min-height: 600px;
  max-height: 90vh;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 2px solid black;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
`;

const LoadingWrapper = styled.div`
  min-height: 558px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReviewModal = ({
  reviewModalInfo,
  setSnackbarText,
  handleClose,
  isSuggested = false,
  isViewClosed = false,
}) => {
  const [reviewInfo, setReviewInfo] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const getReviewInfo = async () => {
      try {
        if (isSuggested) {
          const { data } = await API.get(
            `dashboard/quiz/pushed/details/?id=${reviewModalInfo.id}`,
          );
          return setReviewInfo(data.quiz);
        }
        if (isViewClosed) {
          const { data } = await API.get(
            `/dashboard/quiz/review/?id=${reviewModalInfo.id}`,
          );
          return setReviewInfo(data);
        }
        const { data } = await API.get(
          `/dashboard/marketplace/quizzes/quiz/?id=${reviewModalInfo.id}`,
        );
        setReviewInfo(data);
      } catch (error) {
        setSnackbarText("Error loading module review info error");
        return handleClose();
      }
    };

    getReviewInfo();
  }, []);

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ModalWrapper>
      {!reviewInfo ? (
        <LoadingWrapper>
          <FunLoader />
        </LoadingWrapper>
      ) : (
        <>
          <FlexDiv>
            <TabWrapper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: 6,
                }}
              >
                <img
                  src={reviewInfo.image}
                  style={{
                    height: 48,
                    width: 48,
                    borderRadius: 6,
                    border: "1px solid #EEEEEE",
                    objectFit: "contain",
                    marginRight: 8,
                    background: "white",
                  }}
                  alt="module"
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    style={{ fontSize: 18, fontWeight: "600", lineHeight: 1.3 }}
                  >
                    {reviewInfo.name}
                  </Typography>
                  {Boolean(reviewModalInfo?.marketplaceParent)}
                  <span
                    style={{
                      lineHeight: 1.3,
                      fontSize: 11,
                      color: "darkgrey",
                      fontWeight: "400",
                    }}
                  >
                    {reviewModalInfo?.marketplaceParent
                      ? `By: ${reviewModalInfo.marketplaceParent}`
                      : ""}
                  </span>
                </div>
              </div>
              <ReviewAppBar position="static">
                <LaunchTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  scrollButtons="off"
                >
                  <Tab label="Details" />
                  <Tab label="Content" />
                  <Tab label="Questions" />
                </LaunchTabs>
              </ReviewAppBar>
              <TabPanel value={tabValue} index={0}>
                <Basics details={reviewInfo} isMarketplace />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Content details={reviewInfo} isMarketplace />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Questions
                  questions={reviewInfo.questions.questions}
                  minScore={reviewInfo.passScore}
                />
              </TabPanel>
            </TabWrapper>
          </FlexDiv>
        </>
      )}
    </ModalWrapper>
  );
};

export default ReviewModal;

ReviewModal.propTypes = {
  reviewModalInfo: PropTypes.object,
  setReviewModalInfo: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  isSuggested: PropTypes.bool,
  isViewClosed: PropTypes.bool,
};

ReviewModal.defaultProps = {
  reviewModalInfo: null,
  isSuggested: null,
  isViewClosed: null,
};
