import { AppBar, CircularProgress, IconButton, Tabs } from "@material-ui/core";
import styled, { keyframes } from "styled-components";

export const ReviewAppBar = styled(AppBar)`
  min-width: 300px;
  width: 100%;
  background-color: white;
  color: black;
  min-height: 26px;
  padding: 0px;
  margin: 0px;
  box-shadow: none;
`;

export const LaunchTabs = styled(Tabs)`
  .MuiTab-root {
    border-bottom: 2px solid #eee;
    min-height: 26px;
    font-size: 14px;
    margin-right: 8px;
    min-width: 80px;

    @media (max-width: 1100px) {
      min-width: 100px;
    }

    @media (max-width: 550px) {
      min-width: 80px;
    }
  }

  .MuiTab-root.Mui-selected {
    background-color: white;
    border-bottom: 2px solid rgb(51, 122, 183);
    color: black;
  }

  .MuiTab-wrapper {
    font-size: 15px;
    color: black;
    font-weight: 500;

    @media (max-width: 550px) {
      font-size: 12px;
    }
  }
`;
export const FlexDiv = styled.div`
  height: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
`;

export const TabWrapper = styled.div`
  margin-bottom: 16px;
`;

export const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  padding: 10px 0px;
`;

export const ObserverDiv = styled.div`
  height: 10px;
  width: 10px;
  pointer-events: none;
  position: absolute;
  bottom: 800px;
  left: 50%;
  right: 50px;
`;

export const MarketplaceWrapper = styled.div`
  min-height: 10px;
  width: 100%;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, 290px);
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const LoadMoreWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingMore = styled(CircularProgress)`
  height: 100px;
  width: 100px;
  color: ${(props) => props.theme.palette.info.light};
  position: relative;
`;

// Quiz Card

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const AddedAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(6px);
  }
  10%{
    opacity: 1;
    transform: translateY(-0px);
  }
  80%{
    opacity: 1;
    transform: translateY(-0px);
  }
  100%{
    opacity: 0;
  }
`;

export const AddButton = styled(IconButton)`
  margin-right: 4px;
  border-radius: 6px;

  background-color: ${(props) => props.theme.palette.grey[100]};

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[300]};
  }

  svg {
    height: 18px;
    width: 18px;
    color: #337ab7;
  }

  &:disabled {
    svg {
      color: ${(props) => props.theme.palette.grey[400]};
    }
  }
`;

export const ReviewButton = styled(IconButton)`
  font-size: 12px;
  border-radius: 6px;
  color: black;
  background-color: ${(props) => props.theme.palette.grey[100]};

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[300]};
  }

  svg {
    height: 18px;
    width: 18px;
    color: black;
  }
`;
