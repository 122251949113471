import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Paper, MenuItem } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Chart } from "chart.js";
import { Typography, TextField } from "../../styles";
import { PieChart } from "react-feather";
import SubmissionTypeChip from "../../home/retail/SubmissionTypeChip";

const BREAKDOWN_OPTIONS = {
  AVERAGE_SCORE: "averageScore",
  SUBMISSIONS: "submissions",
  COMPLETIONS: "passed",
  FAILED: "failed",
  PASS_RATE: "passRate",
};

Chart.register(annotationPlugin);

const Wrapper = styled(Paper)`
  padding: 20px;
  min-height: 100px;
  border-radius: 6px;
`;

const ChartWrapper = styled.div`
  width: 100%;
`;

const CustomTextField = styled(TextField)`
  width: 160px;
  margin-right: 18px;
  .MuiSelect-root {
    padding: 8px 32px 8px 8px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

const getCleanName = (name) => {
  if (name === BREAKDOWN_OPTIONS.AVERAGE_SCORE) {
    return "Average Score";
  }
  if (name === BREAKDOWN_OPTIONS.SUBMISSIONS) {
    return " Submissions";
  }
  if (name === BREAKDOWN_OPTIONS.PASS_RATE) {
    return "Pass Rate";
  }
  if (name === BREAKDOWN_OPTIONS.COMPLETIONS) {
    return "Completions";
  }
  if (name === BREAKDOWN_OPTIONS.FAILED) {
    return "Failed Submissions";
  }
  return "None";
};

const getIsPercentage = (breakdown) => {
  if (
    [BREAKDOWN_OPTIONS.AVERAGE_SCORE, BREAKDOWN_OPTIONS.PASS_RATE].includes(
      breakdown,
    )
  ) {
    return true;
  }
  return false;
};

const getChartHeight = (num) => {
  if (num < 10) return 320;
  if (num > 40) return 500;
  return 420;
};

const HorizontalBar = ({ employeeArr, engagementType }) => {
  const numEmployees = employeeArr.length;
  const [breakdown, setBreakdown] = useState(BREAKDOWN_OPTIONS.COMPLETIONS);

  const a = breakdown;
  const newBreakdownData = employeeArr.map((employee) => ({
    name: employee.name,
    metric: employee[a],
  }));

  const newAverageMean = Math.floor(
    newBreakdownData.reduce((tot, next) => {
      return (tot += next.metric);
    }, 0) / newBreakdownData.length,
  );

  const versusMean = newBreakdownData
    .map((data) => ({
      ...data,
      vsMean: Math.floor(Number(data.metric - newAverageMean)),
    }))
    .sort((first, second) => second.metric - first.metric);

  if (numEmployees.length === 0) return null;

  const chartHeight = getChartHeight(numEmployees);

  const cleanName = getCleanName(breakdown);

  const isPercentage = getIsPercentage(breakdown);

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 2,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: () => "",
          title: (context) => `${context[0].label}`,
          beforeBody: (context) =>
            `${cleanName}: ${context[0].raw}${isPercentage ? "%" : ""}`,
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            xMin: newAverageMean,
            xMax: newAverageMean,
            yMin: -100,
            yMax: 180,
            borderColor: "slategrey",
            borderWidth: 2,
            label: {
              enabled: true,
              content: `<- Mean ${cleanName}: (${newAverageMean}${
                isPercentage ? "%" : ""
              })`,
              xAdjust: 100,
              yAdjust: 100,
              backgroundColor: "rgba(158,158,158,0.46)",
              color: "black",
            },
          },
        },
      },
    },
    scales: {
      x: {
        suggestedMax: 100,
        title: {
          display: true,
          text: () => {
            if (isPercentage) {
              return `${cleanName} %`;
            }
            return cleanName;
          },
        },
        ticks: {
          callback: function (value) {
            if (isPercentage) {
              return `${value}%`;
            }
            return value;
          },
        },
      },
    },
  };

  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 800, 0);
    gradient.addColorStop(0, "rgba(158,158,158,0.06)");
    gradient.addColorStop(0.6, "rgb(23,62,88)");
    // gradient.addColorStop(0, "#6E85B7");
    // gradient.addColorStop(0.6, "#337ab7");

    return {
      labels: versusMean.map((val) => val.name),
      datasets: [
        {
          data: versusMean.map((val) => val.metric),
          borderColor: "darkgrey",
          borderRadius: 4,
          borderWidth: 1,

          barPercentage: 0.9,
          backgroundColor: gradient,
          // backgroundColor: "black",
        },
      ],
    };
  };

  return (
    <Wrapper>
      <FlexDiv>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <PieChart color="rgb(51, 122, 183)" height={11} width={11} />
          <Typography style={{ fontSize: 16, fontWeight: "600" }}>
            {cleanName} Breakdown
          </Typography>
          <SubmissionTypeChip small submissionType={engagementType} />
        </div>
        <CustomTextField
          select
          size="small"
          variant="outlined"
          value={breakdown}
          onChange={(e) => setBreakdown(e.target.value)}
        >
          <MenuItem value={BREAKDOWN_OPTIONS.COMPLETIONS}>Completions</MenuItem>
          <MenuItem value={BREAKDOWN_OPTIONS.SUBMISSIONS}>Submissions</MenuItem>
          <MenuItem value={BREAKDOWN_OPTIONS.PASS_RATE}>Pass Rate</MenuItem>
        </CustomTextField>
      </FlexDiv>
      <ChartWrapper>
        <Bar data={chartData} options={options} height={chartHeight} />
      </ChartWrapper>
    </Wrapper>
  );
};

export default HorizontalBar;

HorizontalBar.propTypes = {
  employeeArr: PropTypes.array.isRequired,
};
