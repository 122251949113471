/* eslint-disable */
// dr
import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import API from "../../../axios/instances/API";
import { FEEDBACK_MESSAGES } from "../../../storage/constants";
import { Alert, DeleteButton, DeleteWrapper, Typography } from "../styles";

const DeleteLocationModal = ({
  deleteInfo,
  setDeleteInfo,
  setSnackbarText,
  mutate,
}) => {
  const [errorAlert, setErrorAlert] = useState(false);
  const [isdeleting, setIsDeleting] = useState(false);
  const [confirmText, setConfirmText] = useState(null);

  const isConfirmed =
    confirmText?.toLowerCase()?.trim() ===
    deleteInfo?.name?.toLowerCase()?.trim();

  const handleDelete = async () => {
    setIsDeleting(true);
    setErrorAlert(false);
    try {
      await API.delete("/dashboard/locations/details/", {
        data: { id: deleteInfo.id },
      });
      setIsDeleting(false);
      setSnackbarText(`Deleted location ${deleteInfo?.name} warning`);
      mutate();
      setDeleteInfo(null);
    } catch (error) {
      setErrorAlert(true);
      setIsDeleting(false);
    }
  };

  return (
    <>
      <DeleteWrapper style={{ border: "2px solid tomato", width: 460 }}>
        <Typography variant="h5" component="h5">
          Delete this location?
        </Typography>
        <Typography mb={4} mt={1} style={{ fontWeight: "400", fontSize: 14 }}>
          {deleteInfo.name}
        </Typography>
        <Alert severity="error" style={{ textAlign: "left" }}>
          <p style={{ fontWeight: "600" }}>This action cannot be reversed.</p>
          {/* <p style={{ fontWeight: "600" }}>Confirming will:</p> */}
          <ul style={{ padding: 4 }}>
            <li>
              <span>The location will be deleted</span>
            </li>
            <li>
              <span>All team members at this location will be deleted</span>
            </li>
            <li>
              <span>
                All pending team members invites at this location will be
                deleted
              </span>
            </li>
            <li>
              <span>
                All supervisors with dashboard access to this location will be
                deleted. If they have access to another location, they will
                still have access to that location.
              </span>
            </li>
          </ul>
        </Alert>
        <div style={{ height: 18 }} />

        <TextField
          size="small"
          variant="outlined"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
          fullWidth
          autoFocus
          error={isConfirmed}
          label="Type location name to confirm"
        />
        <div style={{ height: 12 }} />
        <DeleteButton
          style={{ borderRadius: 4 }}
          fullWidth
          // p={2}
          disabled={isdeleting || !isConfirmed}
          onClick={handleDelete}
        >
          {isdeleting ? "..." : "Confirm"}
        </DeleteButton>
        <div style={{ height: 8 }} />
        {/* <Button fullWidth variant="text">
          Cancel
        </Button> */}
        {errorAlert && (
          <Alert mt={4} severity="error" onClose={() => setErrorAlert(false)}>
            {FEEDBACK_MESSAGES.GENERIC_ERROR}
          </Alert>
        )}
      </DeleteWrapper>
    </>
  );
};

export default DeleteLocationModal;

DeleteLocationModal.propTypes = {
  deleteInfo: PropTypes.object,
  setSnackbarText: PropTypes.func.isRequired,
  setDeleteInfo: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  endpoint: PropTypes.string,
  args: PropTypes.object,
};

DeleteLocationModal.defaultProps = {
  args: null,
  deleteInfo: null,
  endpoint: null,
};
