import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, useMediaQuery } from "@material-ui/core";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import useManagers from "../../../swr/hooks/Managers/useManagers";
import { makeDefaultTableOptions } from "../../../storage/helpers";
import ConfirmDeleteManager from "./detail/ConfirmDeleteManager";
import { StyledLink, CustomDatatableV2 } from "../styles";
import { Monitor, List } from "react-feather";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import LocationsTooltip from "./LocationsTooltip";
import TableTitle from "../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";

const SupervisorsDatatable = ({ readOnly, isAdmin }) => {
  const { managers, isLoading, mutate } = useManagers();

  const [deleteInfo, setDeleteInfo] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  if (isLoading) return <LoaderWrapper text="Managers" />;

  const options = makeDefaultTableOptions("supervisors");
  options.textLabels = {
    body: {
      noMatch:
        "No users have supervisor access. Promote an existing user from the Team Members page or invite a new supervisor from the button above.",
    },
  };
  options.rowsPerPage = 50;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = managers.map((manager) => ({
    id: manager.id,
    name: `${manager.firstName} ${manager.lastName}`,
    locations: manager?.managed_locations?.length,
    email: manager.email,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            to={`/supervisors/detail/${tableMeta.rowData[0]}`}
            value={value}
            CustomIcon={Monitor}
          />
        ),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "locations",
      label: "Locations",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <LocationsTooltip value={value} id={tableMeta?.rowData[0]} />;
        },
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        sort: false,
        filter: false,
        empty: true,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly || !isAdmin}
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setDeleteInfo({
                id: rowData[0],
                name: rowData[1],
                description: "Are you sure you want to delete Supervisor",
                header: "Delete Supervisor",
                snack: `Deleted Supervisor ${rowData[1]} warning`,
              });
            }}
          />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        // title="Supervisors"
        title={<TableTitle title="Supervisor List" Icon={List} />}
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete a Supervisor Invitation"
        aria-describedby="Delete a Supervisor Invitation"
      >
        <div>
          <ConfirmDeleteManager
            deleteInfo={deleteInfo}
            setConfirmModalOpen={() => setDeleteInfo(null)}
            setSnackbarText={setSnackbarText}
            setDeleteInfo={setDeleteInfo}
            mutate={mutate}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default SupervisorsDatatable;

SupervisorsDatatable.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
