import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Alert } from "@material-ui/lab";
import { Formik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import API from "../../axios/instances/API";
import { TextField, Typography } from "../../pages/private/styles";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
`;

const Visibility = styled(VisibilityIcon)`
  cursor: pointer;
`;

const NoVisibility = styled(VisibilityOff)`
  cursor: pointer;
`;

const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .max(255, "Password maximum 255 characters")
    .min(8, "Password should be min 8 characters")
    .notOneOf(
      [Yup.ref("currentPassword"), null],
      "New Password should not be the same as old password",
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords are not the same")
    .required("Password confirm is required"),
});

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
    setShowError(false);
    setShowSuccess(false);
    try {
      await API.put("dashboard/account/password/", {
        password: values.currentPassword,
        password1: values.newPassword,
        password2: values.confirmPassword,
      });
      setShowSuccess("Password Changed");
      setSubmitting(false);
      resetForm();
    } catch (error) {
      setShowError("Error changing your password, please try again");
      resetForm();
      setSubmitting(false);
    }
  };

  return (
    <ModalWrapper>
      <Typography style={{ fontSize: 16, fontWeight: 600 }} mb={4}>
        Change Your Password
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          isValid,
          dirty,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              type="password"
              name="currentPassword"
              label="Current Password"
              value={values.currentPassword}
              error={Boolean(touched.currentPassword && errors.currentPassword)}
              fullWidth
              helperText={touched.currentPassword && errors.currentPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              size="small"
              autoComplete="off"
            />
            <TextField
              type={showPassword ? "text" : "password"}
              name="newPassword"
              label="New Password"
              value={values.newPassword}
              error={Boolean(touched.newPassword && errors.newPassword)}
              fullWidth
              helperText={touched.newPassword && errors.newPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              mt={4}
              variant="outlined"
              size="small"
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setShowPassword((p) => !p)}
                  >
                    {showPassword ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <NoVisibility fontSize="small" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm New Password"
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              mt={4}
              variant="outlined"
              size="small"
              autoComplete="off"
            />
            <Box mt={4} mb={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting || !(isValid && dirty)}
                startIcon={isSubmitting && <CircularProgress size={20} />}
              >
                {isSubmitting ? "Saving" : "Save New Password"}
              </Button>
            </Box>

            {showSuccess && (
              <Box mt={2}>
                <Alert severity="success" onClose={() => setShowSuccess(false)}>
                  New Password Saved!
                </Alert>
              </Box>
            )}
            {showError && (
              <Box mt={2}>
                <Alert severity="error" onClose={() => setShowError(false)}>
                  Ooops.. we have an error. Please try again.
                </Alert>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default ChangePassword;
