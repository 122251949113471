import { Avatar, Chip, Paper, Tooltip } from "@material-ui/core";
import React from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import SubmissionTypeChip from "../../home/retail/SubmissionTypeChip";
import { Typography } from "../../styles";
import AverageScoreBar from "../reuseableComponents/AverageScoreBar";

const SmallAvatar = styled(Avatar)`
  height: 18px;
  width: 18px;
  background-color: ${(props) =>
    props.iscorrect === "Yes"
      ? props.theme.palette.primary.main
      : props.theme.palette.grey[400]};
  font-size: 12px;
`;

const Wrapper = styled(Paper)`
  padding: 20px 20px 40px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 420px;
  height: 644px;
  position: relative;
  margin-right: 16px;
  margin-bottom: 16px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ChartWrapper = styled.div`
  min-height: 240px;
  max-height: 260px;
  width: 100%;
  margin-right: 20px;
`;

const BreakdownWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  padding-top: 16px;
  width: 100%;
`;

const DarkNumber = styled.span`
  font-size: 22px;
  font-weight: 800;
  color: ${(props) => props.theme.palette.primary.dark};
  } 
`;

const BreakdownItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SmallText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.primary.dark};
`;

const HighlightQ = styled.span`
  margin-right: 4px;
  font-weight: 700;
  font-style: italic;
`;

const SmallTextGrey = styled.span`
  font-size: 11px;
`;

const OptionsWrapper = styled.div`
  min-height: 100px;
  width: 100%;
  padding: 0px 20px;
`;

const TitleText = styled(Typography)`
  font-weight: 800;
  font-size: 13px;
  font-style: italic;
`;

const OptionDisplay = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]};
  margin: 2px 0px;
  text-overflow: ellipsis;
`;

const AnswerSmall = styled(Typography)`
  font-size: 10px;
  font-style: italic;
  margin-left: 8px;
  text-overflow: ellipsis;
`;

const FloatChip = styled(Chip)`
  border: 1px solid black;
  font-size: 10px;
  background-color: white;
  position: absolute;
  left: 5px;
  top: 5px;
`;

const getLabel = (question) => {
  switch (question.type) {
    case "mc":
      return "M/C";
    case "tf":
      return "T/F";
    case "sa":
      return "S/A";
    default:
      return "";
  }
};

const getAnswer = (q) => {
  if (q.type === "tf") {
    return q.correctAnswer.toUpperCase();
  }

  if (q.type !== "sa") {
    return q.correctAnswer.toUpperCase();
  }

  if (Array.isArray(q.correctAnswer)) {
    return q.correctAnswer.map((letter) => letter.toUpperCase()).join(",");
  }
  return q.correctAnswer;
};

const formatAnswer = (answer, keepLow = false) => {
  if (Array.isArray(answer)) {
    const retArr = answer.map((letter) =>
      keepLow ? letter.toLowerCase() : letter.toUpperCase(),
    );
    return retArr.join(",");
  }
  return keepLow ? answer.toLowerCase() : answer.toUpperCase();
};

const options = {
  maintainAspectRatio: false,
  responsive: true,
  cornerRadius: 2,
  indexAxis: "x",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: () => "",
        beforeBody: (c) => `# Submissions : ${c[0].raw}`,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value) => {
          if (Number.isInteger(value)) return value;
        },
        color: "black",
      },
      title: {
        display: true,
        text: "Count",
      },
    },

    x: {
      title: {
        display: true,
        text: "Submitted Answers",
      },
      ticks: {
        color: "black",
      },
    },
  },
};

const injectMissingKeysToTFIfZero = (answerDistribution) => {
  try {
    const hasTrueAnswer = Boolean(answerDistribution.true);
    const hasFalseAnswer = Boolean(answerDistribution.false);

    if (hasTrueAnswer && hasFalseAnswer) {
      return answerDistribution;
    }
    if (!hasTrueAnswer) {
      return {
        ...answerDistribution,
        true: {
          answers_of_assigned_submissions: 0,
          answers_of_follower_submissions: 0,
        },
      };
    }

    if (!hasFalseAnswer) {
      return {
        ...answerDistribution,
        false: {
          answers_of_assigned_submissions: 0,
          answers_of_follower_submissions: 0,
        },
      };
    }
  } catch (error) {
    return {};
  }
};

const getAnswerResults = (question, engagementType) => {
  let totalCorrect = 0;
  let totalIncorrect = 0;
  let totalSubmitted = 0;
  let percentageCorrect = 0;

  try {
    const { answer_distribution: answerDistribution } = question;

    if (question.type === "tf") {
      const answerDistCleaned = injectMissingKeysToTFIfZero(answerDistribution);

      if (engagementType === ENGAGEMENT_TYPES.ALL) {
        totalSubmitted = [
          ...Object.values(answerDistCleaned.false),
          ...Object.values(answerDistCleaned.true),
        ].reduce((a, b) => a + b, 0);
        totalCorrect = [
          ...Object.values(answerDistCleaned[question.correct_answer]),
        ].reduce((a, b) => a + b, 0);
      }

      if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
        totalSubmitted =
          answerDistCleaned.false.answers_of_assigned_submissions +
          answerDistCleaned.true.answers_of_assigned_submissions;
        totalCorrect =
          answerDistCleaned[question.correct_answer]
            .answers_of_assigned_submissions;
      }
      if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
        totalSubmitted =
          answerDistCleaned.false.answers_of_follower_submissions +
          answerDistCleaned.true.answers_of_follower_submissions;
        totalCorrect =
          answerDistCleaned[question.correct_answer]
            .answers_of_follower_submissions;
      }
    }

    if (question.type === "mc") {
      if (engagementType === ENGAGEMENT_TYPES.ALL) {
        const vals = Object.values(answerDistribution);
        totalSubmitted = vals
          .flatMap((v) => Object.values(v))
          .reduce((a, b) => a + b, 0);
        totalCorrect =
          answerDistribution[question.correct_answer]
            .answers_of_assigned_submissions +
          answerDistribution[question.correct_answer]
            .answers_of_follower_submissions;
      }
      if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
        totalSubmitted = Object.values(answerDistribution)
          .map((obj) => obj.answers_of_assigned_submissions)
          .reduce((a, b) => a + b, 0);
        totalCorrect =
          answerDistribution[question.correct_answer]
            .answers_of_assigned_submissions;
      }
      if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
        totalSubmitted = Object.values(answerDistribution)
          .map((obj) => obj.answers_of_follower_submissions)
          .reduce((a, b) => a + b, 0);
        totalCorrect =
          answerDistribution[question.correct_answer]
            .answers_of_follower_submissions;
      }
    }

    if (question.type === "sa") {
      const formattedAnswer = formatAnswer(question.correct_answer, true);
      if (engagementType === ENGAGEMENT_TYPES.ALL) {
        const vals = Object.values(answerDistribution);
        totalSubmitted = vals
          .flatMap((v) => Object.values(v))
          .reduce((a, b) => a + b, 0);
        totalCorrect = Object.values(
          answerDistribution[formattedAnswer],
        ).reduce((a, b) => a + b, 0);
      }
      if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
        totalSubmitted = Object.values(answerDistribution)
          .map((obj) => obj.answers_of_assigned_submissions)
          .reduce((a, b) => a + b, 0);
        totalCorrect =
          answerDistribution[formattedAnswer].answers_of_assigned_submissions;
      }
      if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
        totalSubmitted = Object.values(answerDistribution)
          .map((obj) => obj.answers_of_follower_submissions)
          .reduce((a, b) => a + b, 0);
        totalCorrect =
          answerDistribution[formattedAnswer].answers_of_follower_submissions;
      }
    }

    totalIncorrect = totalSubmitted - totalCorrect;
    percentageCorrect = (totalCorrect / totalSubmitted).toFixed(2);

    return {
      totalCorrect,
      totalSubmitted,
      percentageCorrect: isNaN(percentageCorrect) ? 0 : percentageCorrect,
      totalIncorrect,
    };
  } catch (error) {
    return {
      totalCorrect,
      totalIncorrect,
      totalSubmitted,
      percentageCorrect,
    };
  }
};

const getDataByEngagementType = (answerDistribution, type, engagementType) => {
  try {
    if (type === "tf") {
      const answerDistCleaned = injectMissingKeysToTFIfZero(answerDistribution);

      if (engagementType === ENGAGEMENT_TYPES.ALL) {
        return [
          answerDistCleaned.true.answers_of_assigned_submissions +
            answerDistCleaned.true.answers_of_follower_submissions,
          answerDistCleaned.false.answers_of_assigned_submissions +
            answerDistCleaned.false.answers_of_follower_submissions,
        ];
      }
      if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
        return [
          answerDistCleaned.true.answers_of_assigned_submissions,
          answerDistCleaned.false.answers_of_assigned_submissions,
        ];
      }
      if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
        return [
          answerDistCleaned.true.answers_of_follower_submissions,
          answerDistCleaned.false.answers_of_follower_submissions,
        ];
      }
    }
    if (type === "mc" || type === "sa") {
      if (engagementType === ENGAGEMENT_TYPES.ALL) {
        return Object.values(answerDistribution).map(
          (v) =>
            v.answers_of_assigned_submissions +
            v.answers_of_follower_submissions,
        );
      }
      if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
        return Object.values(answerDistribution).map(
          (v) => v.answers_of_assigned_submissions,
        );
      }
      if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
        return Object.values(answerDistribution).map(
          (v) => v.answers_of_follower_submissions,
        );
      }
    }
  } catch (error) {
    return [];
  }
  return [];
};

const AnswerDistributionByEngagementType = ({ question, engagementType }) => {
  const { percentageCorrect, totalCorrect, totalSubmitted, totalIncorrect } =
    getAnswerResults(question, engagementType);

  const dataByEngagementType = getDataByEngagementType(
    question.answer_distribution,
    question.type,
    engagementType,
  );

  const data = {
    labels:
      question.type === "tf"
        ? ["TRUE", "FALSE"]
        : Object.keys(question.answer_distribution).map((val) =>
            val.toUpperCase(),
          ),
    datasets: [
      {
        label: "Submitted Options",
        data: dataByEngagementType,

        barPercentage: 0.5,
        borderRadius: 2,
        minBarLength: 0,
        backgroundColor:
          question.type === "tf"
            ? ["TRUE", "FALSE"].map((word) => {
                if (getAnswer(question) === word) {
                  return "rgb(23,62,88)";
                }
                return "rgba(0, 0, 0, .2)";
              })
            : Object.keys(question.answer_distribution).map((key) => {
                if (key.toUpperCase() === getAnswer(question)) {
                  return "rgb(23,62,88)";
                }
                return "rgba(0, 0, 0, .2)";
              }),
        borderColor: "black",
        categoryPercentage: 0.9,
      },
    ],
  };

  return (
    <Wrapper>
      <FloatChip size="small" label={getLabel(question)} />
      <div style={{ position: "absolute", right: 5, top: 5 }}>
        <SubmissionTypeChip submissionType={engagementType} small isVendor />
      </div>
      <HeaderWrapper>
        <TitleText variant="subtitle2" align="center" mt={2} mb={-1}>
          <HighlightQ>{`Q${question.order + 1})`}</HighlightQ>
          {question.question}
        </TitleText>
        <SmallText variant="body2" align="center">
          {question.type !== "sa"
            ? `Correct Answer (${question.correctAnswer.toUpperCase()})`
            : `Correct Answer (${formatAnswer(question.correctAnswer)})`}
        </SmallText>
      </HeaderWrapper>
      {question.type !== "tf" && (
        <>
          <OptionsWrapper>
            {Object.entries(question.options).map((option) => (
              <OptionDisplay key={option[0]}>
                {question.type === "sa" ? (
                  <SmallAvatar
                    iscorrect={
                      formatAnswer(question.correctAnswer).includes(
                        option[0].toUpperCase(),
                      )
                        ? "Yes"
                        : "No"
                    }
                  >
                    {option[0].toUpperCase()}
                  </SmallAvatar>
                ) : (
                  <SmallAvatar
                    iscorrect={
                      question.correctAnswer.toUpperCase() ===
                      option[0].toUpperCase()
                        ? "Yes"
                        : "No"
                    }
                  >
                    {option[0].toUpperCase()}
                  </SmallAvatar>
                )}
                <Tooltip title={option[1]}>
                  <AnswerSmall>{option[1]}</AnswerSmall>
                </Tooltip>
              </OptionDisplay>
            ))}
          </OptionsWrapper>
        </>
      )}
      <ChartWrapper>
        <Bar data={data} options={options} />
      </ChartWrapper>
      <BreakdownWrapper>
        <BreakdownItem>
          <SmallTextGrey>Total Answers</SmallTextGrey>
          <DarkNumber engagementType={engagementType}>
            {totalSubmitted}
          </DarkNumber>
        </BreakdownItem>
        <BreakdownItem>
          <SmallTextGrey>Correct Answers</SmallTextGrey>
          <DarkNumber engagementType={engagementType}>
            {totalCorrect}
          </DarkNumber>
        </BreakdownItem>
        <BreakdownItem>
          <SmallTextGrey>Incorrect Answers</SmallTextGrey>
          <DarkNumber engagementType={engagementType}>
            {totalIncorrect}
          </DarkNumber>
        </BreakdownItem>
      </BreakdownWrapper>
      <AverageScoreBar
        title="Percentage Correct"
        value={percentageCorrect}
        engagementType={engagementType}
      />
    </Wrapper>
  );
};

export default AnswerDistributionByEngagementType;
