import React from "react";
import { XCircle } from "react-feather";
import { StyledItem } from "../styles";
import {
  QUIZSTATES,
  USER_TYPES,
  LIBRARY_MODAL_KEYS,
} from "../../../../../storage/constants";

const CloseModule = ({
  id,
  handleClose,
  userType,
  moduleState,
  setModalState,
  moduleName,
}) => {
  if (
    ![
      QUIZSTATES.LIVE,
      QUIZSTATES.IN_DEVELOPMENT,
      QUIZSTATES.PENDING,
      QUIZSTATES.IN_REVIEW,
    ].includes(moduleState)
  ) {
    return null;
  }

  if (![USER_TYPES.RETAIL_ADMIN, USER_TYPES.VENDOR].includes(userType)) {
    return null;
  }

  return (
    <StyledItem
      onClick={() => {
        setModalState({ key: LIBRARY_MODAL_KEYS.CLOSE, id, name: moduleName });
        handleClose();
      }}
    >
      <XCircle style={{ backgroundColor: "firebrick", color: "#eeeeee" }} />
      <span>Close Module</span>
    </StyledItem>
  );
};

export default CloseModule;
