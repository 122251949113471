/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "../../../pages/private/styles";
import { IntroText } from "../styles";

const Wrapper = styled.div`
  margin-left: 12px;
`;

const Audience = ({ isRetail }) => (
  <Wrapper>
    <Typography variant="subtitle2">What is an Audience?</Typography>
    {isRetail ? (
      <IntroText mt={1} mb={6}>
        Your audience is the group of team members who will receive the module
        in the app. You must assign an audience to each module.
      </IntroText>
    ) : (
      <IntroText mt={1} mb={6}>
        Your audience is the collection of compaines you can recommend to
        consume your content. You must assign an audience if you plan on
        suggesting content.
      </IntroText>
    )}
  </Wrapper>
);

export default Audience;

Audience.propTypes = {
  isRetail: PropTypes.bool.isRequired,
};
