import React, { useState } from "react";
import styled from "styled-components";
import useRoles from "../../../../swr/hooks/Roles/useRoles";
import { Paper, Button, Checkbox } from "@material-ui/core";
import { Tag, Check } from "react-feather";
import { mutate as swrMutate } from "swr";
import API from "../../../../axios/instances/API";
import FunLoader from "../../../../components/Loaders/FunLoader";

const RoleItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 2px;

  border-radius: 4px;
  margin-bottom: 2px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee44;
  }
`;

const UncheckedIcon = () => (
  <div
    style={{
      height: 14,
      width: 14,
      minWidth: 14,
      minHeight: 14,
      borderRadius: 4,
      background: "lightgray",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  />
);

const CheckedIcon = () => (
  <div
    style={{
      height: 14,
      width: 14,
      minHeight: 14,
      minWidth: 14,
      borderRadius: 4,
      background: "#337ab7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Check height={10} width={10} style={{ color: "white" }} />
  </div>
);

const StyledCheckbox = styled(Checkbox)`
  .MuiCheckbox-root {
    height: 18px;
    width: 18px;
    border-radius: 6px;
  }

  .MuiCheckbox-colorPrimary {
    background-color: #337ab7;
  }
`;

const ModalWrapper = styled(Paper)`
  padding: 16px 16px 10px 16px;
  width: 330px;
  max-height: 90vh;
  min-height: 500px;
  position: absolute;
  left: 50%;
  display: flex;
  flex-direction: column;

  border: 2px solid black;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const EditRolesModal = ({
  usersCurrentRoles,
  mutate,
  setSnackbarText,
  userId,
  handleClose,
}) => {
  const [selected, setSelected] = useState(usersCurrentRoles);
  const [saving, setSaving] = useState(false);

  const { rolesLoading, roles } = useRoles();

  if (rolesLoading) {
    return (
      <ModalWrapper>
        <p style={{ fontSize: 15, marginBottom: 6, fontWeight: "600" }}>
          Edit Roles
        </p>
        <div
          style={{
            width: "100%",
            minHeight: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FunLoader />
        </div>
      </ModalWrapper>
    );
  }

  const handleCheck = (id) => {
    setSelected((p) => {
      if (p.includes(id)) {
        return p.filter((i) => i !== id);
      }
      return [...p, id];
    });
  };

  const handleSaveRoles = async () => {
    if (selected.length === 0) {
      return setSnackbarText("Mimimum of 1 role required warning");
    }
    setSaving(true);
    try {
      const body = {
        id: userId,
        roles: selected,
      };

      await API.put("dashboard/teams/roles/", body);
      await mutate();
      await swrMutate(
        `/dashboard/analytics/retail-reports/users-activity-breakdown/?team_member_id=${userId}`,
      );
      setSaving(false);
      setSnackbarText("Roles updated success");
      handleClose();
    } catch (error) {
      setSaving(false);
      setSnackbarText("Failed to update roles error");
    }
  };

  return (
    <ModalWrapper>
      <p style={{ fontSize: 15, marginBottom: 6, fontWeight: "600" }}>
        Edit Roles
      </p>
      <div
        style={{
          maxHeight: 440,
          overflowY: "scroll",
          flex: 1,
        }}
      >
        {roles?.map((role) => (
          <RoleItem key={role.id} onClick={() => handleCheck(role.id)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                justifyContent: "flex-start",
              }}
            >
              <Tag
                height={14}
                width={14}
                color="black"
                style={{
                  backgroundColor: "#eeeeee",
                  padding: 2,
                  borderRadius: 3,
                }}
              />
              <span>{role.name}</span>
            </div>
            <StyledCheckbox
              color="primary"
              size="small"
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              checked={selected?.includes(role.id)}
              onChange={() => {}}
            />
          </RoleItem>
        ))}
      </div>
      <div
        style={{
          marginTop: 10,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            backgroundColor: saving ? "lightgray" : "#337ab7",
            color: "white",
            marginLeft: "auto",
          }}
          variant="contained"
          onClick={handleSaveRoles}
        >
          {saving ? "..." : "Save"}
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default EditRolesModal;
