import React from "react";
import { Typography } from "../../../pages/private/styles";
import ShowLocationChip from "../../ShowLocationChip";
import { AUDIENCETYPES } from "../../../storage/constants";
import ShowRoleChip from "../../ShowRoleChip";
import { Tag, MapPin, Cast, Users, X, AlertTriangle } from "react-feather";
import { ScrollWrapper } from "./styles";

const checkIfSameNumberOfRoles = (audience) => {
  const testArr = [];

  Object.values(audience).forEach((value, i) => {
    const sortedAndStringified = value
      .map((v) => v.id)
      .sort((a, b) => a - b)
      .join("+");

    testArr.push(sortedAndStringified);
  });

  let strToCheck = testArr[0];

  const myTest = testArr.every((str) => str === strToCheck);

  return myTest;
};

const getSupervisorAudience = (options, audience) => {
  try {
    /// --- Check that there is actually an audience --- //

    const hasAudience = Object.keys(audience).length > 0;

    if (!hasAudience) {
      return {
        title: "No Team Members (Supervisor)",
        description:
          "None of the team members under your supervision are in this audience.",
        ids: [],
        type: AUDIENCETYPES.NONE,
        Icon: AlertTriangle,
      };
    }

    const { locations, roles } = options;

    const audienceLocIds = Object.keys(audience);
    const locationIds = locations.map((l) => l.id);

    const allLocationsAreInCurrentAudience = locations.every((loc) =>
      audienceLocIds.includes(String(loc.id)),
    );

    /// ----- ALL Team Members -------- ///
    if (allLocationsAreInCurrentAudience) {
      const allLocationsHaveEveryRole = locationIds.every(
        (id) => audience[id].length === roles.length,
      );

      if (allLocationsHaveEveryRole) {
        return {
          title: "All Team Members (Supervisor)",
          description: "This module will be deployed to all team members.",
          ids: [],
          type: AUDIENCETYPES.SELECT_ALL,
          Icon: Users,
        };
      }
    }

    /// ----------- Locations & Custom ---------- ///
    if (locations.length !== Object.keys(audience)) {
      // Check if any locations are fully filled with roles

      const locationsWithAllRoles = locationIds.filter(
        (id) => audience[id]?.length === roles.length,
      );

      const locationsWithAtLeast1ButNotAllRoles = locationIds.filter(
        (id) => !locationsWithAllRoles.includes(id) && audience[id]?.length > 0,
      );

      /// ----> Custom <------ ///
      if (
        locationsWithAtLeast1ButNotAllRoles.length > 0 &&
        locationsWithAllRoles.length > 0
      ) {
        // If there is at least 1 location with every role, and at least 1 without every role.. its a custom audience for the manager
        return {
          title: "Custom Audience (Supervisor)",
          description:
            "This module will be deployed to team members based on the role(s) and location(s) combination chosen in the table.",
          ids: [],
          type: AUDIENCETYPES.OTHER,
          Icon: Cast,
        };
      }

      if (locationsWithAllRoles.length > 0) {
        return {
          title: "By Locations (Supervisor)",
          description:
            "This module will be deployed to all team members in the following location(s).",
          ids: locationsWithAllRoles,
          type: AUDIENCETYPES.LOCATIONS,
          Icon: MapPin,
        };
      }
    }

    //// Roles need to check if all locations have the same roles///
    if (Object.keys(audience).length === locations.length) {
      // check that the number of locations in audience is same as available
      /// get all the roles in audience... loop over and count -> make sure counts are the same
      const areSameRolesInAllLocations = checkIfSameNumberOfRoles(audience);

      if (areSameRolesInAllLocations) {
        return {
          title: "By Roles (Supervisor)",
          description:
            "This module will be deployed to all team members at the location(s) you supervisor with the following role tag(s).",
          ids: Object.values(audience)[0].map((r) => r.id),
          type: AUDIENCETYPES.ROLES,
          Icon: Tag,
        };
      }
    }

    /// ----- Fallback ------- ///
    return {
      title: "Custom Audience (Supervisor)",
      description:
        "This module will be deployed to team members based on the role(s) and location(s) combination chosen in the table.",
      ids: [],
      type: AUDIENCETYPES.OTHER,
      Icon: Cast,
    };
  } catch (error) {
    return {
      title: "Error",
      description: "Error getting info.. this might be a bug",
      ids: [],
      type: "Error",
      Icon: X,
    };
  }
};

const CurrentSupervisor = ({ options, audience }) => {
  const { title, description, ids, type, Icon } = getSupervisorAudience(
    options,
    audience,
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        borderRadius: 4,
        padding: 6,
        backgroundColor: "white",
        border: "1px solid slategrey",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Icon
          style={{
            height: 13,
            width: 13,
            color: "black",
            marginRight: 6,

            borderRadius: 2,
          }}
        />
        <Typography variant="subtitle2">{title}</Typography>
      </div>
      <span
        style={{
          fontSize: 12,
          fontWeight: "400",
          marginLeft: 20,
          color: "darkgrey",
        }}
      >
        {description}
      </span>
      {type === AUDIENCETYPES.LOCATIONS && (
        <ScrollWrapper style={{ maxHeight: 90 }}>
          {options.locations
            .filter((loc) => ids.includes(loc.id))
            .map((loc) => (
              <ShowLocationChip
                name={loc.name}
                id={loc.id}
                key={loc.id}
                showLinkToLocation={false}
              />
            ))}
        </ScrollWrapper>
      )}
      {type === AUDIENCETYPES.ROLES && (
        <ScrollWrapper style={{ maxHeight: 90 }}>
          {options.roles
            .filter((role) => ids.includes(role.id))
            .map((role) => (
              <ShowRoleChip
                name={role.name}
                id={role.id}
                key={role.id}
                showLinkToLocation={false}
              />
            ))}
        </ScrollWrapper>
      )}
    </div>
  );
};

export default CurrentSupervisor;
