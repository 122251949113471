import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Typography } from "../../private/styles";
import { ChevronLeft } from "react-feather";

const DashboardSignupHeader = ({
  handleBack = null,
  title = "",
  subtitle = "Fill out fields to register for dashboard access",
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: 16,
        position: "relative",
      }}
    >
      <Typography
        style={{
          fontSize: 22,
          fontWeight: "600",
          textDecoration: "none",
          color: "black",
        }}
        align="center"
        mt={-2}
      >
        {title}
      </Typography>
      <Typography
        style={{ fontSize: 12, color: "darkgrey", fontWeight: "300" }}
        mt={-1}
        align="center"
      >
        {subtitle}
      </Typography>

      <Tooltip
        title="Go Back"
        style={{ position: "absolute", top: -4, left: -12 }}
      >
        <IconButton size="small" onClick={handleBack}>
          <ChevronLeft />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default DashboardSignupHeader;
