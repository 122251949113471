import React from "react";
import { IconButton, Snackbar } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import styled from "styled-components";

// Takes the last word from snackbarText and use it for severity
// Everytime you set a snackbarText make sure the last word is one of
// ["info", "success", "warning", "error"]

const Close = styled(CloseIcon)`
  color: ${(props) => props.theme.palette.primary.light};
`;

const transformText = (text) => {
  const split = text.trim().split(" ");
  const last = split.pop();
  return { text: split.join(" "), severity: last };
};

const SnackbarWrapper = ({ snackbarText, setSnackbarText }) => {
  const { text, severity } = transformText(snackbarText);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={snackbarText.length > 1}
      onClose={() => setSnackbarText("")}
      autoHideDuration={7000}
      disableWindowBlurListener
      key={snackbarText}
      transitionDuration={{
        enter: 300,
        exit: 60,
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          onClick={() => setSnackbarText("")}
        >
          <Close fontSize="small" />
        </IconButton>
      }
    >
      <Alert
        severity={severity}
        variant="filled" // consider going to standard //
        onClose={() => setSnackbarText("")}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarWrapper;

SnackbarWrapper.propTypes = {
  snackbarText: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
