import React from "react";
import { Tooltip, Button } from "@material-ui/core";
import styled from "styled-components";

const StyledButton = styled(Button)`
  height: 30px;
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  max-width: 30px;
  min-width: 30px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: background-color 200ms ease-in;

  svg {
    color: slategrey;
  }
  &:hover {
    background-color: slategray;

    svg {
      color: #eeeeee;
    }
  }
`;

const ActionButtonForAccount = ({
  handleClick,
  disabled = false,
  title = "title",
  Icon,
}) => {
  return (
    <>
      <Tooltip arrow placement="bottom" title={title}>
        <span>
          <StyledButton onClick={handleClick} disabled={disabled}>
            <Icon height={16} width={16} />
          </StyledButton>
        </span>
      </Tooltip>
    </>
  );
};

export default ActionButtonForAccount;
