import React from "react";
import { Divider as MuiDivider, Button, Tooltip } from "@material-ui/core";
import { ArrowLeft, Home } from "react-feather";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const BackButton = styled(Button)`
  height: 34px;
  width: 34px;
  min-height: 34px;
  min-width: 34px;
  max-height: 34px;
  max-width: 34px;
  background: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eeeeee;
`;

const SpacingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 6px 0px 0px 0px;

  white-space: nowrap;
  overflow: hidden;
`;

const Wrapper = styled.div`
  margin-top: -24px;
  // border: 1px solid red;

  @media (max-width: 1280px) {
    margin-top: -8px;
  }

  @media (max-width: 440px) {
    h4 {
      font-size: 14px;
    }

    h2 {
      font-size: 14px;
    }

    h3 {
      font-size: 14px;
    }

    a {
      font-size: 14px;
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

const PrimaryTitle = styled.p`
  font-size: 22px;
  font-weight: 700;
  color: black;
`;

const SecondaryTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
`;

const PrivateHeader = ({
  children,
  header,
  secondary = false,
  Icon = Home,
  hideBackBtn = false,
}) => {
  const history = useHistory();

  if (secondary) {
    return (
      <Wrapper>
        <SpacingBox>
          {!hideBackBtn && (
            <Tooltip arrow title="go back">
              <BackButton onClick={() => history.goBack()}>
                <ArrowLeft height={14} width={14} color="slategray" />
              </BackButton>
            </Tooltip>
          )}
          <SecondaryTitle style={{ color: "black" }}>{header}</SecondaryTitle>
          <span style={{ padding: 2, color: "lightgray", fontSize: 16 }}>
            /
          </span>
          <SecondaryTitle
            style={{
              color: "slategray",
            }}
          >
            {secondary}
          </SecondaryTitle>
          {children && <div style={{ marginLeft: "auto" }}>{children}</div>}
        </SpacingBox>

        <Divider mb={6} mt={2} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <SpacingBox>
        <div
          style={{
            height: 40,
            width: 40,
            borderRadius: 4,
            background: "#eeeeee",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon height={16} width={16} />
        </div>
        <PrimaryTitle>{header}</PrimaryTitle>
        {children && <div style={{ marginLeft: "auto" }}>{children}</div>}
      </SpacingBox>
      <Divider mb={6} mt={2} />
    </Wrapper>
  );
};

export default PrivateHeader;

PrivateHeader.propTypes = {
  header: PropTypes.string.isRequired,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  destination: PropTypes.string,
  small: PropTypes.bool,
  children: PropTypes.node,
};

PrivateHeader.defaultProps = {
  secondary: null,
  destination: null,
  small: null,
  children: null,
};
