import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { Tag } from "react-feather";

const RoleSelect = ({
  roles,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  return (
    <FormControl style={{ width: "100%" }} size="small" variant="outlined">
      <InputLabel id="Roles">Roles</InputLabel>
      <Select
        multiple
        label="Roles"
        labelId="Roles"
        name="roles"
        value={values.roles}
        onBlur={handleBlur}
        fullWidth
        onChange={handleChange}
        error={Boolean(touched.roles && errors.roles)}
        helperText={touched.roles && errors.roles}
        startAdornment={
          <InputAdornment position="start">
            <Tag style={{ height: 14, width: 14, color: "darkgrey" }} />
          </InputAdornment>
        }
      >
        {roles.map((role) => (
          <MenuItem key={role.name} value={role.id} size="small">
            {role.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoleSelect;
