import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import QuestionDetailsMC from "../../moduleBuilder/questions/Display/QuestionDetailsMC";
import QuestionDetailsSA from "../../moduleBuilder/questions/Display/QuestionDetailsSA";
import QuestionDetailsTF from "../../moduleBuilder/questions/Display/QuestionDetailsTF";
import { Typography } from "../../styles";

import { ChevronDown } from "react-feather";
import styled from "styled-components";
import { QUESTION_TYPES } from "../../../../storage/constants";
import { QuestionsWrapper } from "../marketplaceStyles";

const CustomSummary = styled(AccordionSummary)`
  min-height: 30px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #eeeeee;

  .MuiExpanded {
    min-height: 10px;
  }

  .MuiAccordionSummary-content {
    display: flex;
  }
`;

const CustomDetail = styled(AccordionDetails)`
  padding: 8px 8px 0px 8px;
  margin-top: -4px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const StyledAccordion = styled(Accordion)`
  border: none;
  box-shadow: none;
  margin-bottom: 4px;
  border-radius: 4px;

  &:before {
    display: none;
  }
`;

const QUESTION_COMPONENTS = {
  [QUESTION_TYPES.SELECT_ALL]: QuestionDetailsSA,
  [QUESTION_TYPES.MULTIPLE_CHOICE]: QuestionDetailsMC,
  [QUESTION_TYPES.TRUE_FALSE]: QuestionDetailsTF,
};

const GetQuestionType = ({ question }) => {
  const QuestionEl = QUESTION_COMPONENTS[question.type];
  return <QuestionEl question={question} isReview isModifyLive={false} />;
};

GetQuestionType.propTypes = {
  question: PropTypes.object.isRequired,
};

const Questions = ({ questions, minScore, hideMinScore = false }) => (
  <>
    {questions.length === 0 && (
      <Typography variant="subtitle1" mt={4}>
        There are no questions for this Module yet.
      </Typography>
    )}
    <QuestionsWrapper>
      {questions.map((question, index) => (
        <StyledAccordion key={question.id}>
          <CustomSummary
            expandIcon={<ChevronDown color="black" height={15} width={15} />}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",

                marginBottom: 0,
                gap: 0,
                width: "100%",
              }}
            >
              <span
                style={{
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#707A82",
                }}
              >
                Question # {index + 1}
              </span>
              <div>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                  }}
                >
                  {question.question}
                </Typography>
              </div>
            </div>
          </CustomSummary>
          <CustomDetail>
            <GetQuestionType question={question} />
          </CustomDetail>
        </StyledAccordion>
      ))}
      {!hideMinScore && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 4,
            marginTop: 18,
          }}
        >
          <span style={{ color: "slategrey", marginRight: 4 }}>
            Score to pass:{" "}
          </span>
          <span style={{ fontWeight: "600" }}>
            {minScore} / {questions.length}
          </span>
        </div>
      )}
    </QuestionsWrapper>
  </>
);

export default Questions;

Questions.propTypes = {
  questions: PropTypes.array.isRequired,
  minScore: PropTypes.number.isRequired,
  hideMinScore: PropTypes.bool,
};

Questions.defaultProps = {
  hideMinScore: null,
};
