/* eslint-disable react/no-unescaped-entities */
import React from "react";
import styled from "styled-components";
import { Typography } from "../../../pages/private/styles";
import { IntroText } from "../styles";

const Wrapper = styled.div`
  margin-left: 12px;
`;

const Basics = () => (
  <Wrapper>
    <Typography variant="subtitle2">
      What do the start and end dates represent?
    </Typography>
    <IntroText mt={1} mb={6}>
      The start date is the day the module will be deployed to your audience on
      the app. The end date is the day the module will no longer be available to
      your audience in the app. Once a quiz is launched, you cannot change the
      start date, but you can change the end date. Start date is required, while
      you can have an open ended end date (module stays live in perpetuity). You
      cannot set a start date prior to the current date.
    </IntroText>
    <Typography variant="subtitle2">
      What does "minutes to complete" mean?
    </Typography>
    <IntroText mt={1} mb={6}>
      The minutes to complete is just an estimation of time your module will
      take to complete, from reviewing the content to answering the questions.
      It does not need to be exact, just an approximation to give people an
      idea.
    </IntroText>
    <Typography variant="subtitle2">
      Where will my module image be used?
    </Typography>
    <IntroText mt={1} mb={6}>
      The module image will be the main image associated with that module. It
      will show up in the app whenever a user views the module. If you do not
      upload a module image, your company logo will be used in its place.
    </IntroText>
  </Wrapper>
);

export default Basics;
