import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ChevronDown } from "react-feather";
import { Typography } from "../../../styles";
import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core";
import TFEval from "./TFEval";
import MCEval from "./MCEval";
import SAEval from "./SAEval";

const Wrapper = styled.div`
  min-height: 100px;
  width: 100%;
  margin: auto;

  margin-top: 8px;
  max-width: 600px;
`;

const CustomSummary = styled(AccordionSummary)`
  min-height: 30px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #eeeeee;

  .MuiExpanded {
    min-height: 10px;
  }

  .MuiAccordionSummary-content {
    display: flex;
  }
`;

const CustomDetail = styled(AccordionDetails)`
  // padding: 8px 8px 0px 8px;
  padding: 18px 6px;
  margin-top: -4px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const CustomAccordion = styled(Accordion)`
  border: none;
  box-shadow: none;
  margin-bottom: 4px;
  border-radius: 4px;

  &:before {
    display: none;
  }
`;

const EvaluateSubmission = ({ answers }) => (
  <Wrapper>
    {answers.map((answer, index) => (
      <CustomAccordion key={index} style={{ boxShadow: "none" }}>
        <CustomSummary
          expandIcon={<ChevronDown color="black" height={15} width={15} />}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",

              marginBottom: 0,
              gap: 0,
              width: "100%",
            }}
          >
            <span
              style={{
                fontSize: 12,
                fontWeight: "500",
                color: "#707A82",
              }}
            >
              Question # {index + 1}
            </span>
            <div>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                }}
              >
                {answer.question.text}
              </Typography>
            </div>
          </div>
        </CustomSummary>
        <CustomDetail>
          {answer.question.type === "tf" && <TFEval answer={answer} />}
          {answer.question.type === "mc" && <MCEval answer={answer} />}
          {answer.question.type === "sa" && <SAEval answer={answer} />}
        </CustomDetail>
      </CustomAccordion>
    ))}
  </Wrapper>
);

export default EvaluateSubmission;

EvaluateSubmission.propTypes = {
  answers: PropTypes.array.isRequired,
};
