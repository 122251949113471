import React from "react";
import styled from "styled-components";
import { Chip } from "@material-ui/core";

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;

  background: rgb(51, 122, 183);
  margin-left: 6px;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const BadgeLabel = ({ text, num }) => (
  <FlexDiv>
    <span>{text}</span>
    {num && <LinkBadge label={num} />}
  </FlexDiv>
);

export default BadgeLabel;
