import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import LoaderWrapper from "../../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../../components/PrivateHeader";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import useSingleModuleProgress from "../../../../../swr/hooks/RetailReports/useSingleModuleProgress";
import useSingleModuleStats from "../../../../../swr/hooks/RetailReports/useSingleModuleStats";
import { Typography } from "../../../styles";
import QuestionBreakdown from "../../reuseableComponents/QuestionBreakdown";
import FeedbackTableRetail from "./FeedbackTableRetail";
import OutstandingTable from "./OutstandingTable";
import ProgressHero from "./ProgressHero";
import QuizDetails from "./QuizDetails";
import Submissions from "./Submissions";

const SubtitleText = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: rgb(23, 62, 88);
  margin-bottom: 8px;
  margin-top: 16px;
  margin-left: 4px;
`;

const Spacer = styled.div`
  background: transparent;
  height: 16px;
`;

const RetailProgress = ({ id }) => {
  const {
    data,
    isLoading: progressLoading,
    isError: progressError,
  } = useSingleModuleProgress(id);
  const { data: selectedQuiz, isLoading, isError } = useSingleModuleStats(id);

  if (isLoading || progressLoading) {
    return (
      <>
        <PrivateHeader header="Module Report" secondary />
        <LoaderWrapper text="Module report" />
      </>
    );
  }

  if (isError || progressError) {
    return <ErrorBlockWithPermissions errorObj={isError || progressError} />;
  }

  const {
    assigned_users: assignedUsers,
    progress_by_location: locationProgress,
  } = data;

  return (
    <>
      <PrivateHeader
        header="Module Report"
        secondary={selectedQuiz?.quiz?.name}
      />
      <QuizDetails selectedQuiz={selectedQuiz} id={id} />
      <ProgressHero
        locationProgress={locationProgress}
        assignedUsers={assignedUsers}
        moduleName={selectedQuiz?.quiz?.name}
      />
      <Spacer />
      <OutstandingTable
        selectedQuiz={selectedQuiz}
        assignedUsers={assignedUsers}
        locationProgress={locationProgress}
      />
      <Spacer />
      <Submissions
        name={selectedQuiz?.quiz?.name}
        submissions={selectedQuiz?.submissionList}
      />
      {Boolean(selectedQuiz.feedback) && selectedQuiz.feedback.length > 0 && (
        <>
          <Spacer />
          <FeedbackTableRetail feedback={selectedQuiz.feedback} />
        </>
      )}
      {selectedQuiz.submissionList.length > 1 && (
        <>
          <Spacer />
          <SubtitleText>Questions</SubtitleText>
          <QuestionBreakdown selectedQuiz={selectedQuiz} />
        </>
      )}
    </>
  );
};

export default RetailProgress;

RetailProgress.propTypes = {
  id: PropTypes.string.isRequired,
};
