import { Paper } from "@material-ui/core";
import styled from "styled-components";
import { Alert } from "../../private/styles";

export const Wrapper = styled(Paper)`
  width: calc(min(520px, 96vw));
  min-height: 200px;
  padding: 40px;

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

export const WideAlert = styled(Alert)`
  width: 100%;
  .MuiAlert-root {
    margin-top: 8px;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  min-height: 220px;
  align-items: center;
  justify-content: center;
`;
