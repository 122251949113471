/* eslint-disable */
// dr
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { CircularProgress, Paper } from "@material-ui/core";
import { Typography, TextField, Button, Alert } from "../styles";
import { FEEDBACK_MESSAGES } from "../../../storage/constants";
import API from "../../../axios/instances/API";
import styled from "styled-components";

const Wrapper = styled(Paper)`
  padding: 30px;
  min-width: min(600px, 96vw);
  max-width: 600px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const CreateAudience = ({ setAddAudienceOpen, isRetail }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [audienceName, setAudienceName] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const history = useHistory();

  const createAudience = async (e) => {
    e.preventDefault();
    setErrorAlert(false);
    setIsSubmitting(true);
    try {
      const { data } = await API.post("dashboard/audiences/", {
        name: audienceName,
      });
      setIsSubmitting(false);
      setAddAudienceOpen(false);
      history.push(`/audience/detail/${data.id}`);
    } catch (error) {
      setErrorAlert(true);
      setIsSubmitting(false);
    }
  };

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="h3">
          {isRetail ? "Create Audience Template" : "Create Custom Audience"}
        </Typography>
      </div>
      {isRetail ? (
        <Typography
          variant="subtitle1"
          style={{ lineHeight: 1.4, marginTop: 4 }}
        >
          {/* Fill out the field below to create a new Audience. */}
          We’ll save this name as an easy reminder of which groups of employees
          and locations you’re communicating with when you choose this template
          . Be descriptive! For example, “All Managers, Northwest region” or
          “All locations, All roles except Managers".
        </Typography>
      ) : (
        <Typography
          variant="subtitle1"
          style={{ lineHeight: 1.4, marginTop: 4 }}
        >
          Fill out the field below to create a new Audience.
        </Typography>
      )}

      <form id="createAudienceForm" onSubmit={createAudience}>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          mt={10}
          autoFocus
          placeholder="New Audience Name"
          value={audienceName}
          onChange={(e) => setAudienceName(e.target.value)}
        />
        <Button
          type="submit"
          form="createAudienceForm"
          fullWidth
          variant="contained"
          color="secondary"
          disabled={audienceName.length < 3 || isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          mt={4}
        >
          {isSubmitting ? "Creating Audience" : "Create Audience"}
        </Button>
      </form>
      {errorAlert && (
        <Alert
          severity="error"
          mt={2}
          fullWidth
          onClose={() => setErrorAlert(false)}
        >
          {FEEDBACK_MESSAGES.GENERIC_ERROR}
        </Alert>
      )}
    </Wrapper>
  );
};

export default CreateAudience;

CreateAudience.propTypes = {
  setAddAudienceOpen: PropTypes.func.isRequired,
};
