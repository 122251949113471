import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Paper,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from "@material-ui/core";
import * as Yup from "yup";
import styled from "styled-components";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { mutate } from "swr";
import API from "../../../axios/instances/API";
import { TextField, Typography, Alert, FormControl, Button } from "../styles";
import { FEEDBACK_MESSAGES } from "../../../storage/constants";
import useAudienceOptions from "../../../swr/hooks/Audience/useAudienceOptions";
import DeployPermissionsTooltip from "../../../components/UI/DeployPermissionsTooltip";

const Centered = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled(Paper)`
  padding: 30px;
  width: ${(props) => props.theme.spacing(160)}px;
  max-width: 96vw;

  position: absolute;
  min-height: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  border: 2px solid black;
`;

const initialValues = {
  locationName: "",
  addressLineOne: "",
  addressLineTwo: "",
  postcode: "",
  managersCanDeployContent: false,
};

const validationSchema = Yup.object({
  locationName: Yup.string().required("Field is Required"),
  addressLineOne: Yup.string().required("Field is Required"),
  addressLineTwo: Yup.string(),
  postcode: Yup.string().max(7).required("Field is Required"),
});

const getErrorMsg = (error) => {
  try {
    if (Boolean(error?.response?.data?.error?.details)) {
      return error?.response?.data?.error?.details;
    }
    return FEEDBACK_MESSAGES.GENERIC_ERROR;
  } catch (error) {
    return FEEDBACK_MESSAGES.GENERIC_ERROR;
  }
};

const CreateNewLocation = ({ setCreateModalOpen, setSnackbarText }) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [countries, setCountries] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [stateValue, setStateValue] = useState(undefined);
  const [cityValue, setCityValue] = useState(undefined);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [submitError, setSubmitError] = useState("");
  const [stateOrCityError, setStateOrCityError] = useState(false);
  const { mutate: audeinceOptionsMutate } = useAudienceOptions();

  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await API.get("/dashboard/address/countries/");
        setCountries(data.countries);
        setInitialLoad(false);
      } catch (error) {
        setInitialLoad(false);
        setCreateModalOpen(false);
      }
    };

    getCountries();
  }, []);

  const resetUncontrolled = () => {
    setCountryCode("");
    setStateValue(undefined);
    setCityValue(undefined);
    setCities([]);
    setStates([]);
  };

  const handleNewLocationSubmit = async (
    values,
    { setSubmitting, resetForm },
  ) => {
    setSubmitting(true);
    setSubmitError(false);
    const [filteredState] = states.filter((state) => state.name === stateValue);
    const [filteredCity] = cities.filter((city) => city.name === cityValue);
    const sendObject = {
      name: values.locationName,
      line1: values.addressLineOne,
      line2: values.addressLineTwo,
      state: filteredState.id,
      city: filteredCity.id,
      country_code: countryCode,
      postcode: values.postcode,
      managers_can_deploy_content: values.managersCanDeployContent,
    };
    try {
      await API.post("dashboard/locations/", sendObject);
      await mutate("/dashboard/locations/");
      audeinceOptionsMutate();
      setSubmitting(false);
      setSnackbarText(`Location "${values.locationName}" created success`);
      resetForm();
      setCreateModalOpen(false);
    } catch (error) {
      const errorMsg = getErrorMsg(error);
      setSubmitError(errorMsg);
      setSubmitting(false);
      resetForm();
      resetUncontrolled();
    }
  };

  const changeCountry = async (e) => {
    setCities([]);
    setStates([]);
    setCityValue("");
    setStateValue("");
    const code = e.target.value;
    setCountryCode(code);
    if (!code) return;
    try {
      const { data } = await API.get(
        `dashboard/address/states/?country_code=${code}`,
      );
      setStates(data.states);
    } catch (error) {
      setStateOrCityError(true);
    }
  };

  const changeState = async (e) => {
    const chosenState = e.target.value;
    setStateValue(chosenState);
    setCityValue(undefined);
    const [filtered] = states.filter((state) => state.name === chosenState);
    try {
      const { data } = await API.get(
        `dashboard/address/cities/?state_id=${filtered.id}`,
      );
      setCities(data.cities);
    } catch (error) {
      setStateOrCityError(true);
    }
  };

  const changeCity = (e) => {
    setCityValue(e.target.value);
  };

  if (initialLoad) {
    return (
      <ModalWrapper elevation={0}>
        <Centered>
          <span style={{ fontSize: 14, color: "darkgrey" }}>loading...</span>
        </Centered>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          // border: '1px solid black',
        }}
      >
        <Typography
          variant="h3"
          align="left"
          color="textPrimary"
          style={{ lineHeight: 1.1 }}
        >
          Create New Location
        </Typography>
      </div>
      <Typography variant="subtitle1" color="textPrimary" mb={6}>
        Fill in the inputs below to create a new Location.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleNewLocationSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
          dirty,
          isValid,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="locationName"
                  label="Location Name"
                  value={values.locationName}
                  fullWidth
                  error={Boolean(touched.locationName && errors.locationName)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.locationName && errors.locationName}
                  autoComplete="new-password"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="text"
                  name="addressLineOne"
                  label="Address Line One"
                  value={values.addressLineOne}
                  fullWidth
                  error={Boolean(
                    touched.addressLineOne && errors.addressLineOne,
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.addressLineOne && errors.addressLineOne}
                  autoComplete="new-password"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="text"
                  name="addressLineTwo"
                  label="Address Line Two"
                  value={values.addressLineTwo}
                  fullWidth
                  error={Boolean(
                    touched.addressLineTwo && errors.addressLineTwo,
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.addressLineTwo && errors.addressLineTwo}
                  autoComplete="new-password"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl variant="outlined" size="small">
                  <InputLabel id="countrycode-label">Country</InputLabel>
                  <Select
                    labelId="countrycode-label"
                    id="countrycode_select"
                    defaultValue=""
                    onChange={changeCountry}
                    elevation={0}
                    variant="outlined"
                    label="Country"
                    value={countryCode}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl variant="outlined" size="small" label="State">
                  <InputLabel id="provincelabel">Province/State</InputLabel>
                  <Select
                    labelId="provincelabel"
                    id="provinceinput"
                    defaultValue=""
                    onChange={changeState}
                    elevation={0}
                    variant="outlined"
                    label="Province/State"
                  >
                    {states.map((state) => (
                      <MenuItem value={state.name} key={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl variant="outlined" size="small">
                  <InputLabel id="citylabel">City</InputLabel>
                  <Select
                    labelId="citylabel"
                    id="city"
                    defaultValue=""
                    onChange={changeCity}
                    elevation={0}
                    label="City"
                    variant="outlined"
                  >
                    {cities.map((city) => (
                      <MenuItem value={city.name} key={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  type="text"
                  name="postcode"
                  label="Postal Code / ZIP"
                  value={values.postcode}
                  fullWidth
                  error={Boolean(touched.postcode && errors.postcode)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.postcode && errors.postcode}
                  autoComplete="new-password"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    background: "white",
                    width: "100%",
                    minHeight: 48,
                    marginRight: "auto",
                    alignSelf: "flex-start",
                    borderRadius: 8,
                    border: "1px solid lightgray",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      minHeight: 46,
                      padding: "2px 8px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      borderRadius: 4,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 4,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: "500",
                          color: "black",
                        }}
                      >
                        Enable supervisor deploy permissions for this location?
                      </span>
                      <DeployPermissionsTooltip />
                    </div>
                    <span style={{ fontSize: 10, color: "darkgrey" }}>
                      All supervisors who oversee this location will have deploy
                      permissions enabled.
                    </span>
                  </div>
                  <div
                    style={{
                      background: "#eeeeee50",
                      flexBasis: 60,
                      minHeight: 46,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      name="managersCanDeployContent"
                      size="small"
                      color="secondary"
                      checked={values.managersCanDeployContent}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  p={2}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !dirty ||
                    !isValid ||
                    !countryCode ||
                    !cityValue ||
                    !stateValue
                  }
                  startIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  {isSubmitting ? "Creating Location" : "Create Location"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      {submitError && (
        <Alert mt={2} severity="error" onClose={() => setSubmitError("")}>
          {submitError}
        </Alert>
      )}
      {stateOrCityError && (
        <Alert
          mt={2}
          severity="error"
          onClose={() => setStateOrCityError(false)}
        >
          There is an Error Loading the Location Data. Refresh and try again
        </Alert>
      )}
      {/* <DeleteTimes onClick={() => setCreateModalOpen(false)} /> */}
    </ModalWrapper>
  );
};

export default CreateNewLocation;

CreateNewLocation.propTypes = {
  setCreateModalOpen: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
