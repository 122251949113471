import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "../../../styles";

const Wrapper = styled.div`
  padding: 0px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const SmallText = styled.span`
  font-size: 10px;
`;

const TFEval = ({ answer }) => (
  <Wrapper>
    <InfoWrapper>
      <SmallText variant="subtitle2">Correct Answer:</SmallText>
      <Typography variant="h6" ml={2}>
        {answer.question.correctAnswer.toUpperCase()}
      </Typography>
    </InfoWrapper>
    <InfoWrapper>
      <SmallText variant="subtitle2">Submitted Answer:</SmallText>
      <Typography variant="h6" ml={2}>
        {answer.answer.toUpperCase()}
      </Typography>
    </InfoWrapper>
  </Wrapper>
);

export default TFEval;

TFEval.propTypes = {
  answer: PropTypes.object.isRequired,
};
