import React, { useState } from "react";
import { Modal, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import { User, Users } from "react-feather";
import { StyledLink } from "../../styles";
import RemoveRoleModal from "./RemoveRoleModal";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import { CustomDatatableV2 } from "../../styles";
import DeleteButtonForTables from "../../../../components/Table/DeleteButtonForTables";
import TableTitle from "../../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";

const RoleDetail = ({ readOnly, teamMembers, roleId, mutate, name }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [deleteInfo, setDeleteInfo] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  const options = makeDefaultTableOptions("role-details");
  options.rowsPerPage = 50;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = teamMembers.map((member) => ({
    id: member.id,
    name: `${member.user.firstName} ${member.user.lastName}`,
    locations: member.location.name,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            to={`/teammembers/detail/${tableMeta.rowData[0]}`}
            value={value}
            CustomIcon={User}
          />
        ),
      },
    },
    {
      name: "locations",
      label: "Location",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "delete",
      label: "Remove",
      options: {
        sort: false,
        filter: false,
        empty: true,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            color="default"
            size="medium"
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setDeleteInfo({
                teamMemberId: rowData[0],
                name: rowData[1],
                roleName: name,
              });
            }}
          />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle Icon={Users} title="Team members with role" />}
        options={options}
        columns={columns}
        data={tableData}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Remove Role Tag From Team Member"
        aria-describedby="Modal to remove a Role Tag"
      >
        <div>
          <RemoveRoleModal
            deleteInfo={deleteInfo}
            setDeleteInfo={setDeleteInfo}
            setSnackbarText={setSnackbarText}
            roleId={roleId}
            mutate={mutate}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default RoleDetail;

RoleDetail.propTypes = {
  roleId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  teamMembers: PropTypes.array.isRequired,
  mutate: PropTypes.func.isRequired,
};
