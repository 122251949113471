import React, { useState, useEffect, useContext, lazy } from "react";
import { Grid, Modal, Tab, Box, IconButton, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { Users, UserX } from "react-feather";
import useTeamMembers from "../../../swr/hooks/Team/useTeamMembers";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import TeamDataTable from "./TeamTable/TeamDataTable";
import InviteTeamMember from "./InviteTeamMember";
import PrivateHeader from "../../../components/PrivateHeader";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import OnLoadError from "../../../components/UI/OnLoadError";
import TabPanel from "../../../components/TabPanel";
import OutstandingInvitationsTable from "./OutstandingInvitationsTable";
import PendingTable from "./PendingTable";
import { TableTabs, TableTabBar, Button } from "../styles";
import { GlobalState } from "../../../store/GlobalState";
import BadgeLabel from "../../../components/BadgeLabel";
import { Plus } from "react-feather";
import CreateButton from "../../../components/UI/CreateButton";
import LimitsMsg from "../../../components/LimitsMsg";

const InactiveTeamMembers = lazy(() => import("./InactiveTeamMembers"));
const InviteTeamMemberViaCsv = lazy(() => import("./InviteTeamMembersViaCsv"));

const ButtonHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

const StyledIconButtton = styled(IconButton)`
  border-radius: 6px;
  max-height: 36px;
  max-width: 36px;
  padding: 8px;
  background: #eeeeee80;
  border: 1px solid lightgray;

  svg {
    color: slategrey;
  }

  &:hover {
    background: #eeeeee;

`;

const TeamMembers = ({ location }) => {
  const { state } = useContext(GlobalState);
  const { config, job } = state;
  const { user } = config;

  const { read_only: readOnly } = user;
  const [inviteMemberModal, setInviteMemberModal] = useState(false);
  const [inviteCsvModal, setInviteCsvModal] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [inactiveTeamMembersOpen, setInactiveTeamMembersOpen] = useState(false);

  const { permissions } = config?.current_company;

  const pendingTeamMembers =
    config?.current_company?.pending_access_requests?.team_members;

  const { isLoading, isError, mutate: teamMutate, team } = useTeamMembers();

  const isManager = job === "M";

  useEffect(() => {
    if (location.state === null || !location.state) return;

    setTabValue(2);
  }, []);

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Team Members" Icon={Users} />
        <LoaderWrapper text="Team Members" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Helmet title="Spiffy | Team Members" />
      <PrivateHeader header="Team Members" Icon={Users}>
        <LimitsMsg
          title="Team Member"
          current={team?.length || 0}
          limit={permissions?.fields?.user_limit}
          explanationText={`Your account allows up to ${permissions?.fields?.user_limit} team members. To add more, remove members or upgrade your plan.`}
        />
      </PrivateHeader>
      <ButtonHolder>
        <CreateButton
          disabled={readOnly}
          handleClick={() => setInviteMemberModal(true)}
          title="Invite Team Member"
        />
        <Button
          disabled={readOnly}
          color="inherit"
          variant="outlined"
          style={{ height: 38, borderRadius: 4 }}
          startIcon={<Plus height={14} width={14} />}
          onClick={() => setInviteCsvModal(true)}
        >
          Invite via CSV
        </Button>
        <div style={{ marginLeft: "auto" }}>
          <Tooltip arrow title="View Inactive Team Members">
            <StyledIconButtton
              disabled={readOnly || isManager}
              onClick={() => setInactiveTeamMembersOpen(true)}
            >
              <UserX height={16} width={16} />
            </StyledIconButtton>
          </Tooltip>
          {/* <Button style={{ background: "#eeeeee" }}>Inactive Members</Button> */}
        </div>
      </ButtonHolder>
      <div style={{ height: 18 }} />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <TableTabBar position="relative" elevation={0}>
            <TableTabs
              value={tabValue}
              onChange={handleTabChange}
              scrollButtons="off"
            >
              <Tab label="Team Members" />
              <Tab label="Outstanding Invitations" />
              <Tab
                label={
                  <BadgeLabel
                    text="Access Requests"
                    num={!pendingTeamMembers ? null : pendingTeamMembers}
                  />
                }
              />
            </TableTabs>
          </TableTabBar>
        </Grid>
      </Grid>
      <Box mt={0}>
        <Grid container>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={0}>
              <TeamDataTable
                readOnly={readOnly}
                isManager={isManager}
                team={team}
                mutate={teamMutate}
              />
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={1}>
              <OutstandingInvitationsTable readOnly={readOnly} />
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={2}>
              <PendingTable readOnly={readOnly} />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={Boolean(inviteMemberModal)}
        onClose={() => setInviteMemberModal(false)}
        aria-labelledby="Invite Team Member"
        aria-describedby="Modal to invite a team member"
      >
        <div>
          <InviteTeamMember
            setInviteMemberModal={setInviteMemberModal}
            setSnackbarText={setSnackbarText}
          />
        </div>
      </Modal>
      <Modal
        open={inactiveTeamMembersOpen}
        onClose={() => setInactiveTeamMembersOpen(false)}
      >
        <div>
          <InactiveTeamMembers setSnackbarText={setSnackbarText} />
        </div>
      </Modal>
      <Modal
        open={Boolean(inviteCsvModal)}
        onClose={() => setInviteCsvModal(false)}
        aria-labelledby="Invite Team Members By Csv"
        aria-describedby="Modal to invite Team Members By Csv"
      >
        <div>
          <InviteTeamMemberViaCsv
            mutate={teamMutate}
            setInviteCsvModal={setInviteCsvModal}
            setSnackbarText={setSnackbarText}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default TeamMembers;

TeamMembers.propTypes = {
  location: PropTypes.object.isRequired,
};
