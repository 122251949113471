import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import API from "../../../../axios/instances/API";

const ModalWrapper = styled(Paper)`
  padding: 14px;
  width: calc(min(280px, 96vw));
  max-height: 90vh;
  position: absolute;
  left: 50%;
  overflow-y: scroll;
  border: 2px solid black;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const BASE_URL = "/dashboard/analytics/vendor-reports/submission-list/?";

const buildSearchParamsAsString = () => {
  const obj = {
    enable_pagination: 0,
    order_by: "created",
  };

  const searchParams = new URLSearchParams(obj);
  return searchParams.toString();
};

const DownloadAllModal = ({ getTableData, users }) => {
  const [submissions, setSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const retDataUnpaginated = async () => {
    try {
      const urlWithParams = `${BASE_URL}${buildSearchParamsAsString(false)}`;
      const { data } = await API.get(urlWithParams);
      const cleanData = getTableData(data.submissions, users);
      const downloadFormattedData = cleanData.map((row) => ({
        ...row,
        team: row.team
          ? row.team.map((c) => `${c.company}-${c.location}`).join(", ")
          : "",
      }));
      setSubmissions(downloadFormattedData);
      setIsLoading(false);
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    retDataUnpaginated();
  }, []);

  return (
    <ModalWrapper>
      <span style={{ fontWeight: "600", fontSize: 14 }}>
        Download All Submissions
      </span>
      <div style={{ height: 12 }} />

      <CSVLink
        disabled={isLoading}
        style={{
          background: isLoading ? "lightgray" : "#337ab7",
          color: "white",
          padding: "6px 10px",
          borderRadius: 4,
          textDecoration: "none",
          fontWeight: "500",
          border: "1px solid lightgray",
        }}
        data={submissions}
        filename="all-time-submissions"
      >
        {isLoading ? "loading..." : "Download"}
      </CSVLink>
    </ModalWrapper>
  );
};

export default DownloadAllModal;
