import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, useMediaQuery } from "@material-ui/core";
import { List, Tag } from "react-feather";
import useRoles from "../../../swr/hooks/Roles/useRoles";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import API from "../../../axios/instances/API";
import { StyledLink, CustomDatatableV2 } from "../styles";
import { makeDefaultTableOptions } from "../../../storage/helpers";
import ConfirmModalSmall from "../../../components/Modals/ConfirmModalSmall";
import useAudienceOptions from "../../../swr/hooks/Audience/useAudienceOptions";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import TableTitle from "../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";

const RolesTable = ({ readOnly }) => {
  const { roles, rolesLoading, mutate } = useRoles();
  const { mutate: mutateOptions } = useAudienceOptions();
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const handleDelete = async () => {
    try {
      await API.delete("dashboard/roles/details/", {
        data: {
          id: deleteInfo?.id,
        },
      });
      await mutate();
      mutateOptions();
      setSnackbarText(`"${deleteInfo?.name}" role deleted warning`);
      return setDeleteInfo(null);
    } catch (error) {
      setSnackbarText("Failed to delete role error");
    }
  };

  if (rolesLoading) return null;

  const options = makeDefaultTableOptions("roles");
  options.rowsPerPage = 100;
  options.textLabels = {
    body: {
      noMatch:
        'No role tags created for your team yet. Click on "Create New Role" to get started!',
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = roles.map((role) => ({
    id: role.id,
    role: role.name,
    category: role.category === null ? "None" : role.category.name,
    count: role.teamMembers,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) =>
          readOnly ? (
            <span>{value}</span>
          ) : (
            <StyledLink
              to={`/roles/details/${tableMeta.rowData[0]}`}
              value={value}
              CustomIcon={Tag}
            />
          ),
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "count",
      label: "Team Members",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        sort: false,
        filter: false,
        print: false,
        empty: true,
        download: false,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setDeleteInfo({
                id: rowData[0],
                name: rowData[1],
              });
            }}
          />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle title="Roles List" Icon={List} />}
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete Role Modal"
        aria-describedby="Modal to Delete Role"
      >
        <div>
          <ConfirmModalSmall
            title="Delete this role?"
            name={deleteInfo?.name}
            handleConfirm={handleDelete}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default RolesTable;

RolesTable.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};
