/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { QuizBadge, Typography } from "../../pages/private/styles";
import {
  BDown,
  CustomDetails,
  CustomAccordion,
  CustomAccordionSummary,
} from "./styles";

// TODO -> Update and remove this page

const Wrapper = styled.div`
  padding: 10px 0px;
  width: 100%;
  flex: 0.5;
  flex-grow: 1;
`;

const ExplanationHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee;
`;

const BadgeHolder = styled.div`
  margin-left: 4px;
  flex: 0.3;
  height: 100%;
`;

const DescriptionHolder = styled.p`
  flex: 0.6;
  padding: 12px 4px;
  color: ${(props) => props.theme.palette.grey[700]};
  font-size: 12px;
  max-width: 300px;
`;

const ExplainStates = ({ isRetail }) => (
  <Wrapper>
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<BDown />}>
        <Typography variant="subtitle1">
          <strong>Breakdown of Module States</strong>
        </Typography>
      </CustomAccordionSummary>
      <CustomDetails>
        <ExplanationHolder>
          <BadgeHolder>
            <QuizBadge
              input="Live"
              color="secondary"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
                overlap: "circular",
              }}
            >
              Live
            </QuizBadge>
          </BadgeHolder>
          {isRetail ? (
            <DescriptionHolder>
              Module can be viewed and completed in the App. Everyone included
              in the audience will have access to this module.
            </DescriptionHolder>
          ) : (
            <DescriptionHolder>
              Modules with this state have been successfully published on the
              marketplace, and are available for consumption. Once a module is
              live, only the audience, content piece, and answer text can be
              edited.
            </DescriptionHolder>
          )}
        </ExplanationHolder>
        <ExplanationHolder>
          <BadgeHolder>
            <QuizBadge
              input="Pending Release"
              color="secondary"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
                overlap: "circular",
              }}
            >
              Pending Release
            </QuizBadge>
          </BadgeHolder>
          <DescriptionHolder>
            Modules that are "Pending Release", have been launched but are
            waiting for their "Start Date" to be deployed. When the start date
            arrives, the modules state will change to "Live". While pending
            release, you still have the ability to edit all components of the
            module.
          </DescriptionHolder>
        </ExplanationHolder>
        <ExplanationHolder>
          <BadgeHolder>
            <QuizBadge
              input="In Development"
              color="secondary"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
                overlap: "circular",
              }}
            >
              In Development
            </QuizBadge>
          </BadgeHolder>
          <DescriptionHolder>
            Module has been created but is still in development. It may be
            missing the content, questions or audience. Make sure all details
            are filled in before launching.
          </DescriptionHolder>
        </ExplanationHolder>
        <ExplanationHolder>
          <BadgeHolder>
            <QuizBadge
              input="Closed"
              color="secondary"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
                overlap: "circular",
              }}
            >
              Closed
            </QuizBadge>
          </BadgeHolder>
          <DescriptionHolder>
            This state is achieved when an admin closes the module or its "end
            date" is passed. When the module is closed, only team members who
            have successfully completed it can view it. They can do so in the
            "Review" tab in the app. A closed module cannot be edited.
          </DescriptionHolder>
        </ExplanationHolder>
      </CustomDetails>
    </CustomAccordion>
  </Wrapper>
);

export default ExplainStates;

ExplainStates.propTypes = {
  isRetail: PropTypes.bool.isRequired,
};
