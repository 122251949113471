import {
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Chip,
  Paper,
  Tabs,
} from "@material-ui/core";
import { Award } from "react-feather";
import styled from "styled-components";

export const QuizTabAppBar = styled(AppBar)`
  width: fit-content;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  background-color: transparent;
  margin-top: 8px;
`;

export const QuizTabs = styled(Tabs)`
  height: 30px;
  max-height: 30px;
  min-height: 30px;

  .MuiTab-root {
    height: 30px;
    min-width: 100px;
    border-radius: 4px;
    border: 2px solid lightgray;
    color: darkgrey;
    margin: 0px 2px;
    background: #eeeeee44;
  }

  .MuiTab-root.Mui-selected {
    border: 2px solid #337ab7;
    background-color: #eeeeee22;
    color: black;
  }

  .MuiButtonBase-root {
    padding: 2px;
    min-height: 24px;
    min-width: 100px;
  }

  .MuiTab-wrapper {
    padding: 0px;
  }

  .MuiTab-wrapper.Mui-selected {
    color: black;
  }
`;

export const LoaderCentered = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QuizBuilderWrapper = styled(Paper)`
  width: 100%;
  min-height: 540px;
  padding: 0px;
  position: relative;
  margin-bottom: 100px;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.07);
`;

export const Separate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 20px;
  width: 100%;
  margin-top: 10px;
`;

export const SpacingDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 4px;
  border-bottom: 1px solid #eeeeee;
  padding: 1px;
`;

export const QuestionAvatar = styled(Avatar)`
  height: 18px;
  width: 18px;
  font-size: 11px;
  font-weight: 600;
  margin-right: 12px;
  background-color: ${(props) =>
    props.activecolor === "Yes" ? "#337ab7" : props.theme.palette.grey[400]};
`;

// Quiz Answer Components //

export const QTChip = styled(Chip)`
  background-color: white;
  font-size: 9px;
  margin-left: auto;
`;

export const CustomSummary = styled(AccordionSummary)`
  min-height: 30px;
  border-radius: 4px;
  background-color: #eeeeee88;

  .MuiExpanded {
    min-height: 10px;
  }

  .MuiAccordionSummary-content {
    display: flex;
  }
`;

export const CustomDetail = styled(AccordionDetails)`
  padding: 8px 8px 0px 8px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

// Audience

export const BAward = styled(Award)`
  color: ${(props) => props.theme.palette.secondary.light};
  height: 16px;
  width: 16px;
  margin-left: 4px;
  transform: translateY(2px);
`;

export const CurrentAudienceWrapper = styled.div`
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  border-radius: 6px;

  @media (max-width: 960px) {
    border: 2px solid #eee;
    min-height: 360px;
    margin-top: 40px;
    height: auto;
    margin-bottom: 40px;
  }
`;

export const HighlightOrange = styled.span`
  color: ${(props) => props.theme.palette.warning.main};
`;

// Review

export const NoContentWrapper = styled.div`
  border: 2px solid #eeeeee;
  border-radius: 2px;
  min-height: 304px;
  min-width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
