import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";

const Wrapper = styled.div`
  height: 20px;
  width: 100%;
  padding: 0px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CustomLinearProgress = styled(LinearProgress)`
  background-color: white;
  background-color: ${(props) => props.theme.palette.grey[100]};
  border-radius: 8px;
  height: 8px;
  width: 100%;


  

  .MuiLinearProgress-barColorPrimary{    
    background-color: ${(props) => {
      if (props.value > 80) return props.theme.palette.success.main;
      if (props.value > 60) return props.theme.palette.success.light;
      if (props.value > 40) return props.theme.palette.warning.light;
      if (props.value > 20) return props.theme.palette.warning.main;
      if (props.value > 0) return props.theme.palette.error.main;
      return props.theme.palette.primary.main;
    }};
`;

const ProgBarMini = ({ progress }) => (
  <Wrapper>
    <CustomLinearProgress
      aria-label="progress"
      variant="determinate"
      value={progress}
    />
  </Wrapper>
);

export default ProgBarMini;

ProgBarMini.propTypes = {
  progress: PropTypes.number.isRequired,
};
