import React from "react";
import { Tooltip, Button } from "@material-ui/core";
import styled from "styled-components";

const StyledButton = styled(Button)`
  height: 38px;
  width: 38px;
  min-height: 38px;
  max-height: 38px;
  max-width: 38px;
  min-width: 38px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: background-color 200ms ease-in;

  svg {
    color: black;
  }

  &:hover {
    background-color: lightgray;

    svg {
      color: black;
    }
  }

  &:disabled {
    svg {
      color: lightgray;
    }
  }
`;

const StyledButtonPrimary = styled(Button)`
  height: 38px;
  width: 38px;
  min-height: 38px;
  max-height: 38px;
  max-width: 38px;
  min-width: 38px;
  background-color: #337ab7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: background-color 200ms ease-in;

  svg {
    color: white;
  }
  &:hover {
    background-color: #134f83;

    svg {
      color: #eeeeee;
    }
  }

  &:disabled {
    background-color: #eeeeee;
  }
`;

const StyledButtonError = styled(Button)`
  height: 38px;
  width: 38px;
  min-height: 38px;
  max-height: 38px;
  max-width: 38px;
  min-width: 38px;
  background-color: slategray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: background-color 200ms ease-in;

  svg {
    color: white;
  }

  &:hover {
    background-color: firebrick;

    svg {
      color: #eeeeee;
    }
  }

  &:disabled {
    background-color: #eeeeee;
  }
`;

const AttActionButton = ({
  handleClick,
  disabled = false,
  title = "title",
  Icon,

  primary = false,
  error = false,
}) => {
  return (
    <Tooltip arrow placement="bottom" title={title}>
      <div>
        {/* ^ this div allows tooltip to still show if button is disabled --- dont remove */}
        {primary ? (
          <StyledButtonPrimary onClick={handleClick} disabled={disabled}>
            <Icon height={14} width={14} />
          </StyledButtonPrimary>
        ) : error ? (
          <StyledButtonError onClick={handleClick} disabled={disabled}>
            <Icon height={14} width={14} />
          </StyledButtonError>
        ) : (
          <StyledButton onClick={handleClick} disabled={disabled}>
            <Icon height={14} width={14} />
          </StyledButton>
        )}
      </div>
    </Tooltip>
  );
};

export default AttActionButton;
