import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { DeleteWrapper, DeleteButton, Alert, Typography } from "../../styles";
import API from "../../../../axios/instances/API";
import { FEEDBACK_MESSAGES } from "../../../../storage/constants";

const ConfirmDeleteTeamMember = ({ deleteInfo, setDeleteInfo, endpoint }) => {
  const [isdeleting, setIsDeleting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);
  const history = useHistory();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await API.delete(endpoint, {
        data: {
          id: deleteInfo.id,
        },
      });
      setIsDeleting(false);
      setHasDeleted(true);
      setTimeout(() => {
        history.push("/teammembers/");
      }, 4000);
    } catch (error) {
      setErrorAlert(true);
      setIsDeleting(false);
    }
  };

  return (
    <DeleteWrapper style={{ border: "1px solid tomato", width: 460 }}>
      {hasDeleted ? (
        <Alert my={6}>
          Team member successfully deleted, you will be redirected to the team
          member page
        </Alert>
      ) : (
        <>
          <Typography variant="h5" component="h5">
            Remove team member?
          </Typography>
          <Typography mb={6} variant="subtitle1">
            {deleteInfo?.name}
          </Typography>
          <DeleteButton
            fullWidth
            p={2}
            disabled={isdeleting}
            onClick={handleDelete}
          >
            {isdeleting ? "..." : "Confirm"}
          </DeleteButton>
          {errorAlert && (
            <Alert mt={4} severity="error" onClose={() => setErrorAlert(false)}>
              {FEEDBACK_MESSAGES.GENERIC_ERROR}
            </Alert>
          )}
        </>
      )}
    </DeleteWrapper>
  );
};

export default ConfirmDeleteTeamMember;

ConfirmDeleteTeamMember.propTypes = {
  deleteInfo: PropTypes.object,
  setDeleteInfo: PropTypes.func.isRequired,
  endpoint: PropTypes.string,
};

ConfirmDeleteTeamMember.defaultProps = {
  endpoint: null,
  deleteInfo: null,
};
