import React, { useState } from "react";
import { Button, CircularProgress, Paper, TextField } from "@material-ui/core";
import { Trash2 } from "react-feather";
import styled from "styled-components";
import API from "../../../../../axios/instances/API";
import Item from "./Item";

const ModalWrapper = styled(Paper)`
  padding: 20px 20px 14px 20px;
  width: calc(min(500px, 96vw));
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 640px;
`;

const ListContainer = styled.div`
  flex: 1;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  width: 100%;
  overflow-y: scroll;
  padding: 4px;
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 12px;
`;

const getCleanTeamMembers = (team, searchActive, search) => {
  const flatTeamMembers = team
    .map((member) => {
      if (member.team_members.length > 1) {
        return member.team_members.map((m) => {
          return {
            teamMember: m,
            name: `${member.first_name} ${member.last_name}`,
          };
        });
      } else {
        return {
          teamMember: member.team_members[0],
          name: `${member.first_name} ${member.last_name}`,
        };
      }
    })
    .flat();

  if (!searchActive) return flatTeamMembers;

  return flatTeamMembers.filter(
    (member) =>
      member?.name?.toLowerCase().includes(search.toLowerCase()) ||
      member?.teamMember?.location_name
        ?.toLowerCase()
        .includes(search.toLowerCase()),
  );
};

const BulkDeleteModal = ({ team, mutate, handleClose, setSnackbarText }) => {
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const isDisabled = deleteIds.length === 0 || isDeleting;
  const searchActive = search.length > 2;

  const handleBulkDelete = async () => {
    try {
      setIsDeleting(true);

      await API.delete("/dashboard/teams/bulk-fire/", {
        data: {
          team_member_ids: deleteIds,
        },
      });
      await mutate();
      setSnackbarText(
        `Successfully removed ${deleteIds.length} team ${
          deleteIds.length === 1 ? "member" : "members"
        } success`,
      );
      setIsDeleting(false);
      handleClose();
    } catch (error) {
      setSnackbarText("Failed to remove team members error");
      setIsDeleting(false);
    }
  };

  const cleanTeamMembers = getCleanTeamMembers(team, searchActive, search);

  const handleClick = (id) => {
    if (deleteIds.includes(id)) {
      const newIds = deleteIds.filter((_id) => _id !== id);
      setDeleteIds(newIds);
    } else {
      const newIds = [...deleteIds, id];
      setDeleteIds(newIds);
    }
  };

  return (
    <ModalWrapper>
      <span style={{ fontSize: 16, fontWeight: "600" }}>
        Remove Team Members
      </span>
      <span style={{ fontSize: 12, color: "darkgrey" }}>
        Use this feature to remove members from your team.
      </span>
      <div style={{ height: 4 }} />
      <TextField
        style={{ marginTop: 4 }}
        fullWidth
        variant="outlined"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        size="small"
        placeholder="Search..."
      />
      <ListContainer>
        <>
          <div>
            {(cleanTeamMembers || []).map((member) => (
              <Item
                key={member.teamMember?.team_member_id}
                member={member}
                handleClick={handleClick}
                checked={deleteIds.includes(member.teamMember?.team_member_id)}
              />
            ))}
          </div>
          {cleanTeamMembers.length === 0 && searchActive && (
            <div style={{ padding: 6 }}>
              <span style={{ color: "darkgrey", fontSize: 12 }}>
                No results for this search...
              </span>
            </div>
          )}
        </>
      </ListContainer>
      <ButtonContainer>
        <Button
          size="small"
          startIcon={
            isDeleting ? (
              <CircularProgress size={16} color="white" />
            ) : (
              <Trash2 color="white" height={13} width={13} />
            )
          }
          disabled={isDisabled}
          style={{
            backgroundColor: isDisabled ? "darkgrey" : "firebrick",
            color: "white",
            padding: "4px 8px",
          }}
          onClick={handleBulkDelete}
        >
          Remove {isDisabled ? null : `(${deleteIds.length})`}
        </Button>
      </ButtonContainer>
    </ModalWrapper>
  );
};

export default BulkDeleteModal;
