import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Switch, LinearProgress, CircularProgress } from "@material-ui/core";
import ExtraInfo from "../../../../components/ExtraInfo";
import API from "../../../../axios/instances/API";
import useCompanyDetails from "../../../../swr/hooks/Util/useCompanyDetails";
import { Typography } from "../../styles";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #ffffff;
  padding: 2px 8px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  width: 200px;
  position: relative;
  align-self: flex-start;
`;

const ProgressMarginLeft = styled(CircularProgress)`
  margin-left: 4px;
`;

const CustomLinearProg = styled(LinearProgress)`
  width: 96%;
  position: absolute;
  bottom: 0px;
  height: 1px;
  border-radius: 1px;
`;

const AutoAcceptSwitch = ({ setSnackbarText }) => {
  const { isLoading, isError, company, mutate } = useCompanyDetails();

  const [isUpdating, setIsUpdating] = useState(false);

  if (isError) return null;

  const handleChange = async (e) => {
    setIsUpdating(true);
    try {
      await API.put("/dashboard/company/details/", {
        auto_accept_modules: e.target.checked,
      });
      await mutate();
      setIsUpdating(false);
    } catch (error) {
      setSnackbarText("Failed up update auto accep error");
      setIsUpdating(false);
    }
  };

  if (isLoading) {
    return (
      <Wrapper>
        <Typography variant="body1">Auto Accept?</Typography>
        <Switch disabled />
        <CustomLinearProg variant="indeterminate" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Typography variant="body1">Auto Accept?</Typography>
      {isUpdating ? (
        <ProgressMarginLeft size={12} ml={2} />
      ) : (
        <ExtraInfo
          text='You do not automatically accept and deploy suggested modules and streams. They will be added to your "Suggested" tables for further review.'
          saved={company.autoAcceptModules}
          savedText="Suggested modules and streams from vendors are automatically accepted and deployed."
        />
      )}
      <Switch
        disabled={isUpdating}
        checked={company.autoAcceptModules}
        onChange={handleChange}
      />
    </Wrapper>
  );
};

export default AutoAcceptSwitch;

AutoAcceptSwitch.propTypes = {
  setSnackbarText: PropTypes.func.isRequired,
};
