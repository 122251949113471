import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import { Search } from "react-feather";

const BREAKPOINT = 800;

const Wrapper = styled.div`
  width: 100%;
  min-height: 66px;
  background: white;
  border-radius: 6px;
  margin-bottom: 8px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  gap: 4px;
  position: relative;

  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    gap: 6px;
    padding: 14px;
  }
`;

const CustomFormControl = styled(FormControl)`
  width: 200px;

  @media (max-width: ${BREAKPOINT}px) {
    width: 100%;
  }
`;

const ResetButton = styled(Button)`
  background: black;
  color: white;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const LoadingLinearProgress = styled(LinearProgress)`
  height: 1px;
  width: 99%;
  position: absolute;
  bottom: -1px;
  left: 4px;

  .MuiLinearProgress-barColorPrimary {
    background-color: slategrey;
  }
`;

const ResponsiveTextField = styled(TextField)`
  width: 200px;

  @media (max-width: ${BREAKPOINT}px) {
    flex-grow: 1;
    width: 100%;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: auto;

  @media (max-width: ${BREAKPOINT}px) {
    margin-left: 0px;
    width: 100%;
  }
`;

const TopFilters = ({
  engagmentType,
  setEngagementType,
  setSearchText,
  companyId,
  setCompanyId,
  companies,
  resetTrigger,
  isLoading,
}) => {
  const [localSearchText, setLocalSearchText] = useState("");

  useEffect(() => {
    if (resetTrigger) {
      setLocalSearchText("");
    }
  }, [resetTrigger]);

  const handleReset = () => {
    setLocalSearchText("");
    setSearchText("");
    setCompanyId(0);
    setEngagementType(ENGAGEMENT_TYPES.ALL);
  };

  return (
    <Wrapper>
      <CustomFormControl>
        <Select
          variant="outlined"
          size="small"
          margin="dense"
          value={engagmentType}
          onChange={(e) => setEngagementType(e.target.value)}
          style={{
            // width: 180,
            borderRadius: 6,
            height: 40,
          }}
        >
          <MenuItem value={ENGAGEMENT_TYPES.ALL}>All Engagement</MenuItem>
          <MenuItem value={ENGAGEMENT_TYPES.ASSIGNED}>Assigned</MenuItem>
          <MenuItem value={ENGAGEMENT_TYPES.EXTRACREDIT}>Follower</MenuItem>
        </Select>
      </CustomFormControl>
      <CustomFormControl>
        <Select
          variant="outlined"
          size="small"
          placeholder="All Companies"
          value={companyId}
          margin="dense"
          onChange={(e) => setCompanyId(e.target.value)}
          style={{
            height: 40,
          }}
        >
          <MenuItem value={0}>All Companies</MenuItem>
          {companies.map((company) => (
            <MenuItem key={company.company_id} value={company.company_id}>
              {company?.name}
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
      <SearchContainer>
        <ResponsiveTextField
          size="small"
          variant="outlined"
          placeholder="Search"
          value={localSearchText}
          onChange={(e) => setLocalSearchText(e.target.value)}
        />
        <Button
          disabled={localSearchText.length < 3}
          onClick={() => setSearchText(localSearchText)}
          style={{
            borderColor: "gainsboro",
            height: 36,
            width: 36,
            maxWidth: 36,
            minWidth: 36,
            minHeight: 36,
            maxHeight: 36,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
          }}
          variant="outlined"
        >
          <Search
            color={localSearchText.length < 3 ? "lightgray" : "black"}
            height={13}
            width={13}
          />
        </Button>
        <div
          style={{
            marginLeft: 8,
            marginRight: 8,
            height: 30,
            width: 1,
            background: "lightgray",
          }}
        />
        <ResetButton onClick={handleReset}>Reset</ResetButton>
      </SearchContainer>
      {isLoading && (
        <LoadingLinearProgress variant="indeterminate" color="primary" />
      )}
    </Wrapper>
  );
};

export default TopFilters;
