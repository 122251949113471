import React from "react";
import { StyledItem } from "../styles";
import { Calendar } from "react-feather";
import {
  USER_TYPES,
  QUIZSTATES,
  LIBRARY_MODAL_KEYS,
} from "../../../../../storage/constants";

const EditStartDate = ({
  id,
  moduleState,
  userType,
  isRetailerModuleFromMarketplace,
  setModalState,
  handleClose,
}) => {
  if (userType !== USER_TYPES.RETAIL_ADMIN) {
    return null;
  }

  if (!isRetailerModuleFromMarketplace) return null;

  if (![QUIZSTATES.PENDING].includes(moduleState)) {
    return null;
  }

  return (
    <StyledItem
      onClick={() => {
        setModalState({ key: LIBRARY_MODAL_KEYS.EDIT_START_DATE, id });
        handleClose();
      }}
    >
      <Calendar />
      <span>Edit Start Date</span>
    </StyledItem>
  );
};

export default EditStartDate;
