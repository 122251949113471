import React from "react";
import { Lock } from "react-feather";
import { InputAdornment } from "@material-ui/core";
import { TextField } from "../../private/styles";

const PasswordField = ({
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
}) => {
  return (
    <TextField
      type="password"
      name="password"
      variant="outlined"
      label="Password"
      size="small"
      fullWidth
      value={values.password}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(touched.password && errors.password)}
      helperText={touched.password && errors.password}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Lock style={{ height: 14, width: 14, color: "darkgrey" }} />
          </InputAdornment>
        ),
        autoComplete: "new-password",
        form: {
          autoComplete: "off",
        },
      }}
    />
  );
};

export default PasswordField;
