import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Paper, Modal } from "@material-ui/core";
import useGuideDetails from "../../swr/hooks/userGuideDetails";
import { Button } from "../../pages/private/styles";
import ShowVideo from "./ShowVideo";
import SnackbarWrapper from "../SnackbarWrapper";
import API from "../../axios/instances/API";
import { GUIDE_TRIGGERS } from "../../storage/constants";

const Wrapper = styled(Paper)`
  padding: 14px 20px;
  min-height: 380px;
  width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  outline: none;
`;

const isTriggerInSessionStorage = (trigger) => sessionStorage.getItem(trigger);

const Guides = ({ trigger = GUIDE_TRIGGERS.RETAIL_HOME }) => {
  const { data, isLoading, isError } = useGuideDetails(trigger);
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  useEffect(() => {
    if (isLoading) return null;
    if (isError) return null;

    const { completed, guide } = data;
    if (!completed && guide) {
      const inStorage = isTriggerInSessionStorage(trigger);

      if (!inStorage) {
        setIsOpen(true);
      }
    }
  }, [isLoading, isError]);

  if (isLoading || isError || !isOpen) return null;

  const handleFinish = async () => {
    try {
      await API.post("/dashboard/guides/", { id: data.guide.guide_id });
      return setIsOpen(false);
    } catch (error) {
      return setSnackbarText("Guide not completed error");
    }
  };

  const handleRemindLater = () => {
    sessionStorage.setItem(trigger, true);
    return setIsOpen(false);
  };

  return (
    <>
      <Modal
        open
        onClose={() => setIsOpen(false)}
        aria-labelledby="Spiffy Guides"
        aria-describedby="Onboarding Guide"
        disableEnforceFocus
      >
        <Wrapper>
          <span style={{ fontSize: 20, fontWeight: "600" }}>
            {data.guide.title}
          </span>
          <span
            style={{
              fontSize: 13,
              fontWeight: "300",
              marginTop: -2,
              marginBottom: 6,
            }}
          >
            {data.guide.subheading}
          </span>
          <ShowVideo
            videoUrl={data.guide.video_url}
            setSnackbarText={setSnackbarText}
          />
          <div
            style={{
              marginTop: 8,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              mr={1}
              onClick={handleRemindLater}
            >
              Remind Me Later
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFinish}
              ml={1}
            >
              Finish
            </Button>
          </div>
        </Wrapper>
      </Modal>
      <SnackbarWrapper
        setSnackbarText={setSnackbarText}
        snackbarText={snackbarText}
      />
    </>
  );
};

export default Guides;
