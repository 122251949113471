import React, { useState, useEffect } from "react";
import { Paper, Grid, MenuItem, CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { Formik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Button, Alert, Typography, TextField } from "../../styles";
import API from "../../../../axios/instances/API";

const Wrapper = styled(Paper)`
  padding: 30px;
  width: 560px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const WideAlert = styled(Alert)`
  width: 100%;
`;

const validationSchema = Yup.object({
  locationName: Yup.string("Enter your First Name").required(
    "Fist name is required",
  ),
  line1: Yup.string("Enter your Last Name").required("Last name is required"),
  postal: Yup.string().max(7, "Postal Code/Zip should be 6 characters"),
});

const EditLocationDetails = ({
  data,
  readOnly,
  isAdmin,
  setSnackbarText,
  handleClose,
}) => {
  const [countryCodeValue, setCountryCodeValue] = useState("CA");
  const [stateValue, setStateValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [locationRequestError, setLocationRequestError] = useState(false);
  const [resetStates, setResetStates] = useState(false);
  const [resetCities, setResetCities] = useState(false);

  useEffect(() => {
    const getStatesAndCities = async () => {
      try {
        const { data: countryData } = await API.get(
          "dashboard/address/countries/",
        );
        const { data: stateData } = await API.get(
          `dashboard/address/states/?country_code=${data.location.address.country.code}`,
        );
        const { data: cityData } = await API.get(
          `dashboard/address/cities/?state_id=${data.location.address.state.id}`,
        );
        // Set possible country/state/city value
        setCountries(countryData.countries);
        setStates(stateData.states);
        setCities(cityData.cities);
        // Set the current value
        setCountryCodeValue(data.location.address.country.code);
        setStateValue(data.location.address.state);
        setCityValue(data.location.address.city);
      } catch (error) {
        setLocationRequestError(true);
      }
    };

    return getStatesAndCities();
  }, []);

  const changeCountry = async (e) => {
    try {
      setResetStates((p) => !p);
      setResetCities((p) => !p);
      setCityValue("");
      setStateValue("");
      setCountryCodeValue(e.target.value);
      const { data: countryData } = await API.get(
        `dashboard/address/states/?country_code=${e.target.value}`,
      );
      setStates(countryData.states);
    } catch (err) {
      setLocationRequestError(true);
    }
  };

  const changeState = async (newValue) => {
    if (!newValue) {
      setCities([]);
      setCityValue("");
      setStateValue("");
      setResetStates((p) => !p);
      return setResetCities((p) => !p);
    }

    try {
      setStateValue(newValue);
      setResetCities((p) => !p);
      setCities([]);
      setCityValue("");
      const { data: stateData } = await API.get(
        `dashboard/address/cities/?state_id=${newValue.id}`,
      );
      return setCities(stateData.cities);
    } catch (err) {
      return setLocationRequestError(true);
    }
  };

  const changeCity = (newValue) => {
    if (!newValue) {
      setCityValue("");
      return setResetCities((p) => !p);
    }

    return setCityValue(newValue);
  };

  const initialValues = {
    locationName: data.location.name,
    line1: data.location.address.line1,
    line2: data.location.address.line2,
    postal:
      data.location.address.postcode === null
        ? ""
        : data.location.address.postcode,
  };

  const handleEditLocationSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const sendObj = {
      id: data.location.id,
      name: values.locationName,
      line1: values.line1,
      line2: values.line2,
      state_id: stateValue.id,
      city_id: cityValue.id,
      country_code: countryCodeValue,
      postcode: values.postal,
    };
    try {
      await API.put("dashboard/locations/details/", sendObj);
      setSubmitting(false);
      setSnackbarText("Location details updated success");
      return handleClose();
    } catch (err) {
      setSubmitting(false);
      return setSnackbarText("Location details failed to update error");
    }
  };

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="h3">Edit Location Details</Typography>
      </div>
      <Typography variant="body1" mb={8}>
        Edit inputs and save to change details for this location
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleEditLocationSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
          dirty,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="locationName"
                  label="Location Name"
                  value={values.locationName}
                  fullWidth
                  error={Boolean(touched.locationName && errors.locationName)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.locationName && errors.locationName}
                  autoComplete="new-password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="line1"
                  label="Address Line 1"
                  value={values.line1}
                  fullWidth
                  error={Boolean(touched.line1 && errors.line1)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.line1 && errors.line1}
                  autoComplete="new-password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="line2"
                  label="Address Line 2"
                  value={values.line2}
                  fullWidth
                  error={Boolean(touched.line2 && errors.line2)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.line2 && errors.line2}
                  autoComplete="new-password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="postal"
                  label="Postal Code/ ZIP"
                  value={values.postal}
                  fullWidth
                  error={Boolean(touched.postal && errors.postal)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.postal && errors.postal}
                  autoComplete="new-password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  label="Country"
                  elevation={0}
                  value={countryCodeValue}
                  onChange={changeCountry}
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  {countries.length > 0 ? (
                    countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.code}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">None</MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  key={resetStates}
                  options={states}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, newValue) => changeState(newValue)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  size="small"
                  value={stateValue}
                  noOptionsText="No State/Province Available"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State/Province"
                      variant="outlined"
                      size="small"
                      autoComplete="new-password"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  key={resetCities}
                  options={cities}
                  value={cityValue}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, newValue) => changeCity(newValue)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  size="small"
                  noOptionsText="No Cities Available"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      variant="outlined"
                      size="small"
                      autoComplete="new-password"
                    />
                  )}
                />
              </Grid>

              <Button
                mt={4}
                mb={2}
                mr={2}
                ml={2}
                variant="contained"
                color="secondary"
                fullWidth
                type="submit"
                disabled={
                  isSubmitting ||
                  readOnly ||
                  !isAdmin ||
                  // Object.keys(touched).length === 0 ||
                  // !dirty ||
                  !countryCodeValue ||
                  !cityValue ||
                  !stateValue
                }
                startIcon={isSubmitting && <CircularProgress size={20} />}
              >
                {isSubmitting ? "Submitting" : "Save"}
              </Button>
              {locationRequestError && (
                <WideAlert
                  mt={2}
                  severity="error"
                  onClose={() => setLocationRequestError(false)}
                  fullWidth
                >
                  Error loading location data. Please refresh and try again.
                </WideAlert>
              )}
            </Grid>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default EditLocationDetails;

EditLocationDetails.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
