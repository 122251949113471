// TODO -> removed and move to component

import styled from "styled-components";

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  height: 38px;
  width: 100%;
  background-color: white;

  &:hover {
    background-color: #eeeeee;
    cursor: pointer;

    svg {
      background-color: lightgray;
      padding: 3.5px;
      transform: scale(1.01);
      transition: all 200ms ease-in;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    color: black;
    background-color: #eeeeee;
    padding: 4px;
    border-radius: 4px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }
`;
