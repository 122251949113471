import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ArrowRight } from "react-feather";
import NoImage from "../../../../assets/images/empty-small.jpg";
import { getSrcTwo } from "../../../../storage/helpers";
import { StyledLink } from "../../styles";
import { Tooltip } from "@material-ui/core";
import { CmPointerContainer, MpPointerContainer } from "../../styles";

const FlexDivImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    border: 1px solid #eeeeee;
    margin-right: 8px;
    height: 46px;
    width: 46px;
    object-fit: contain;
    border-radius: 4px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-overflow: ellipsis;
  maxwidth: 280px;
`;

const TitleSpan = styled.p`
  font-size: 12px;
  font-weight: 500;
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`;

const ParentSpan = styled.p`
  color: darkgrey;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LibraryShowModule = ({
  srcString,
  value,
  marketplaceParentInfo,
  linkString = null,
  config,
}) => {
  const companyName = config?.current_company?.name;

  return (
    <FlexDivImage>
      <LazyLoadImage
        src={getSrcTwo(srcString)}
        // width={46}
        effect="opacity"
        placeholder={
          <div
            style={{
              height: 46,
              width: 46,
              borderRadius: 8,
              backgroundColor: "#eeeeee",
              border: "1px solid lightgray",
            }}
          />
        }
        style={{
          backgroundColor: "white",
          borderRadius: 8,
        }}
        onError={(event) => (event.target.src = NoImage)}
      />
      <TextContainer>
        {linkString ? (
          <StyledLink value={value || "---/---"} to={linkString} />
        ) : (
          <Tooltip title={value} arrow placement="top">
            <TitleSpan>{value || "---/---"}</TitleSpan>
          </Tooltip>
        )}
        {marketplaceParentInfo ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 0,
              gap: 3,
            }}
          >
            <ParentSpan>{marketplaceParentInfo.companyName}</ParentSpan>
            <ArrowRight height={8} width={8} color="black" />
            <Tooltip
              title="Module was added from the marketplace"
              arrow
              placement="right"
            >
              <MpPointerContainer>
                <span style={{ color: "#813eee" }}>MP</span>
              </MpPointerContainer>
            </Tooltip>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 3,
            }}
          >
            <ParentSpan>{companyName}</ParentSpan>

            <ArrowRight height={8} width={8} color="black" />
            <Tooltip
              title="Custom module made by your team"
              arrow
              placement="right"
            >
              <CmPointerContainer>
                <span style={{ color: "#337ab7", fontWeight: 600 }}>
                  CUSTOM
                </span>
              </CmPointerContainer>
            </Tooltip>
          </div>
        )}
      </TextContainer>
    </FlexDivImage>
  );
};

export default LibraryShowModule;
