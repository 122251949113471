import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { Briefcase, FilePlus, Info, Smile } from "react-feather";
import { Typography } from "../../../private/styles";

export const Wrapper = styled(Paper)`
  padding: 30px 40px 40px 40px;
  width: calc(min(520px, 96vw));
  min-height: 40px;

  @media (max-width: 560px) {
    padding: 20px;

    h2 {
      font-size: 22px;
    }
  }
`;

export const BriefcaseBlue = styled(Briefcase)`
  color: ${(props) => props.theme.palette.secondary.main};
  margin-left: 4px;
  transform: translateY(2px);
`;

export const BlueInfo = styled(Info)`
  color: ${(props) => props.theme.palette.secondary.main};
  margin-left: 4px;
  transform: translateY(2px);
`;

export const BlueSmile = styled(Smile)`
  color: ${(props) => props.theme.palette.secondary.main};
  margin-left: 4px;
  transform: translateY(2px);
`;

export const FilePlusBlue = styled(FilePlus)`
  color: ${(props) => props.theme.palette.secondary.main};
  margin-left: 4px;
  transform: translateY(2px);
`;

export const TextLink = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  font-weight: 500;
`;
