/* eslint-disable */
// dr
import React, { useState } from "react";
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  FormHelperText,
  Paper,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import useRoleCategories from "../../../swr/hooks/Roles/useRoleCategories";
import API from "../../../axios/instances/API";
import { Typography, Button, TextField, Alert, FormControl } from "../styles";
import { FEEDBACK_MESSAGES } from "../../../storage/constants";
import useAudienceOptions from "../../../swr/hooks/Audience/useAudienceOptions";

export const Wrapper = styled(Paper)`
  padding: 30px;
  min-width: min(600px, 96vw);
  max-width: 600px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const CreateRole = ({ setSnackbarText, setCreateModalOpen }) => {
  const { roleCategories, isLoading } = useRoleCategories();
  const { mutate } = useAudienceOptions();
  const [selectValue, setSelectValue] = useState("None");
  const [newRoleName, setNewRoleName] = useState("");
  const [newFrenchName, setNewFrenchName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const history = useHistory();

  if (isLoading) return null;

  const handleSubmit = async () => {
    setErrorAlert(false);
    setIsSubmitting(true);
    if (selectValue === "None") {
      setIsSubmitting(false);
      return setErrorAlert("You Must Choose a Category");
    }
    try {
      const { data } = await API.post("dashboard/roles/", {
        name: newRoleName,
        category_id: selectValue,
        fr_name: newFrenchName.length > 0 ? newFrenchName : newRoleName,
      });

      // Update the audience options with the new role
      mutate();

      //
      setIsSubmitting(false);
      setSnackbarText(`Role "${newRoleName}" created success`);
      setCreateModalOpen(false);
      history.push(`/roles/details/${data.id}`);
    } catch (error) {
      setIsSubmitting(false);
      // Can't make role if there is already one with that name
      if (error.response && error.response.data) {
        return setErrorAlert(error.response.data.error);
      }
      return setErrorAlert(FEEDBACK_MESSAGES.GENERIC_ERROR);
    }
  };

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="h3">Create Role</Typography>
      </div>
      <Typography variant="subtitle1" color="textPrimary" mb={6}>
        Fill in the inputs below to create a new Role.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            helperText="*Required"
            fullWidth
            autoFocus
            value={newRoleName}
            onChange={(e) => setNewRoleName(e.currentTarget.value)}
            label="New Role Name"
            size="small"
          />
          <TextField
            mt={4}
            variant="outlined"
            fullWidth
            value={newFrenchName}
            onChange={(e) => setNewFrenchName(e.currentTarget.value)}
            label="French Role Name"
            size="small"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" size="small">
            <InputLabel id="category_label">Category</InputLabel>
            <Select
              labelId="category_label"
              id="category_select"
              value={selectValue}
              onChange={(e) => setSelectValue(e.target.value)}
              label="Category"
            >
              <MenuItem value="None">
                <em>None</em>
              </MenuItem>
              {roleCategories?.map((category) => (
                <MenuItem key={category?.id} value={category?.id}>
                  {category?.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>*Required</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Button
        disabled={
          newRoleName.length < 1 || selectValue === "None" || isSubmitting
        }
        onClick={handleSubmit}
        variant="contained"
        color="secondary"
        mt={6}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        fullWidth
      >
        {isSubmitting ? "Creating" : "Create Role"}
      </Button>
      {errorAlert && (
        <Alert severity="error" mt={2} onClose={() => setErrorAlert("")}>
          {errorAlert}
        </Alert>
      )}
    </Wrapper>
  );
};

export default CreateRole;

CreateRole.propTypes = {
  setSnackbarText: PropTypes.func.isRequired,
  setCreateModalOpen: PropTypes.func.isRequired,
};
