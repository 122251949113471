/* eslint-disable */
// dr
import React, { useState } from "react";
import PropTypes from "prop-types";
import { DeleteWrapper, DeleteButton, Alert, Typography } from "../../styles";
import API from "../../../../axios/instances/API";
import { FEEDBACK_MESSAGES } from "../../../../storage/constants";
import { useHistory } from "react-router-dom";
import useManagerDetail from "../../../../swr/hooks/Managers/useManagerDetail";
import { CircularProgress } from "@material-ui/core";

const ConfirmRemoveLocation = ({
  setSnackbarText,
  deleteInfo,
  setDeleteInfo,
  managerId,
  mutate,
  fromLocationPage = false,
}) => {
  const { isLoading, details, isError } = useManagerDetail(managerId);

  const [errorAlert, setErrorAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();

  if (isLoading) {
    return (
      <DeleteWrapper>
        <div
          style={{
            width: "100%",
            height: 240,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={30} />
        </div>
      </DeleteWrapper>
    );
  }

  if (isError) {
    return (
      <DeleteWrapper>
        <div
          style={{
            width: "100%",
            height: 240,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Alert severity="error">
            Error.. could not load information about this supervisor
          </Alert>
        </div>
      </DeleteWrapper>
    );
  }

  const isLastLocation = details.locations && details.locations.length === 1;

  const handleDelete = async () => {
    setErrorAlert(false);
    setIsDeleting(true);
    try {
      const sendObj = {
        manager_id: managerId,
        location_id: deleteInfo.locationId,
      };

      if (isLastLocation) {
        sendObj.confirm_deactivation = true;
      }

      await API.delete("dashboard/managers/locations/", {
        data: sendObj,
      });

      mutate();
      setIsDeleting(false);

      if (!isLastLocation || fromLocationPage) {
        setSnackbarText(
          `Supervisor ${details.manager.firstName} ${details.manager.lastName} successfully removed success`,
        );
        setDeleteInfo(null);
      } else {
        setDeleteInfo(null);
        history.push({
          pathname: "/supervisors/",
          state: {
            supervisorDeleted: true,
            supervisorName: deleteInfo.name,
          },
        });
      }
    } catch (error) {
      setErrorAlert(true);
      setIsDeleting(false);
    }
  };

  return (
    <DeleteWrapper style={{ width: 460, border: "2px solid tomato" }}>
      <Typography variant="h5" component="h5">
        Remove Supervisor from Location?
      </Typography>
      <Typography
        mb={isLastLocation ? 4 : 8}
        style={{ fontWeight: "400", fontSize: 14 }}
      >
        {deleteInfo.name} -> {deleteInfo.locationName}
      </Typography>
      {isLastLocation && (
        <Alert severity="warning" mb={3} style={{ textAlign: "left" }}>
          <p style={{ fontWeight: "600" }}>
            This is the supervisors only location.
          </p>
          <p>
            Removing a supervisor from their only location will remove their
            supervisor status.
          </p>
        </Alert>
      )}
      <DeleteButton
        fullWidth
        p={2}
        onClick={handleDelete}
        disabled={isDeleting}
      >
        {isDeleting ? "..." : "Confirm"}
      </DeleteButton>
      {errorAlert && (
        <Alert mt={4} severity="error" onClose={() => setErrorAlert(false)}>
          {FEEDBACK_MESSAGES.GENERIC_ERROR}
        </Alert>
      )}
    </DeleteWrapper>
  );
};

export default ConfirmRemoveLocation;

ConfirmRemoveLocation.propTypes = {
  setSnackbarText: PropTypes.func.isRequired,
  deleteInfo: PropTypes.object,
  setDeleteInfo: PropTypes.func.isRequired,
  managerId: PropTypes.string.isRequired,
  mutate: PropTypes.func.isRequired,
};

ConfirmRemoveLocation.defaultProps = {
  deleteInfo: null,
};
