import React, { useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { Wrapper, Typography, TextField, Button } from "../../styles";
import API from "../../../../axios/instances/API";
import CustomField from "../../../../components/CustomField";
import { get400ErrorMsg } from "../../../../storage/helpers";

const AddQuizToStream = ({
  details,
  mutate,
  setSnackbarText,
  readOnly,
  isRetail,
}) => {
  const [comboValue, setComboValue] = useState([]);
  const [resetKey, setResetKey] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const autocompleteOptions = details.available_quizzes
    .sort((a, b) => new Date(b.created) - new Date(a.created))
    .map((quiz) => ({
      id: quiz.id,
      name: quiz.name,
      fromMarketplace: quiz.is_marketplace,
      vendor: quiz.is_marketplace ? quiz.vendor : null,
      created: quiz.created,
      state: quiz.state,
    }));

  const handleSubmit = async () => {
    const moreThenOne = comboValue.length > 1;
    const prevQuizzes = details.quizzes.map((quiz) => quiz.id);
    const newQuizzes = comboValue.map((quiz) => quiz.id);
    setIsSubmitting(true);
    try {
      const { data } = await API.put("/dashboard/streams/quizzes/", {
        id: details.stream.id,
        quiz_ids: prevQuizzes.concat(newQuizzes),
      });
      if (data.invalidQuizzes && data.invalidQuizzes.length > 0) {
        setSnackbarText(
          `Unable to add the ${data.invalidQuizzes.length > 1 ? "modules" : "module"} ${data.invalidQuizzes.map((q) => q.quiz_name).join(" ")} as ${data.invalidQuizzes.length > 1 ? "they are" : "it is"} incomplete error`,
        );
        return setIsSubmitting(false);
      }

      setResetKey((p) => !p);
      await mutate();
      setSnackbarText(
        `${moreThenOne ? "Modules" : "Module"} added to the stream success`,
      );
      setComboValue([]);
      return setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      const errorMsg = get400ErrorMsg(
        error,
        "Error while attempting to add module. Please try again. error",
      );
      return setSnackbarText(errorMsg);
    }
  };

  return (
    <Wrapper>
      <Typography variant="h3">Available Modules</Typography>
      <Typography variant="subtitle1" mb={10}>
        You can add these modules into your stream
      </Typography>
      <Autocomplete
        key={resetKey}
        multiple
        size="small"
        options={autocompleteOptions}
        noOptionsText="No Modules Available"
        getOptionLabel={(option) => option.name}
        disableCloseOnSelect
        renderOption={(option) => (
          <CustomField
            option={option}
            isRetail={isRetail}
            companyName={details.stream.company}
          />
        )}
        onChange={(_, newValue) => {
          setComboValue(newValue);
        }}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Modules"
            variant="outlined"
            size="small"
          />
        )}
      />
      <Button
        mt={4}
        variant="contained"
        color="secondary"
        fullWidth
        disabled={comboValue.length === 0 || readOnly || isSubmitting}
        onClick={handleSubmit}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
      >
        {isSubmitting ? "Adding" : "Add To Stream"}
      </Button>
    </Wrapper>
  );
};

export default AddQuizToStream;

AddQuizToStream.propTypes = {
  details: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isRetail: PropTypes.bool.isRequired,
};
