import React from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { List, User } from "react-feather";
import styled from "styled-components";
import useLocationTeamMembers from "../../../swr/hooks/Locations/useLocationTeamMembers";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 6px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  height: 100%;
  min-height: 80px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid slategrey;

  span {
    color: black;
  }
`;

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: "none",
    minWidth: 160,
    maxWidth: 200,

    minHeight: 80,
    maxHeight: "none",
    borderRadius: 8,
    padding: 0,
    // TODO -> used in many components
  },
}));

const Inner = ({ id, name }) => {
  const { teamMembers, isLoading, isError } = useLocationTeamMembers(id);

  if (isLoading) {
    return (
      <InnerContainer>
        <div
          style={{
            width: "100%",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "darkgrey" }}>loading...</span>
        </div>
      </InnerContainer>
    );
  }

  if (isError) {
    return (
      <InnerContainer>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 40,
          }}
        >
          <span style={{ color: "firebrick" }}>error loading...</span>
        </div>
      </InnerContainer>
    );
  }

  return (
    <InnerContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 4,
          marginBottom: 8,
        }}
      >
        <span style={{ fontSize: 11, fontWeight: "500" }}>{name}</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        {teamMembers?.map(({ firstName, lastName }) => (
          <div
            key={firstName}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 4,
            }}
          >
            <User height={10} width={10} color="darkgrey" />
            <span style={{ fontSize: 11, fontWeight: "400" }}>
              {firstName} {lastName}
            </span>
          </div>
        ))}
      </div>
    </InnerContainer>
  );
};

const MembersInLocationTooltip = ({ id, name }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={<Inner id={id} name={name} />}
      arrow
      placement="top"
      classes={{ tooltip: classes.tooltip }}
    >
      <Container>
        <List height={14} width={14} color="darkgrey" />
      </Container>
    </Tooltip>
  );
};

export default MembersInLocationTooltip;
