import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { CircularProgress, Paper } from "@material-ui/core";
import { Typography, TextField, Button, Alert } from "../styles";
import { FEEDBACK_MESSAGES } from "../../../storage/constants";
import API from "../../../axios/instances/API";
import styled from "styled-components";
import { Plus } from "react-feather";

const ModalWrapper = styled(Paper)`
  padding: 30px;
  min-width: min(600px, 96vw);
  max-width: 600px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const CreateStream = ({ setSnackbarText, setAddStreamOpen }) => {
  const [errorAlert, setErrorAlert] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState("");
  const [streamName, setStreamName] = useState("");
  const history = useHistory();
  const createStream = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorAlert(false);

    try {
      const { data } = await API.post("dashboard/streams/", {
        name: streamName,
      });
      setIsSubmitting(false);
      setSnackbarText(`Successfully created stream "${streamName}" success`);
      setAddStreamOpen(false);
      history.push(`/streams/details/${data.id}`);
    } catch (error) {
      setIsSubmitting(false);
      setErrorAlert(true);
    }
  };

  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="h3">Create Stream</Typography>
      </div>
      <Typography variant="subtitle1">
        Fill out the field below to create a new Stream.
      </Typography>

      <form id="createStreamForm" onSubmit={createStream}>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          mt={10}
          label="New Stream Name"
          value={streamName}
          onChange={(e) => setStreamName(e.target.value)}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="submit"
            form="createStreamForm"
            variant="contained"
            color="primary"
            style={{
              backgroundColor:
                streamName.length < 3 || isSubmitting ? "darkgrey" : "#337AB7",
              color: "#eeeeee",
              marginLeft: "auto",
            }}
            disabled={streamName.length < 3 || isSubmitting}
            startIcon={
              isSubmitting ? (
                <CircularProgress size={16} color="white" />
              ) : (
                <Plus />
              )
            }
            mt={4}
          >
            {isSubmitting ? "Creating Stream" : "Create Stream"}
          </Button>
        </div>
      </form>
      {errorAlert && (
        <Alert severity="error" mt={2} onClose={() => setErrorAlert(false)}>
          {FEEDBACK_MESSAGES.GENERIC_ERROR}
        </Alert>
      )}
    </ModalWrapper>
  );
};

export default CreateStream;

CreateStream.propTypes = {
  setAddStreamOpen: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
