import React, { useContext, useState } from "react";
import { Modal, Tab } from "@material-ui/core";
import { Layers } from "react-feather";
import { Helmet } from "react-helmet-async";
import AccessWarning from "../../../components/AdminAccess/AccessWarning";
import BadgeLabel from "../../../components/BadgeLabel";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../components/PrivateHeader";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import TabPanel from "../../../components/TabPanel";
import CreateButton from "../../../components/UI/CreateButton";
import OnLoadError from "../../../components/UI/OnLoadError";
import { GlobalState } from "../../../store/GlobalState";
import useAdminTeam from "../../../swr/hooks/Admin/useAdminTeam";
import { TableTabBar, TableTabs } from "../styles";
import AdminTable from "./AdminTable";
import InviteAdmin from "./InviteAdmin";
import OutstandingAdminTable from "./OutstandingAdminTable";
import PendingAdminTable from "./PendingAdminTable";

const Admin = () => {
  const { isLoading, mutate, admins, isError } = useAdminTeam();

  const [snackbarText, setSnackbarText] = useState("");
  const [addAdminOpen, setAddAdminOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const { state } = useContext(GlobalState);
  const { config, isRetail } = state;

  const { user, current_company: currentCompany } = config;

  const { read_only: readOnly } = user;

  const { id: userId } = config.user;

  const pendingAdmins = currentCompany?.pending_access_requests?.admins;

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  if (isLoading) {
    return (
      <>
        <Helmet title="Spiffy | Admin" />
        <PrivateHeader header="Admins" Icon={Layers} />
        <LoaderWrapper text="Admin Team" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  return (
    <>
      <Helmet title="Spiffy | Admin" />
      <PrivateHeader header="Admins" Icon={Layers} />
      <div style={{ width: "fit-content" }}>
        <CreateButton
          title="Invite Admin"
          handleClick={() => setAddAdminOpen(true)}
          disabled={readOnly}
        >
          Invite Admin
        </CreateButton>
      </div>
      <div style={{ height: 4 }} />
      <AccessWarning />
      <div style={{ height: 18 }} />
      <TableTabBar position="relative" elevation={0}>
        <TableTabs
          value={tabValue}
          onChange={handleTabChange}
          scrollButtons="off"
        >
          <Tab label="All Admins" />
          <Tab label="Outstanding Invitations" />
          <Tab
            label={
              <BadgeLabel text="Access Requests" num={pendingAdmins || null} />
            }
          />
        </TableTabs>
      </TableTabBar>
      <TabPanel value={tabValue} index={0}>
        <AdminTable
          readOnly={readOnly}
          isRetail={isRetail}
          mutate={mutate}
          admins={admins}
          isLoading={isLoading}
          userId={userId}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <OutstandingAdminTable readOnly={readOnly} mutate={mutate} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <PendingAdminTable
          readOnly={readOnly}
          setSnackbarText={setSnackbarText}
        />
      </TabPanel>

      <Modal
        open={addAdminOpen}
        onClose={() => setAddAdminOpen(false)}
        aria-labelledby="Invite an Admin"
        aria-describedby="Invite an Admin"
      >
        <div>
          <InviteAdmin
            setSnackbarText={setSnackbarText}
            setAddAdminOpen={setAddAdminOpen}
            mutate={mutate}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};
export default Admin;
