import React from "react";
import { Modal } from "@material-ui/core";
import ActionButtonForTables from "../../../Table/ActionButtonForTables";
import EditAudienceNameModal from "../../../../pages/private/audience/detail/retail/EditAudienceNameModal";
import { Unlock, Edit2 } from "react-feather";
import useAudienceToolStore from "../../../../store/useAudienceToolStore";
import { TABLE_STATES } from "../helpers";
import AttActionButtonWithText from "../Buttons/AttActionButtonWithText";

const TemplateBox = () => {
  const tableState = useAudienceToolStore((state) => state.tableState);

  const templateName = useAudienceToolStore((state) => state.templateName);
  const editTemplateNameModalOpen = useAudienceToolStore(
    (state) => state.editTemplateNameModalOpen,
  );
  const openEditTemplateName = useAudienceToolStore(
    (state) => state.openEditTemplateName,
  );
  const closeEditTemplateName = useAudienceToolStore(
    (state) => state.closeEditTemplateName,
  );

  const updateTableState = useAudienceToolStore(
    (state) => state.updateTableState,
  );

  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          background: "transparent",
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#eeeeee50",
          zIndex: 99,
        }}
      >
        <div
          style={{
            height: 110,
            width: 280,
            background: "white",
            zIndex: 100,
            border: "2px solid black",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 6,
            borderRadius: 4,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <span
              style={{ color: "darkgrey", fontSize: 12, fontWeight: "500" }}
            >
              {tableState === TABLE_STATES.SAVING
                ? "Template saving.."
                : "Template Saved"}
            </span>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: 4,
                flexDirection: "row",
                marginTop: 2,
              }}
            >
              <span
                style={{
                  fontWeight: "500",
                  fontSize: 13,
                  color: "black",
                }}
              >
                {templateName}
              </span>
              {tableState === TABLE_STATES.LOCKED && (
                <ActionButtonForTables
                  style={{
                    fontWeight: "500",
                    fontSize: 13,
                    color: "black",
                  }}
                  Icon={Edit2}
                  title="Edit template name"
                  handleClick={openEditTemplateName}
                />
              )}
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <AttActionButtonWithText
              style={{ width: "100%" }}
              Icon={Unlock}
              title="Unlock Template"
              handleClick={() => updateTableState(TABLE_STATES.UNLOCKED)}
            />
          </div>
        </div>
      </div>
      <Modal open={editTemplateNameModalOpen} onClose={closeEditTemplateName}>
        <div>
          <EditAudienceNameModal initialName={templateName} />
        </div>
      </Modal>
    </>
  );
};

export default TemplateBox;
