import React from "react";
import PropTypes from "prop-types";
import { QuestionAvatar, Separate, SpacingDiv } from "../../styles";
import { Typography } from "../../../styles";
import EditAndDelete from "../EditAndDelete";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const QuestionDetailsSA = ({
  question,
  handleDeleteQuestion,
  builderContext,
  isReview = false,
  isModuleLive,
  isModuleConvertingNextStateLive,
  isModulePending,
  isModuleConvertingNextStatePending,
  isModuleInReview,
  isModuleConvertingNextStateReview,
}) => {
  const formatCorrect = (correctAnswer) => {
    if (Array.isArray(correctAnswer)) {
      const retArr = correctAnswer.map((val) => val.toUpperCase());
      return retArr.join(", ");
    }

    return correctAnswer.toUpperCase();
  };

  return (
    <Wrapper>
      <Typography
        style={{
          fontSize: 10,
          fontWeight: "500",
          color: "darkgrey",
          whiteSpace: "nowrap",
          alignSelf: "flex-start",
        }}
      >
        Select All
      </Typography>
      <div style={{ height: 8 }} />
      {["A", "B", "C", "D"].map((letter) => (
        <SpacingDiv key={letter}>
          <QuestionAvatar
            activecolor={
              question.correctAnswer.includes(letter) ? "Yes" : "normal"
            }
          >
            {letter}
          </QuestionAvatar>
          <Typography variant="body2">
            {question.options[letter.toLowerCase()]}
          </Typography>
        </SpacingDiv>
      ))}
      {!isReview && (
        <Separate>
          <div
            style={{
              minWidth: 200,
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: 11,
                color: "black",
                whiteSpace: "nowrap",
                fontWeight: "600",
              }}
            >
              Correct Answer: {"  "}
              {formatCorrect(question.correctAnswer)}
            </span>
          </div>

          <EditAndDelete
            builderContext={builderContext}
            handleDeleteQuestion={handleDeleteQuestion}
            question={question}
            deleteDisabled={
              isModuleLive ||
              isModulePending ||
              isModuleInReview ||
              isModuleConvertingNextStateReview ||
              isModuleConvertingNextStateLive ||
              isModuleConvertingNextStatePending
            }
          />
        </Separate>
      )}
    </Wrapper>
  );
};

export default QuestionDetailsSA;

QuestionDetailsSA.propTypes = {
  question: PropTypes.object.isRequired,
  handleDeleteQuestion: PropTypes.func,
  builderContext: PropTypes.object,
  isReview: PropTypes.bool.isRequired,
  isModifyLive: PropTypes.bool.isRequired,
};

QuestionDetailsSA.defaultProps = {
  handleDeleteQuestion: null,
  builderContext: null,
};
