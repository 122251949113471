import React from "react";
import { Paper } from "@material-ui/core";
import styled from "styled-components";
import ExtraInfo from "../../../../components/ExtraInfo";
import SubmissionTypeChip from "../../home/retail/SubmissionTypeChip";
import { addCommas } from "../../../../storage/helpers";

const BigBlock = styled(Paper)`
  padding: 20px;
  flex: 0.5;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  border-radius: 4px;
`;

const TitleText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: darkgrey;
`;

const OverSizedText = styled.span`
  font-size: 40px;
  font-weight: 800;
  color: black;

  @media (max-width: 600px) {
    font-size: 30px;
  }
`;

const BigBlockByEngagementType = ({
  title,
  number,
  definition,
  engagementType,
  Icon = null,
}) => {
  return (
    <BigBlock>
      {Icon && (
        <Icon
          height={26}
          width={26}
          style={{
            marginLeft: 2,
          }}
        />
      )}
      <div style={{ height: 10 }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 4,
        }}
      >
        <TitleText>{title}</TitleText>
        {engagementType === "All" ? (
          <ExtraInfo text={definition} />
        ) : (
          <SubmissionTypeChip small submissionType={engagementType} isVendor />
        )}
      </div>
      <div style={{ height: 8 }} />
      <OverSizedText>{addCommas(number)}</OverSizedText>
    </BigBlock>
  );
};

export default BigBlockByEngagementType;
