import { Avatar, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { BarChart2, Briefcase, MapPin, PieChart, Users } from "react-feather";
import styled from "styled-components";
import LoaderWrapper from "../../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../../components/PrivateHeader";
import SmallBlockWide from "../../../../../components/SmallBlockWide";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import {
  ENGAGEMENT_TYPES,
  returnDefinitionByEngagementType,
} from "../../../../../storage/constants";
import useSingleLocationBreakdown from "../../../../../swr/hooks/VendorReports/useSingleLocationBreakdown";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import MembersInMultipleCompaniesWarning from "../../reuseableComponents/MembersInMultipleCompaniesWarning";
import {
  retCompsByEngagementType,
  retSubsByEngagementType,
} from "../../utils/utils";
import SubmissionsTable from "./SubmissionsTable";
import TeamMembersTable from "./TeamMembersTable";

const StyledAvatar = styled(Avatar)`
  height: 56px;
  width: 56px;
  border-radius: 4px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const SingleLocationBreakdown = ({ match }) => {
  const { id } = match.params;
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const { isLoading, isError, data } = useSingleLocationBreakdown(id);

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Location Report" secondary={true} />
        <LoaderWrapper text="Location Report" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PrivateHeader header="Location Report" secondary={true} />
        <ErrorBlockWithPermissions errorObj={isError} />
      </>
    );
  }

  const subs = retSubsByEngagementType(data.totals, engagementType);
  const comps = retCompsByEngagementType(data.totals, engagementType);

  return (
    <>
      <PrivateHeader header="Location Report" secondary={data?.location_name} />
      {data?.users_with_team_members_in_multiple_locations >= 1 && (
        <>
          <MembersInMultipleCompaniesWarning
            num={data.users_with_team_members_in_multiple_locations}
          />
          {/* <div style={{ height: 12 }} /> */}
        </>
      )}

      <div style={{ height: 12 }} />
      <Paper
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
          backgroundColor: "white",
          borderRadius: 6,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <StyledAvatar>
            <MapPin />
          </StyledAvatar>
          <div
            style={{
              paddingLeft: 12,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: -2,
              }}
            >
              <Briefcase
                height={12}
                width={12}
                style={{ color: "darkgrey", marginRight: 4 }}
              />
              <span style={{ color: "darkgrey" }}>{data.company_name}</span>
            </div>
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              {data.location_name}
            </span>
            <span style={{ fontSize: 12, fontWeight: 400 }}>
              {data.location_address_line_1}, {data.city_name}
            </span>
          </div>
        </div>
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
          isVendor
        />
      </Paper>
      <Container>
        <SmallBlockWide
          title="Completions"
          Icon={PieChart}
          stat={comps}
          engagementType={engagementType}
          definition={returnDefinitionByEngagementType(
            "completions",
            engagementType,
          )}
        />
        <SmallBlockWide
          title="Submissions"
          Icon={BarChart2}
          stat={subs}
          engagementType={engagementType}
          definition={returnDefinitionByEngagementType(
            "submissions",
            engagementType,
          )}
        />
        <SmallBlockWide
          title="Engaged Team Members"
          Icon={Users}
          stat={data.totals.active_employees}
          engagementType={engagementType}
        />
      </Container>
      <div style={{ height: 8 }} />

      <TeamMembersTable
        members={data.team_members_breakdown}
        engagementType={engagementType}
        locationName={data.location_name}
      />
      <div style={{ height: 16 }} />
      <SubmissionsTable
        locationId={id}
        locationName={data.location_name}
        engagementType={engagementType}
      />
    </>
  );
};

export default SingleLocationBreakdown;

SingleLocationBreakdown.propTypes = {
  match: PropTypes.object.isRequired,
};
